import 'babel-polyfill'
import * as THREE from 'three'
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls'
import {
  ProjectedMaterial
} from '../shaders/ProjectedMaterial'
import {
  FixedMaterial
} from '../shaders/FixedMaterial'
import {
  CreateObject
} from '../Objects/createObject'
import {
  DomManager
} from '../domElements/domManager';
import {
  FirebaseManager
} from '../firebaseManager/firebaseManager'
import {
  Vector2
} from 'three'

export class CreateTestimony {
  constructor(parameters) {
    this.parameters = parameters ? parameters : false
    this.scene = parameters.createTestimonyScene
    this.renderer = parameters.createTestimonyRenderer
    this.modelArray = parameters.modelArray ? parameters.modelArray : false
    this.indexOfModelToLoad = parameters.indexOfModelToLoad
    this.indexOfModelLoaded = parameters.indexOfModelLoaded

    this.labelArray = parameters.labelArray
    this.domManager = new DomManager()
    this.testimonyValues = {
      cameraValues: {},
      objectRotation: false,
      name: false,
      place: false,
      roomMates: false,
      text: false,
      importance: false,
      indexOfObject: false,
      texture: false,
      view: false,
    }
    this.renderState = "showFinaleResult"
    this.isRotating = true
    this.counter = 0
  }

  shareYourTestimony() {
    this.domManager.displayOverlayMessage("SHARE YOUR TESTIMONY", "The goal of this web page is to collect various testimonies in order to have a global overview of what everyone experienced. The participants are invited to personalize an emblematic object and then to associate a testimony about their experience to it. <br> <br><span class = \"secondInstructionTitle\"> BE AWARE! </span> <br> All testimonials will be published and freely accessible to anyone visiting the archive. <br> <br> <br><span class = \"secondInstructionTitle\"> INSTRUCTIONS </span> <br> Before you testify, make sure you are in the same place as the one you stayed in during your confinement.")
    let overlayMessage = document.getElementById("overlayMessageContainer")
  }
  warning() {
    this.domManager.changeTitleText("BE AWARE!")
    this.domManager.changeCentralText("All testimonials will be published and freely accessible to anyone visiting the archive.")
    return "objectPresentation"
  }

  presentSelectedObject() {
    this.domManager.changeBackButton("Change")
    this.domManager.changeIndexOfInstruction("1")

    this.nameOfObject = this.modelArray[this.objectIndex].children[0].name

    let newString = this.nameOfObject.replace("_", " ");
    this.domManager.changeTitleText("THE OBJECT")
    this.domManager.changeCentralText("This item has been randomly chosen in the database of iconic objects. All the following questions will be about your relation to this object and your confinment. <br> <br> If you don't like this object click on change.")
    this.domManager.changeObjectName("the " + newString)
    return "instruction1"
  }
  setRandomObject() {
    let randomIndexFromZeroToAmountOfLoadedObjects = this.counter % this.indexOfModelLoaded.length
    this.randomIndex = this.indexOfModelLoaded[randomIndexFromZeroToAmountOfLoadedObjects]

    this.counter++
    this.objectIndex = this.randomIndex
    this.createObject = new CreateObject(this.parameters, this.objectIndex, this.camera)
    this.testimonyValues.indexOfObject = this.objectIndex
    this.createObject.addObject()
    this.renderer.render(this.scene, this.camera)
  }
  addObject() {
    this.createObject.addObject()
    this.renderer.render(this.scene, this.camera)
  }
  removeObject() {
    this.createObject.removeObject()
  }

  setCamera() {
    this.camera = new THREE.PerspectiveCamera(25, 1, 1, 10000)
    this.camera.position.set(0, 0, 550)
    this.scene.add(this.camera)
  }
  setOrbitControls() {
    this.orbit = new OrbitControls(this.camera, this.renderer.domElement)
    this.orbit.enableZoom = false
    this.orbit.autoRotate = true;

    let canvasContainer = document.getElementById("createTestimonyCanvas")
    canvasContainer.addEventListener("mousemove", function () {
      this.orbit.autoRotate = false;
    }.bind(this))
    canvasContainer.addEventListener("touchstart", function () {
      this.orbit.autoRotate = false;
    }.bind(this))
  }

  setImportance() {
    if (document.getElementById("overlayMessageContainer")) {
      this.domManager.removeOverlayMessage()
    }
    this.domManager.changeBackButton("Back")

    this.renderState = "showFinaleResult"

    this.createObject.setOriginalTexture()

    this.domManager.changeIndexOfInstruction("2")
    this.domManager.changeTitleText("IMPORTANCE")
    this.domManager.changeCentralText("How important did this object become during your confinement?")
    this.domManager.changeContinueButton("Continue")

    this.domManager.setImportanceInterface()

    let slider = document.getElementById("slider")
    slider.addEventListener("input", function () {
      this.createObject.scaleObject(slider.value)
    }.bind(this))
    return "instruction2"
  }
  setYourStory() {
    this.renderState = "showFinaleResult"
    this.bakeObjectTexture()
    this.domManager.changeIndexOfInstruction("4")
    this.domManager.changeTitleText("YOUR STORY")
    this.domManager.changeContinueButton("Continue")
    this.domManager.changeCentralText("What was your connection to this object during lockdown? What does it tell about the way you lived during that time?")

    this.domManager.setYourStoryInput()
    return "instruction4"
  }
  saveImportanceValue() {
    this.testimonyValues.importance = document.getElementById("slider").value
  }
  saveTextTestimony() {
    this.testimonyValues.text = document.getElementById("yourStoryInput").value
    if (this.testimonyValues.text === "" || this.testimonyValues.text === "type your testimony here...") {
      this.testimonyValues.text = "I have no words to express my relationship with this object."
    }
  }

  setYourObject() {
    this.domManager.changeIndexOfInstruction("3")
    this.domManager.changeTitleText("YOUR OBJECT")
    this.domManager.changeCentralText("Take a picture of this object at the place where you spent your lockdown.")
    this.domManager.changeContinueButton("Capture")
    this.createObject.initTexture()
    this.renderState = "updateTexture"
    return "instruction3"
  }
  setOriginalScaleToObject() {
    this.createObject.setOriginalScale()
  }
  getCameraValue() {
    this.testimonyValues.cameraValues.matrixWorldInverse = this.camera.matrixWorldInverse
    this.testimonyValues.cameraValues.projectionMatrix = this.camera.projectionMatrix
    this.testimonyValues.cameraValues.matrixWorld = this.camera.matrixWorld
    this.testimonyValues.cameraValues.position = this.camera.position
    // this.createObject.removeObject()
    this.testimonyValues.texture = this.domManager.capturePhotoAsTexture()
    this.renderState == ""
  }

  setYourView() {
    this.domManager.changeIndexOfInstruction("5")
    this.domManager.changeTitleText("YOUR VIEW")
    this.domManager.changeCentralText("From a window, take a picture of the view that you had where you spent your lockdown.")
    this.domManager.changeContinueButton("Capture")
    return "instruction5"
  }
  saveViewValue() {
    this.testimonyValues.view = this.domManager.capturePhotoAsView()
    this.domManager.toogleCameraView()
  }
  // removeObjectAndToggleCanvas() {
  //   this.createObject.removeObject()
  //   this.domManager.toggleCanvas()
  // }

  setYourInfos() {
    this.domManager.changeIndexOfInstruction("6")
    this.domManager.changeTitleText("YOUR INFOS")
    this.domManager.changeCentralText("For additional demographic information, fill in those fields.")
    this.domManager.changeContinueButton("Continue")

    this.domManager.setYourInfos()

    return "instruction6"
  }
  saveInfosValue() {
    this.testimonyValues.name = document.getElementsByClassName("yourInfosInput")[0].value
    if (this.testimonyValues.name === "") {

      this.testimonyValues.name = "someone"

    }
    this.testimonyValues.place = document.getElementsByClassName("yourInfosInput")[1].value
    if (this.testimonyValues.place === "") {

      this.testimonyValues.place = "somewhere"

    }
    this.testimonyValues.roomMates = document.getElementById("yourRoomMateInfosInput").value
    if (this.testimonyValues.roomMates === "") {
      this.testimonyValues.roomMates = "an unknown number of"
    }
  }
  setTestimonyButton() {
    this.domManager.displayTestimony(this.testimonyValues, "createTestimony")
  }
  setPreview() {
    this.domManager.changeIndexOfInstruction("7")
    this.domManager.changeTitleText("PREVIEW")
    this.domManager.changeCentralText("Here is the final preview of your testimony. This will be stored in the archive and it will be accessible to anyone visiting it.")
    this.domManager.changeContinueButton("Post")
    this.bakeObjectTexture()
    this.createObject.addLabelModelToObject()
    this.renderer.render(this.scene, this.camera)
    this.renderState = "showFinaleResult"

    return "instruction7"
  }
  removeCurrentLabel() {
    this.createObject.removeLabelModel()
  }


  bakeObjectTexture() {
    this.renderState = "bakeObjectTexture"
    this.createObject.bakeTexture(this.camera, this.testimonyValues)
    return "showFinalResult"
  }

  getPathFromIndex() {
    this.path = this.modelArray[this.objectIndex].children[0].name
  }
  centerCamera() {
    this.camera.position.x = 0
    this.camera.position.y = 0
    this.camera.position.z = 400
  }
  setHelp() {
    this.domManager.removeTestimony()

    this.domManager.changeIndexOfInstruction("8")
    this.domManager.changeTitleText("KEEP IN TOUCH")
    this.domManager.changeCentralText("In order to improve this project's user experience, we are looking for people to interview. <br> <br> If you would like to help us, write down your email adress and we will send you a survey.")
    this.domManager.changeContinueButton("Continue")
    this.domManager.changeBackButton("Send")

    this.domManager.capturePhotoAsArchive(this.renderer.domElement)

    this.createObject.removeObject()
    this.getPathFromIndex()
    this.postCameraValueToFirebase()
    this.postImageTextureToFirebase()
    this.postTextualValueToFirebase()

    this.domManager.setEmailInput()

    return "instruction8"
  }
  sendEmail() {
    if (this.email != "youremail@example.com" && this.email != null) {
      this.postEmail()
    }
    this.domManager.displayEmailConfirmation()

  }

  setShare() {
    if (document.getElementById("emailConfirmationContainer")) {
      document.getElementById("emailConfirmationContainer").remove()
      this.domManager.toggleContinueButton()
      this.domManager.toggleBackButton()
    }
    this.domManager.removeCameraOutput()
    this.domManager.toggleBackButton()

    this.email = document.getElementById("emailInput").value

    this.domManager.removeEmail()

    this.domManager.changeIndexOfInstruction("9")
    this.domManager.changeTitleText("KEEP A TRACK OF YOUR TESTIMONY")
    this.domManager.changeCentralText("To make this project really meaningfull, we need more testimonies! Share this project with your friends to help us create the greatest lockdown memorial archive ever.")

    if (this.email != "youremail@example.com" && this.email != null) {
      this.postEmail()
    }
    this.domManager.toggleCreateTestimonyCanvas()
    this.createObject.addLabelModelOfObject()

    this.displayLinkToWebsite()
    return "instruction9"
  }

  displayLinkToWebsite() {
    let urlOfObject = "https://lockdown.memorial/?model=" + this.path + "&id=" + this.postID + ""
    this.domManager.setCreateTestimonyCopyUrlButton(urlOfObject)
  }

  setThanks() {
    if (document.getElementById("socialContainer")) {
      document.getElementById("socialContainer").remove()
    }
    this.createObject.removeObject()
    this.domManager.toggleBackButton()

    this.domManager.removeCopyUrlButton()

    this.domManager.changeBackButton("Home")

    this.domManager.removeIndexOfInstruction()
    this.domManager.changeContinueButton("Visit memorial")
    this.domManager.changeTitleText("THANK YOU!")
    this.domManager.changeCentralText("You can now see your object in one of the memorials. Click on Visit memorial to do so or click on Home to get to the main menu.")
    return "endTestimony"
  }

  postCameraValueToFirebase() {
    this.firebaseManager = new FirebaseManager()
    this.postID = this.firebaseManager.sendWithUniqueID(this.path)

    this.firebaseManager.increaseCounter()
    this.firebaseManager.send(this.path + "/" + this.postID + "/CAMERA/MatrixWorldInverse", this.testimonyValues.cameraValues.matrixWorldInverse)
    this.firebaseManager.send(this.path + "/" + this.postID + "/CAMERA/ProjectionMatrix", this.testimonyValues.cameraValues.projectionMatrix)
    this.firebaseManager.send(this.path + "/" + this.postID + "/CAMERA/MatrixWorld", this.testimonyValues.cameraValues.matrixWorld)
    this.firebaseManager.send(this.path + "/" + this.postID + "/CAMERA/Position", this.testimonyValues.cameraValues.position)
  }
  postImageTextureToFirebase() {
    let texturePhoto = document.getElementById("camera--output").src
    this.firebaseManager.sendImageTextureToFirebase(this.path + "/" + this.postID + "/TEXTURE/img.png", texturePhoto)


    let viewPhoto = document.getElementById("camera--output2").src
    this.firebaseManager.sendImageTextureToFirebase("VIEW/" + this.postID + "/VIEW/img.png", viewPhoto)
    this.firebaseManager.send("VIEW/" + this.postID, "img")

    let archivePhoto = document.getElementById("camera--output3").src
    this.firebaseManager.sendImageTextureToFirebase(this.path + "/" + this.postID + "/ARCHIVE/img.png", archivePhoto)

  }
  postTextualValueToFirebase() {
    this.firebaseManager.send(this.path + "/" + this.postID + "/NAME", this.testimonyValues.name)
    this.firebaseManager.send(this.path + "/" + this.postID + "/PLACE", this.testimonyValues.place)
    this.firebaseManager.send(this.path + "/" + this.postID + "/ROOMMATES", this.testimonyValues.roomMates)
    this.firebaseManager.send(this.path + "/" + this.postID + "/TEXT", this.testimonyValues.text)
    this.firebaseManager.send(this.path + "/" + this.postID + "/IMPORTANCE", this.testimonyValues.importance)
  }
  postEmail() {
    this.firebaseManager.send("EMAIL/" + this.postID, this.email)
  }

  render() {
    if (this.orbit) {
      this.orbit.update()
    }
    if (this.renderState == "updateTexture") {
      this.createObject.updateTexture()
    }
    if (this.camera) {
      // this.camera.aspect = window.innerWidth / ((window.innerWidth * 3) / 4);
      // this.camera.updateProjectionMatrix();
    }
    this.renderer.render(this.scene, this.camera)
  }

  /////////////////////////////////////////////////////////////FOR 2nd VERSION
  removeCreateTestimony() {
    this.createObject.removeObject()
    this.scene.remove(this.camera)
    this.scene.remove(this.orbit)
    // this.domManager.removeAllCameraElements()
    // this.domManager.removeCanvas()
    // this.domManager.removeMain()
    // this.domManager.removeInstructionText()
    // this.domManager.removeContinueButton()
    // this.domManager.removeBackButton()
    // this.renderer.render(this.scene, this.camera)

    this.url = {
      model: this.path,
      id: this.postID,
      index: this.createObject.indexOfModel,
    }
    return this.url
  }
  onTestimonyClick() {
    this.testimonyContainer.remove()
    if (this.testimonyIsSmall) {
      this.domManager.displayFullTestimonyOfObject(this.testimonyValue)
    } else {
      this.domManager.displaySmallTestimonyOfObject(this.testimonyValue)
    }
    this.testimonyIsSmall = !this.testimonyIsSmall
    this.testimonyContainer = document.querySelector(".testimony")
    this.testimonyContainer.addEventListener('click', function () {
      this.onTestimonyClick()
    }.bind(this))
  }

  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}