// import 'babel-polyfill'
import * as THREE from 'three'
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls'
import {
  DeviceOrientationControls
} from 'three/examples/jsm/controls/DeviceOrientationControls'
import {
  FixedMaterial
} from '../shaders/FixedMaterial'
import {
  Object
} from '../Objects/object'

import {
  DomManager
} from '../domElements/domManager';
import {
  FirebaseManager
} from '../firebaseManager/firebaseManager'
import {
  Vector3,
  Scene
} from 'three';

import {
  Preload
} from './preload'

export class Memorial {
  constructor(parameters, isMobile) {
    this.parameters = parameters ? parameters : false
    this.scene = parameters.memorialScene
    this.renderer = parameters.memorialRenderer

    this.testimonyOverlayScene = parameters.testimonyOverlayScene
    this.testimonyOverlayRenderer = parameters.testimonyOverlayRenderer

    this.modelArray = parameters.modelArray ? parameters.modelArray : false
    this.refModelArray = parameters.refModelArray
    this.domManager = new DomManager()
    this.firebaseManager = new FirebaseManager()
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.center = new THREE.Vector2();
    this.isMobile = isMobile
    this.boundingBoxGroup = new THREE.Group();
    this.objectIsSelected = false;

    this.indexOfModelToLoad = parameters.indexOfModelToLoad
    this.indexOfModelLoaded = parameters.indexOfModelLoaded

    this.labelArray = parameters.labelArray

    this.ArrayOfCurrentNeededModelIndex = []
    this.renderIsOn = false;
    this.roomIsChosen = false;

  }
  async initMemorial() {
    let mainButtonOverlay = document.getElementById("mainButtonOverlay")
    if (!mainButtonOverlay.classList.contains("nonVisible")) {
      this.domManager.toggleMainButtonOverlay()
    }
    this.isOutlined = false
    this.chooseRoomScene = new THREE.Scene()
    this.modelOfObject = null
    this.addCamera()
    if (this.isMobile) {
      this.addDeviceOrientation()
    } else {
      this.addOrbit()
    }
    this.setupMemorialInteractions()
    this.windowFrame = await Preload.loadModel(require('../models/window.gltf'));
    this.choiceOfRoom()

    return "memorialInit"
  }
  async initMemorialWithID(url) {
    let mainButtonOverlay = document.getElementById("mainButtonOverlay")
    if (!mainButtonOverlay.classList.contains("nonVisible")) {
      this.domManager.toggleMainButtonOverlay()
    }
    this.isOutlined = false
    this.idOfObject = url.id
    this.modelOfObject = url.model
    this.indexOfModel = url.index
    this.chooseRoomScene = new THREE.Scene()

    this.addCamera()
    if (this.isMobile) {
      this.addDeviceOrientation()
    } else {
      this.addOrbit()
    }
    this.setupMemorialInteractions()
    this.windowFrame = await Preload.loadModel(require('../models/window.gltf'));
    this.getIndexOfRoomFromModelIndex()
    this.roomIsChosen = true;

    this.domManager.toggleMemorialCanvas()

    this.createRoom()

    this.renderIsOn = true
    return "memorialInit"
  }
  setupMemorialInteractions() {
    if (this.isMobile) {
      window.addEventListener('deviceorientation', this.onDeviceOrientationChangeEvent.bind(this), true);
      let canvas = document.getElementById("memorialCanvas")
      canvas.addEventListener('touchstart', this.onTouchStart.bind(this), false);
      canvas.addEventListener('touchmove', this.onTouchMove.bind(this), false);
      canvas.addEventListener('touchend', this.onTouchEnd.bind(this), false);
    } else {
      let canvas = document.getElementById("memorialCanvas")
      canvas.addEventListener('mousedown', this.onDocumentMouseDown.bind(this));
      canvas.addEventListener('mousemove', this.onDocumentMouseMove.bind(this));
      canvas.addEventListener('mouseup', this.onDocumentMouseUp.bind(this));
      canvas.addEventListener("click", function () {
        this.orbit0.autoRotate = false;
        this.orbit.autoRotate = false;
      }.bind(this))
    }
  }
  removeMemorialInteractions() {
    if (this.isMobile) {
      // this.addDeviceOrientation()
      window.removeEventListener('deviceorientation', this.onDeviceOrientationChangeEvent.bind(this), true);
      let canvas = document.getElementById("memorialCanvas")
      canvas.removeEventListener('touchstart', this.onTouchStart.bind(this), false);
      canvas.removeEventListener('touchmove', this.onTouchMove.bind(this), false);
      canvas.removeEventListener('touchend', this.onTouchEnd.bind(this), false);
    } else {
      // this.removeOrbit()
      let canvas = document.getElementById("memorialCanvas")
      canvas.removeEventListener('mousedown', this.onDocumentMouseDown.bind(this));
      canvas.removeEventListener('mousemove', this.onDocumentMouseMove.bind(this));
      canvas.removeEventListener('mouseup', this.onDocumentMouseUp.bind(this));
      canvas.removeEventListener("click", function () {
        this.orbit0.autoRotate = false;
        this.orbit.autoRotate = false;
      }.bind(this))
    }
  }
  getIndexOfRoomFromModelIndex() {
    switch (this.indexOfModel) {
      case 0:
        this.indexOfSelectedRoom = 0
        break
      case 1:
        this.indexOfSelectedRoom = 2
        break
      case 2:
        this.indexOfSelectedRoom = 3
        break
      case 3:
        this.indexOfSelectedRoom = 2
        break
      case 4:
        this.indexOfSelectedRoom = 3
        break
      case 5:
        this.indexOfSelectedRoom = 0
        break
      case 6:
        this.indexOfSelectedRoom = 2
        break
      case 7:
        this.indexOfSelectedRoom = 0
        break
      case 8:
        this.indexOfSelectedRoom = 3
        break
      case 9:
        this.indexOfSelectedRoom = 3
        break
      case 10:
        this.indexOfSelectedRoom = 2
        break
      case 11:
        this.indexOfSelectedRoom = 1
        break
      case 12:
        this.indexOfSelectedRoom = 0
        break
      case 13:
        this.indexOfSelectedRoom = 1
        break
      case 14:
        this.indexOfSelectedRoom = 1
        break
      case 15:
        break
    }
  }

  async choiceOfRoom() {
    this.renderMode = "choiceOfRoom"
    this.roomIsChosen = false;
    this.renderIsOn = true

    this.domManager.changeCanvasColor("transparent")

    if (!this.isMobile) {
      this.orbit.target.x = 0
      this.orbit.target.z = 0

      this.orbit0.autoRotate = true;
      this.orbit.autoRotate = true;

    }
    this.camera0.position.set(0, 0, 10)

    this.images = []
    let numberOfRooms = 6

    let imagesParameters = {
      x: 0,
      y: 0,
      z: 0,
      rotateY: 0,
      name: "",
    }
    for (let i = 0; i < numberOfRooms; i++) {

      let imageGeometry = new THREE.PlaneGeometry(72, 128, 1, 1);
      switch (i) {
        case 0:
          var imageTexture = new THREE.TextureLoader().load(require('../img/memorial1.png'));
          imagesParameters.name = "image1"
          imagesParameters.x = 0
          imagesParameters.z = -200
          break
        case 1:
          var imageTexture = new THREE.TextureLoader().load(require('../img/memorial2.png'));
          imagesParameters.name = "image2"
          imagesParameters.rotateY = -Math.PI / 2 + Math.PI / 6
          imagesParameters.x = 150
          imagesParameters.z = -100
          break
        case 2:
          var imageTexture = new THREE.TextureLoader().load(require('../img/memorial3.png'));
          imagesParameters.name = "image3"

          imagesParameters.rotateY = -3 / 4 * Math.PI
          imagesParameters.x = 150
          imagesParameters.z = 100
          break
        case 3:
          var imageTexture = new THREE.TextureLoader().load(require('../img/memorial4.png'));
          imagesParameters.name = "image4"

          imagesParameters.rotateY = Math.PI
          imagesParameters.x = 0
          imagesParameters.z = 200
          break
        case 4:
          var imageTexture = new THREE.TextureLoader().load(require('../img/comingMemorial1.png'));
          imagesParameters.name = "image5"

          imagesParameters.rotateY = 3 / 4 * Math.PI
          imagesParameters.x = -150
          imagesParameters.z = 100
          break
        case 5:
          var imageTexture = new THREE.TextureLoader().load(require('../img/comingMemorial2.png'));
          imagesParameters.name = "image6"

          imagesParameters.rotateY = Math.PI / 2 - Math.PI / 8
          imagesParameters.x = -150
          imagesParameters.z = -100
          break
      }

      let imageMaterial = new THREE.MeshBasicMaterial({
        map: imageTexture,
      });

      this.images[i] = new THREE.Mesh(imageGeometry, imageMaterial);

      this.images[i].position.x = imagesParameters.x
      this.images[i].position.y = imagesParameters.y
      this.images[i].position.z = imagesParameters.z

      this.images[i].rotateY(imagesParameters.rotateY)

      this.images[i].name = imagesParameters.name

      await this.chooseRoomScene.add(this.images[i]);
      let bottomTextCanvas = this.drawBottomTextCanvas()
      await this.chooseRoomScene.add(bottomTextCanvas)
    }

    this.domManager.removeLoader()
  }
  drawBottomTextCanvas() {
    const ctx = document.createElement('canvas').getContext('2d');
    ctx.canvas.style.backgroundColor = "transparent"
    ctx.canvas.width = 2000;
    ctx.canvas.height = 2000;
    ctx.fillStyle = '#056bf0'
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.fillStyle = 'white'
    ctx.font = "100px Roboto";
    ctx.fillText("LOOK AROUND", 700, 800);

    ctx.font = "50px Roboto";
    ctx.fillText("Move your phone", 700, 900);
    ctx.font = "50px Roboto";
    ctx.fillText("or drag your mouse,", 700, 970);
    ctx.font = "50px Roboto";
    ctx.fillText("to choose a memorial", 700, 1040);

    const texture = new THREE.CanvasTexture(ctx.canvas);

    var textGeometry = new THREE.PlaneGeometry(50, 50, 32);
    var textMaterial = new THREE.MeshBasicMaterial({
      map: texture,
    });
    var textModel = new THREE.Mesh(textGeometry, textMaterial);

    var textContainer = new THREE.Group();
    textContainer.add(textModel)

    textModel.position.y = -50;
    textModel.rotateX(Math.PI / 2 + Math.PI)
    return textContainer
  }

  async createRoom() {
    console.log("createRoom")

    this.domManager.toggleMemorialCanvas()
    this.domManager.changeCanvasColor("gradient")

    this.domManager.removeHeaderTitle()
    this.domManager.setBackHeaderButton("Back to choice of memorial")
    this.renderMode = "displayMemorial"

    let backButton = document.getElementById("backHeaderButton")
    backButton.addEventListener("click", function () {
      if (this.renderMode == "displayMemorial") {
        while (this.scene.children.length > 0) {
          this.scene.remove(this.scene.children[0]);
        }
        this.domManager.setLoader()
        this.domManager.removeBackHeaderButton()
        this.domManager.displayHeaderTitle()
        this.choiceOfRoom()
      } else if (this.renderMode == "displayObject") {
        this.renderMode = "displayMemorial"
        this.displayNewMemorial()
      } else if (this.renderMode == "displayUrl") {
        while (this.testimonyOverlayScene.children.length > 0) {
          this.testimonyOverlayScene.remove(this.testimonyOverlayScene.children[0]);
        }
        this.domManager.removeSocialSharingButton()
        this.domManager.removeUrlInstruction()
        this.selectedObject.removeLabelModel()

        this.displaySelectedObject()
      }

    }.bind(this))


    this.index = this.indexOfSelectedRoom
    switch (this.index) {
      case 2:
        this.ArrayOfCurrentNeededModelIndex = [1, 3, 6, 10]
        await this.neededModel(this.ArrayOfCurrentNeededModelIndex)

        await this.createRoomOne(this.modelOfObject)
        this.domManager.toggleMemorialCanvas()
        this.domManager.removeLoader()
        break;
      case 0:
        this.ArrayOfCurrentNeededModelIndex = [0, 5, 7, 12]
        await this.neededModel(this.ArrayOfCurrentNeededModelIndex)

        await this.createRoomTwo(this.modelOfObject)
        this.domManager.toggleMemorialCanvas()
        this.domManager.removeLoader()

        break;
      case 1:
        this.ArrayOfCurrentNeededModelIndex = [11, 13, 14]
        await this.neededModel(this.ArrayOfCurrentNeededModelIndex)

        await this.createRoomThree(this.modelOfObject)
        this.domManager.toggleMemorialCanvas()
        this.domManager.removeLoader()

        break;
      case 3:
        this.ArrayOfCurrentNeededModelIndex = [2, 4, 8, 9]
        await this.neededModel(this.ArrayOfCurrentNeededModelIndex)

        await this.createRoomFour(this.modelOfObject)
        this.domManager.toggleMemorialCanvas()
        this.domManager.removeLoader()

        break;
    }
  }
  async neededModel() {
    for (let i = 0; i < this.ArrayOfCurrentNeededModelIndex.length; i++) {
      let currentIndex = this.ArrayOfCurrentNeededModelIndex[i]

      if (this.indexOfModelLoaded.indexOf(currentIndex) < 0) {
        this.parameters.modelArray[currentIndex] = await Preload.loadModels(this.refModelArray, currentIndex);
        this.indexOfModelLoaded.push(currentIndex)
        this.indexOfModelToLoad.splice(this.indexOfModelToLoad.indexOf(currentIndex), 1)
      }
    }
  }
  async createRoomOne(modelOfObject) {

    if (!this.isMobile) {
      this.orbit.target.x = 820
      this.orbit.target.z = 151
    }
    this.camera.position.set(1000, 0, 200)

    //////////////////////////////////////////////////////////////////////////////// POINTER
    this.circlePointerGeometry = new THREE.RingGeometry(40, 50, 32);
    this.circlePointerMaterial = new THREE.MeshBasicMaterial({
      // color: 0xfffffff,
      color: 0x056CF2,
      side: THREE.DoubleSide
    });
    this.circlePointer = new THREE.Mesh(this.circlePointerGeometry, this.circlePointerMaterial);
    this.circlePointer.rotateX(-Math.PI / 2)


    this.leftArrowGeometry = new THREE.PlaneGeometry(30, 10, 1, 1);
    this.leftArrowMaterial = new THREE.MeshBasicMaterial({
      color: 0x056CF2,
      // color: 0xfffffff,
      side: THREE.DoubleSide
    });
    this.leftArrow = new THREE.Mesh(this.leftArrowGeometry, this.leftArrowMaterial);
    this.leftArrow.rotateX(Math.PI / 2)
    this.leftArrow.rotateZ(Math.PI / 4)
    this.leftArrow.position.x = 7
    this.leftArrow.position.y = 0
    this.leftArrow.position.z = 0

    this.scene.add(this.leftArrow);

    this.rightArrowGeometry = new THREE.PlaneGeometry(30, 10, 1, 1);
    this.rightArrowMaterial = new THREE.MeshBasicMaterial({
      color: 0x056CF2,
      // color: 0xfffffff,
      side: THREE.DoubleSide
    });
    this.rightArrow = new THREE.Mesh(this.rightArrowGeometry, this.rightArrowMaterial);
    this.rightArrow.rotateX(Math.PI / 2)
    this.rightArrow.rotateZ(-Math.PI / 4)
    this.rightArrow.position.x = -7
    this.rightArrow.position.y = 0
    this.rightArrow.position.z = 0

    this.arrow = new THREE.Group();
    this.arrow.add(this.leftArrow)
    this.arrow.add(this.rightArrow)

    this.pointer = new THREE.Group()

    this.pointer.add(this.circlePointer)
    this.pointer.add(this.arrow)

    this.pointer.position.y = -10

    this.scene.add(this.pointer);

    //////////////////////////////////////////////////////////////////////////////// DESK

    this.desk = []
    this.deskParameters = {
      index: 1,
      space: 120,
      offset: 0,
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
    }

    let sizeCloner = 3
    for (let i = 0; i < sizeCloner; i++) {
      this.deskParameters.position.x = 0
      switch (i) {
        case 0:
          this.deskParameters.position.y = -300
          break;
        case 1:
          this.deskParameters.position.y = -100
          break;
        case 2:
          this.deskParameters.position.y = 100
          break;
      }
      this.deskParameters.position.z = 0

      let clonedPosition = window.Object.assign({}, this.deskParameters.position)

      this.desk[i] = new Object(this.parameters, this.deskParameters.index, this.camera, clonedPosition, this.scene, this.renderer)
      if (i == 0 && modelOfObject == "desk") {
        await this.desk[i].addOriginal(this.idOfObject)
      } else {
        await this.desk[i].add()
      }
      let boundingBox = await this.desk[i].getBoundingBox()
      boundingBox.position.x = this.deskParameters.position.x
      boundingBox.position.y = this.deskParameters.position.y
      boundingBox.position.z = this.deskParameters.position.z

      this.boundingBoxGroup.add(boundingBox)
    }
    this.desk[0].rotateY(Math.PI / 2)
    this.desk[1].rotateY(Math.PI)
    this.desk[2].rotateY(Math.PI / 2 + Math.PI)

    //////////////////////////////////////////////////////////////////////////////// COMPUTER

    this.macPosition = {
      x: 0,
      y: 300,
      z: 0,
    }
    this.mac = new Object(this.parameters, 6, this.camera, this.macPosition, this.scene, this.renderer)
    if (modelOfObject == "computer") {
      await this.mac.addOriginal(this.idOfObject)
    } else {
      await this.mac.add()
    }

    this.mac.rotateY(Math.PI / 2)

    let boundingBox = this.mac.getBoundingBox()
    boundingBox.position.x = this.macPosition.x
    boundingBox.position.y = this.macPosition.y
    boundingBox.position.z = this.macPosition.z

    this.boundingBoxGroup.add(boundingBox)

    //////////////////////////////////////////////////////////////////////////////// CHAIR

    this.chairPosition = {
      x: 0,
      y: -500,
      z: 0,
    }

    this.chair = new Object(this.parameters, 10, this.camera, this.chairPosition, this.scene, this.renderer)
    if (this.modelOfObject == "chair") {
      await this.chair.addOriginal(this.idOfObject)
    } else {
      await this.chair.add()
    }


    this.chair.rotateY(-Math.PI / 2)

    boundingBox = await this.chair.getBoundingBox()
    boundingBox.position.x = this.chairPosition.x
    boundingBox.position.y = this.chairPosition.y
    boundingBox.position.z = this.chairPosition.z
    this.boundingBoxGroup.add(boundingBox)

    //////////////////////////////////////////////////////////////////////////////// HEADPHONE

    this.headphonePosition = {
      x: 0,
      y: 600,
      z: 0,
    }

    this.headphone = new Object(this.parameters, 3, this.camera, this.headphonePosition, this.scene, this.renderer)
    if (modelOfObject == "headphones") {
      await this.headphone.addOriginal(this.idOfObject)
    } else {
      await this.headphone.add()
    }

    this.headphone.rotateY(Math.PI / 2)

    boundingBox = await this.headphone.getBoundingBox()
    boundingBox.position.x = this.headphonePosition.x
    boundingBox.position.y = this.headphonePosition.y
    boundingBox.position.z = this.headphonePosition.z
    this.boundingBoxGroup.add(boundingBox)

    //////////////////////////////////////////////////////////////////////////////// FLOOR


    this.floorGeometry = new THREE.RingGeometry(600, 2000, 32);
    this.floorMaterial = new THREE.MeshBasicMaterial({
      // color: 0x056CF2,
      color: 0xfffffff,
      side: THREE.DoubleSide
    });
    this.floor = new THREE.Mesh(this.floorGeometry, this.floorMaterial);
    this.floor.rotateX(Math.PI / 2)
    this.floor.position.y = -300
    this.floor.name = "floor"
    await this.addViewImageTexture(this.floor)

    this.scene.add(this.floor);

    this.boundingBoxGroup.visible = false;
    await this.scene.add(this.boundingBoxGroup)


  }

  async createRoomTwo(modelOfObject) {

    if (!this.isMobile) {
      this.orbit.target.x = 0
      this.orbit.target.z = 100
    }

    this.camera.position.set(0, 0, 0)

    //////////////////////////////////////////////////////////////////////////////// POINTER
    this.circlePointerGeometry = new THREE.RingGeometry(40, 50, 32);
    this.circlePointerMaterial = new THREE.MeshBasicMaterial({
      // color: 0xfffffff,
      color: 0x056CF2,
      side: THREE.DoubleSide
    });
    this.circlePointer = new THREE.Mesh(this.circlePointerGeometry, this.circlePointerMaterial);
    this.circlePointer.rotateX(-Math.PI / 2)


    this.leftArrowGeometry = new THREE.PlaneGeometry(30, 10, 1, 1);
    this.leftArrowMaterial = new THREE.MeshBasicMaterial({
      color: 0x056CF2,
      // color: 0xfffffff,
      side: THREE.DoubleSide
    });
    this.leftArrow = new THREE.Mesh(this.leftArrowGeometry, this.leftArrowMaterial);
    this.leftArrow.rotateX(Math.PI / 2)
    this.leftArrow.rotateZ(Math.PI / 4)
    this.leftArrow.position.x = 7
    this.leftArrow.position.y = 0
    this.leftArrow.position.z = 0

    this.scene.add(this.leftArrow);

    this.rightArrowGeometry = new THREE.PlaneGeometry(30, 10, 1, 1);
    this.rightArrowMaterial = new THREE.MeshBasicMaterial({
      color: 0x056CF2,
      // color: 0xfffffff,
      side: THREE.DoubleSide
    });
    this.rightArrow = new THREE.Mesh(this.rightArrowGeometry, this.rightArrowMaterial);
    this.rightArrow.rotateX(Math.PI / 2)
    this.rightArrow.rotateZ(-Math.PI / 4)
    this.rightArrow.position.x = -7
    this.rightArrow.position.y = 0
    this.rightArrow.position.z = 0

    this.arrow = new THREE.Group();
    this.arrow.add(this.leftArrow)
    this.arrow.add(this.rightArrow)

    this.pointer = new THREE.Group()

    this.pointer.add(this.circlePointer)
    this.pointer.add(this.arrow)

    this.pointer.position.y = -10

    this.scene.add(this.pointer);

    //////////////////////////////////////////////////////////////////////////////// VIDEO GAME 

    this.videoGame = []
    this.videoGameParameters = {
      index: 0,
      space: 120,
      offset: 0,
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
    }

    let sizeCloner = 4
    for (let i = 0; i < sizeCloner; i++) {
      switch (i) {
        case 0:
          this.videoGameParameters.position.x = 400
          this.videoGameParameters.position.z = 0
          break;
        case 1:
          this.videoGameParameters.position.x = 0
          this.videoGameParameters.position.z = 400
          break;
        case 2:
          this.videoGameParameters.position.x = -400
          this.videoGameParameters.position.z = 0
          break;
        case 3:
          this.videoGameParameters.position.x = 0
          this.videoGameParameters.position.z = -400
          break;
      }
      this.videoGameParameters.position.y = 100

      let clonedPosition = window.Object.assign({}, this.videoGameParameters.position)

      this.videoGame[i] = new Object(this.parameters, this.videoGameParameters.index, this.camera, clonedPosition, this.scene, this.renderer)
      if (i == 0 && modelOfObject == "video_game") {
        await this.videoGame[i].addOriginal(this.idOfObject)
      } else {
        await this.videoGame[i].add()
      }

      let boundingBox = await this.videoGame[i].getBoundingBox()
      boundingBox.position.x = this.videoGameParameters.position.x
      boundingBox.position.y = this.videoGameParameters.position.y
      boundingBox.position.z = this.videoGameParameters.position.z

      this.boundingBoxGroup.add(boundingBox)
    }
    this.videoGame[0].rotateY(Math.PI / 2 + Math.PI)
    this.videoGame[1].rotateY(Math.PI)
    this.videoGame[2].rotateY(Math.PI / 2)
    this.videoGame[3].rotateY(Math.PI * 2)

    ////////////////////////////////////////////////////////////////////////////////////////   TV


    this.tv = []
    this.tvParameters = {
      index: 7,
      space: 120,
      offset: 0,
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
    }

    sizeCloner = 4
    for (let i = 0; i < sizeCloner; i++) {
      switch (i) {
        case 0:
          this.tvParameters.position.x = 500
          this.tvParameters.position.z = -500
          break;
        case 1:
          this.tvParameters.position.x = 500
          this.tvParameters.position.z = 500
          break;
        case 2:
          this.tvParameters.position.x = -500
          this.tvParameters.position.z = 500
          break;
        case 3:
          this.tvParameters.position.x = -500
          this.tvParameters.position.z = -500
          break;
      }
      this.tvParameters.position.y = 200

      let clonedPosition = window.Object.assign({}, this.tvParameters.position)

      this.tv[i] = new Object(this.parameters, this.tvParameters.index, this.camera, clonedPosition, this.scene, this.renderer)
      if (i == 0 && modelOfObject == "television") {
        await this.tv[i].addOriginal(this.idOfObject)
      } else {
        await this.tv[i].add()
      }


      let boundingBox = await this.tv[i].getBoundingBox()
      boundingBox.position.x = this.tvParameters.position.x
      boundingBox.position.y = this.tvParameters.position.y
      boundingBox.position.z = this.tvParameters.position.z

      this.boundingBoxGroup.add(boundingBox)
    }
    this.tv[0].rotateY(-Math.PI / 4)
    this.tv[1].rotateY(Math.PI / 4)

    this.tv[2].rotateY(-Math.PI / 4 - Math.PI)
    this.tv[3].rotateY(Math.PI / 4 + Math.PI)




    ////////////////////////////////////////////////////////////////////////////////////////   SOFA


    this.sofa = []
    this.sofaParameters = {
      index: 5,
      space: 120,
      offset: 0,
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
    }

    sizeCloner = 4
    for (let i = 0; i < sizeCloner; i++) {
      switch (i) {
        case 0:
          this.sofaParameters.position.x = 450
          this.sofaParameters.position.z = -450
          break;
        case 1:
          this.sofaParameters.position.x = 450
          this.sofaParameters.position.z = 450
          break;
        case 2:
          this.sofaParameters.position.x = -450
          this.sofaParameters.position.z = 450
          break;
        case 3:
          this.sofaParameters.position.x = -450
          this.sofaParameters.position.z = -450
          break;
      }
      this.sofaParameters.position.y = -100

      let clonedPosition = window.Object.assign({}, this.sofaParameters.position)

      this.sofa[i] = new Object(this.parameters, this.sofaParameters.index, this.camera, clonedPosition, this.scene, this.renderer)
      if (i == 0 && modelOfObject == "sofa") {
        await this.sofa[i].addOriginal(this.idOfObject)
      } else {
        await this.sofa[i].add()
      }


      let boundingBox = await this.sofa[i].getBoundingBox()
      boundingBox.position.x = this.sofaParameters.position.x
      boundingBox.position.y = this.sofaParameters.position.y
      boundingBox.position.z = this.sofaParameters.position.z

      this.boundingBoxGroup.add(boundingBox)
    }
    this.sofa[0].rotateY(-Math.PI / 4)
    this.sofa[1].rotateY(Math.PI / 4 + Math.PI)

    this.sofa[2].rotateY(-Math.PI / 4 - Math.PI)
    this.sofa[3].rotateY(Math.PI / 4)

    ////////////////////////////////////////////////////////////////////////////////////////   SPEAKER


    this.speaker = []
    this.speakerParameters = {
      index: 12,
      space: 120,
      offset: 0,
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
    }

    sizeCloner = 4
    for (let i = 0; i < sizeCloner; i++) {
      switch (i) {
        case 0:
          this.speakerParameters.position.x = 450
          this.speakerParameters.position.z = 0
          break;
        case 1:
          this.speakerParameters.position.x = 0
          this.speakerParameters.position.z = 450
          break;
        case 2:
          this.speakerParameters.position.x = -450
          this.speakerParameters.position.z = 0
          break;
        case 3:
          this.speakerParameters.position.x = 0
          this.speakerParameters.position.z = -450
          break;
      }
      this.speakerParameters.position.y = 500

      let clonedPosition = window.Object.assign({}, this.speakerParameters.position)

      this.speaker[i] = new Object(this.parameters, this.speakerParameters.index, this.camera, clonedPosition, this.scene, this.renderer)
      if (i == 0 && modelOfObject == "speaker") {
        await this.speaker[i].addOriginal(this.idOfObject)
      } else {
        await this.speaker[i].add()
      }


      let boundingBox = await this.speaker[i].getBoundingBox()
      boundingBox.position.x = this.speakerParameters.position.x
      boundingBox.position.y = this.speakerParameters.position.y
      boundingBox.position.z = this.speakerParameters.position.z

      this.boundingBoxGroup.add(boundingBox)
    }
    this.speaker[0].rotateY(Math.PI / 2 + Math.PI)
    this.speaker[1].rotateY(Math.PI)
    this.speaker[2].rotateY(Math.PI / 2)
    this.speaker[3].rotateY(Math.PI * 2)

    ////////////////////////////////////////////////////////////////////////////////////////   FLOOR

    this.floorGeometry = new THREE.CircleGeometry(500, 32);
    this.floorMaterial = new THREE.MeshBasicMaterial({
      color: 0xfffffff,
      side: THREE.DoubleSide
    });
    this.floor = new THREE.Mesh(this.floorGeometry, this.floorMaterial);
    this.floor.rotateX(Math.PI / 2)
    this.floor.position.y = -200
    this.floor.name = "floor"
    await this.addViewImageTexture(this.floor)

    this.scene.add(this.floor);

    this.boundingBoxGroup.visible = false;
    await this.scene.add(this.boundingBoxGroup)

  }
  async createRoomThree(modelOfObject) {

    if (!this.isMobile) {
      this.orbit.target.x = 0
      this.orbit.target.z = 100
    }

    this.camera.position.set(0, 0, 0)

    //////////////////////////////////////////////////////////////////////////////// POINTER
    this.circlePointerGeometry = new THREE.RingGeometry(40, 50, 32);
    this.circlePointerMaterial = new THREE.MeshBasicMaterial({
      // color: 0xfffffff,
      color: 0x056CF2,
      side: THREE.DoubleSide
    });
    this.circlePointer = new THREE.Mesh(this.circlePointerGeometry, this.circlePointerMaterial);
    this.circlePointer.rotateX(-Math.PI / 2)


    this.leftArrowGeometry = new THREE.PlaneGeometry(30, 10, 1, 1);
    this.leftArrowMaterial = new THREE.MeshBasicMaterial({
      color: 0x056CF2,
      // color: 0xfffffff,
      side: THREE.DoubleSide
    });
    this.leftArrow = new THREE.Mesh(this.leftArrowGeometry, this.leftArrowMaterial);
    this.leftArrow.rotateX(Math.PI / 2)
    this.leftArrow.rotateZ(Math.PI / 4)
    this.leftArrow.position.x = 7
    this.leftArrow.position.y = 0
    this.leftArrow.position.z = 0

    this.scene.add(this.leftArrow);

    this.rightArrowGeometry = new THREE.PlaneGeometry(30, 10, 1, 1);
    this.rightArrowMaterial = new THREE.MeshBasicMaterial({
      color: 0x056CF2,
      // color: 0xfffffff,
      side: THREE.DoubleSide
    });
    this.rightArrow = new THREE.Mesh(this.rightArrowGeometry, this.rightArrowMaterial);
    this.rightArrow.rotateX(Math.PI / 2)
    this.rightArrow.rotateZ(-Math.PI / 4)
    this.rightArrow.position.x = -7
    this.rightArrow.position.y = 0
    this.rightArrow.position.z = 0

    this.arrow = new THREE.Group();
    this.arrow.add(this.leftArrow)
    this.arrow.add(this.rightArrow)

    this.pointer = new THREE.Group()

    this.pointer.add(this.circlePointer)
    this.pointer.add(this.arrow)

    this.pointer.position.y = -10

    this.scene.add(this.pointer);

    //////////////////////////////////////////////////////////////////////////////// BOARD

    this.boardGame = []
    this.boardGameParameters = {
      index: 13,
      space: 120,
      offset: 0,
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
    }

    let sizeCloner = 4
    for (let i = 0; i < sizeCloner; i++) {
      switch (i) {
        case 0:
          this.boardGameParameters.position.x = 800
          this.boardGameParameters.position.z = -400
          break;
        case 1:
          this.boardGameParameters.position.x = 800
          this.boardGameParameters.position.z = 400
          break;
        case 2:
          this.boardGameParameters.position.x = -800
          this.boardGameParameters.position.z = 400
          break;
        case 3:
          this.boardGameParameters.position.x = -800
          this.boardGameParameters.position.z = -400
          break;
      }
      this.boardGameParameters.position.y = 10

      let clonedPosition = window.Object.assign({}, this.boardGameParameters.position)

      this.boardGame[i] = new Object(this.parameters, this.boardGameParameters.index, this.camera, clonedPosition, this.scene, this.renderer)
      if (i == 0 && modelOfObject == "board_game") {
        await this.boardGame[i].addOriginal(this.idOfObject)
      } else {
        await this.boardGame[i].add()
      }


      let boundingBox = await this.boardGame[i].getBoundingBox()
      boundingBox.position.x = this.boardGameParameters.position.x
      boundingBox.position.y = this.boardGameParameters.position.y
      boundingBox.position.z = this.boardGameParameters.position.z

      this.boundingBoxGroup.add(boundingBox)
    }


    //////////////////////////////////////////////////////////////////////////////// PLANT

    this.plant = []
    this.plantParameters = {
      index: 14,
      space: 120,
      offset: 0,
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
    }

    sizeCloner = 6
    for (let i = 0; i < sizeCloner; i++) {
      switch (i) {
        case 0:
          this.plantParameters.position.x = 0
          this.plantParameters.position.z = -400
          break;
        case 1:
          this.plantParameters.position.x = 1600
          this.plantParameters.position.z = -400
          break;
        case 2:
          this.plantParameters.position.x = 1600
          this.plantParameters.position.z = 400
          break;
        case 3:
          this.plantParameters.position.x = 0
          this.plantParameters.position.z = 400
          break;
        case 4:
          this.plantParameters.position.x = -1600
          this.plantParameters.position.z = 400
          break;
        case 5:
          this.plantParameters.position.x = -1600
          this.plantParameters.position.z = -400
          break;
      }
      this.plantParameters.position.y = 10

      let clonedPosition = window.Object.assign({}, this.plantParameters.position)

      this.plant[i] = new Object(this.parameters, this.plantParameters.index, this.camera, clonedPosition, this.scene, this.renderer)
      if (i == 0 && modelOfObject == "plant") {
        await this.plant[i].addOriginal(this.idOfObject)
      } else {
        await this.plant[i].add()
      }


      let boundingBox = await this.plant[i].getBoundingBox()
      boundingBox.position.x = this.plantParameters.position.x
      boundingBox.position.y = this.plantParameters.position.y
      boundingBox.position.z = this.plantParameters.position.z

      this.boundingBoxGroup.add(boundingBox)
    }

    //////////////////////////////////////////////////////////////////////////////// BOOK

    this.book = []
    this.bookParameters = {
      index: 11,
      space: 120,
      offset: 0,
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
    }

    sizeCloner = 2
    for (let i = 0; i < sizeCloner; i++) {
      switch (i) {
        case 0:
          this.bookParameters.position.x = 2000
          this.bookParameters.position.z = 0
          break;
        case 1:
          this.bookParameters.position.x = -2000
          this.bookParameters.position.z = 0
          break;
      }
      this.bookParameters.position.y = 10

      let clonedPosition = window.Object.assign({}, this.bookParameters.position)

      this.book[i] = new Object(this.parameters, this.bookParameters.index, this.camera, clonedPosition, this.scene, this.renderer)
      if (i == 0 && modelOfObject == "book") {
        await this.book[i].addOriginal(this.idOfObject)
      } else {
        await this.book[i].add()
      }


      let boundingBox = await this.book[i].getBoundingBox()
      boundingBox.position.x = this.bookParameters.position.x
      boundingBox.position.y = this.bookParameters.position.y
      boundingBox.position.z = this.bookParameters.position.z

      this.boundingBoxGroup.add(boundingBox)
    }

    //////////////////////////////////////////////////////////////////////////////// WINDOWS

    this.window = []
    this.windowParameters = {
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
    }

    sizeCloner = 8
    for (let i = 0; i < sizeCloner; i++) {
      switch (i) {
        case 0:
          this.windowParameters.position.x = 400
          this.windowParameters.position.z = -600
          break;
        case 1:
          this.windowParameters.position.x = 1200
          this.windowParameters.position.z = -600
          break;
        case 2:
          this.windowParameters.position.x = 1200
          this.windowParameters.position.z = 600
          break;
        case 3:
          this.windowParameters.position.x = 400
          this.windowParameters.position.z = 600
          break;
        case 4:
          this.windowParameters.position.x = -400
          this.windowParameters.position.z = 600
          break;
        case 5:
          this.windowParameters.position.x = -1200
          this.windowParameters.position.z = 600
          break;
        case 6:
          this.windowParameters.position.x = -1200
          this.windowParameters.position.z = -600
          break;
        case 7:
          this.windowParameters.position.x = -400
          this.windowParameters.position.z = -600
          break;
      }
      this.windowParameters.position.y = 400

      this.windowGeometry = new THREE.PlaneGeometry(200, 400, 1);
      this.windowMaterial = new THREE.MeshBasicMaterial({
        // color: 0x056CF2,
        color: 0xfffffff,
        side: THREE.DoubleSide
      });
      this.window[i] = new THREE.Mesh(this.windowGeometry, this.windowMaterial);

      this.window[i].position.x = this.windowParameters.position.x
      this.window[i].position.y = this.windowParameters.position.y
      this.window[i].position.z = this.windowParameters.position.z

      this.scene.add(this.window[i]);

      await this.addViewImageTexture(this.window[i])

    }


    ////////////////////////////////////////////////////////////////////////////////////////   FLOOR

    this.floorGeometry = new THREE.PlaneGeometry(4000, 400, 1);
    this.floorMaterial = new THREE.MeshBasicMaterial({
      // color: 0x056CF2,
      color: 0xfffffff,
      side: THREE.DoubleSide
    });
    this.floor = new THREE.Mesh(this.floorGeometry, this.floorMaterial);
    this.floor.rotateX(Math.PI / 2)
    this.floor.position.y = -200
    this.floor.name = "floor"
    await this.addViewImageTexture(this.floor)

    this.scene.add(this.floor);

    this.boundingBoxGroup.visible = false;
    await this.scene.add(this.boundingBoxGroup)

  }
  async createRoomFour(modelOfObject) {

    if (!this.isMobile) {
      this.orbit.target.x = 0
      this.orbit.target.z = 100
    }

    this.camera.position.set(0, 0, 0)

    //////////////////////////////////////////////////////////////////////////////// POINTER
    this.circlePointerGeometry = new THREE.RingGeometry(40, 50, 32);
    this.circlePointerMaterial = new THREE.MeshBasicMaterial({
      // color: 0xfffffff,
      color: 0x056CF2,
      side: THREE.DoubleSide
    });
    this.circlePointer = new THREE.Mesh(this.circlePointerGeometry, this.circlePointerMaterial);
    this.circlePointer.rotateX(-Math.PI / 2)


    this.leftArrowGeometry = new THREE.PlaneGeometry(30, 10, 1, 1);
    this.leftArrowMaterial = new THREE.MeshBasicMaterial({
      color: 0x056CF2,
      // color: 0xfffffff,
      side: THREE.DoubleSide
    });
    this.leftArrow = new THREE.Mesh(this.leftArrowGeometry, this.leftArrowMaterial);
    this.leftArrow.rotateX(Math.PI / 2)
    this.leftArrow.rotateZ(Math.PI / 4)
    this.leftArrow.position.x = 7
    this.leftArrow.position.y = 0
    this.leftArrow.position.z = 0

    this.scene.add(this.leftArrow);

    this.rightArrowGeometry = new THREE.PlaneGeometry(30, 10, 1, 1);
    this.rightArrowMaterial = new THREE.MeshBasicMaterial({
      color: 0x056CF2,
      // color: 0xfffffff,
      side: THREE.DoubleSide
    });
    this.rightArrow = new THREE.Mesh(this.rightArrowGeometry, this.rightArrowMaterial);
    this.rightArrow.rotateX(Math.PI / 2)
    this.rightArrow.rotateZ(-Math.PI / 4)
    this.rightArrow.position.x = -7
    this.rightArrow.position.y = 0
    this.rightArrow.position.z = 0

    this.arrow = new THREE.Group();
    this.arrow.add(this.leftArrow)
    this.arrow.add(this.rightArrow)

    this.pointer = new THREE.Group()

    this.pointer.add(this.circlePointer)
    this.pointer.add(this.arrow)

    this.pointer.position.y = -10

    this.scene.add(this.pointer);

    //////////////////////////////////////////////////////////////////////////////// Table
    this.table1Position = {
      x: 300,
      y: 100,
      z: 600,
    }
    this.table1 = new Object(this.parameters, 2, this.camera, this.table1Position, this.scene, this.renderer)
    if (modelOfObject == "table") {
      await this.table1.addOriginal(this.idOfObject)
    } else {
      await this.table1.add()
    }


    let rotateX = this.randomIntFromInterval(0, Math.PI * 2)
    let rotateY = this.randomIntFromInterval(0, Math.PI * 2)
    let rotateZ = this.randomIntFromInterval(0, Math.PI * 2)

    this.table1.rotateY(rotateY)
    this.table1.rotateX(rotateX)
    this.table1.rotateZ(rotateZ)

    let boundingBox = await this.table1.getBoundingBox()
    boundingBox.position.x = this.table1Position.x
    boundingBox.position.y = this.table1Position.y
    boundingBox.position.z = this.table1Position.z
    this.boundingBoxGroup.add(boundingBox)

    this.table2Position = {
      x: 1200,
      y: 400,
      z: 300,
    }
    this.table2 = new Object(this.parameters, 2, this.camera, this.table2Position, this.scene, this.renderer)
    await this.table2.add()

    rotateX = this.randomIntFromInterval(0, Math.PI * 2)
    rotateY = this.randomIntFromInterval(0, Math.PI * 2)
    rotateZ = this.randomIntFromInterval(0, Math.PI * 2)

    this.table2.rotateY(rotateY)
    this.table2.rotateX(rotateX)
    this.table2.rotateZ(rotateZ)

    boundingBox = await this.table2.getBoundingBox()
    boundingBox.position.x = this.table2Position.x
    boundingBox.position.y = this.table2Position.y
    boundingBox.position.z = this.table2Position.z
    this.boundingBoxGroup.add(boundingBox)

    this.table3Position = {
      x: 500,
      y: 600,
      z: 1000,
    }
    this.table3 = new Object(this.parameters, 2, this.camera, this.table3Position, this.scene, this.renderer)
    await this.table3.add()

    rotateX = this.randomIntFromInterval(0, Math.PI * 2)
    rotateY = this.randomIntFromInterval(0, Math.PI * 2)
    rotateZ = this.randomIntFromInterval(0, Math.PI * 2)

    this.table3.rotateY(rotateY)
    this.table3.rotateX(rotateX)
    this.table3.rotateZ(rotateZ)

    boundingBox = await this.table3.getBoundingBox()
    boundingBox.position.x = this.table3Position.x
    boundingBox.position.y = this.table3Position.y
    boundingBox.position.z = this.table3Position.z
    this.boundingBoxGroup.add(boundingBox)

    //////////////////////////////////////////////////////////////////////////////// COOKER
    this.cooker1Position = {
      x: 2000,
      y: 200,
      z: 600,
    }
    this.cooker1 = new Object(this.parameters, 4, this.camera, this.cooker1Position, this.scene, this.renderer)
    if (modelOfObject == "cooker") {
      await this.cooker1.addOriginal(this.idOfObject)
    } else {
      await this.cooker1.add()
    }


    rotateX = this.randomIntFromInterval(0, Math.PI * 2)
    rotateY = this.randomIntFromInterval(0, Math.PI * 2)
    rotateZ = this.randomIntFromInterval(0, Math.PI * 2)

    this.cooker1.rotateY(rotateY)
    this.cooker1.rotateX(rotateX)
    this.cooker1.rotateZ(rotateZ)

    boundingBox = await this.cooker1.getBoundingBox()
    boundingBox.position.x = this.cooker1Position.x
    boundingBox.position.y = this.cooker1Position.y
    boundingBox.position.z = this.cooker1Position.z
    this.boundingBoxGroup.add(boundingBox)

    this.cooker2Position = {
      x: -600,
      y: 400,
      z: -600,
    }
    this.cooker2 = new Object(this.parameters, 4, this.camera, this.cooker2Position, this.scene, this.renderer)
    await this.cooker2.add()

    rotateX = this.randomIntFromInterval(0, Math.PI * 2)
    rotateY = this.randomIntFromInterval(0, Math.PI * 2)
    rotateZ = this.randomIntFromInterval(0, Math.PI * 2)

    this.cooker2.rotateY(rotateY)
    this.cooker2.rotateX(rotateX)
    this.cooker2.rotateZ(rotateZ)

    boundingBox = await this.cooker2.getBoundingBox()
    boundingBox.position.x = this.cooker2Position.x
    boundingBox.position.y = this.cooker2Position.y
    boundingBox.position.z = this.cooker2Position.z
    this.boundingBoxGroup.add(boundingBox)

    this.cooker3Position = {
      x: 0,
      y: 200,
      z: -500,
    }
    this.cooker3 = new Object(this.parameters, 4, this.camera, this.cooker3Position, this.scene, this.renderer)
    await this.cooker3.add()

    rotateX = this.randomIntFromInterval(0, Math.PI * 2)
    rotateY = this.randomIntFromInterval(0, Math.PI * 2)
    rotateZ = this.randomIntFromInterval(0, Math.PI * 2)

    this.cooker3.rotateY(rotateY)
    this.cooker3.rotateX(rotateX)
    this.cooker3.rotateZ(rotateZ)

    boundingBox = await this.cooker3.getBoundingBox()
    boundingBox.position.x = this.cooker3Position.x
    boundingBox.position.y = this.cooker3Position.y
    boundingBox.position.z = this.cooker3Position.z
    this.boundingBoxGroup.add(boundingBox)


    //////////////////////////////////////////////////////////////////////////////// PHONE
    this.phone1Position = {
      x: -200,
      y: 100,
      z: 600,
    }
    this.phone1 = new Object(this.parameters, 8, this.camera, this.phone1Position, this.scene, this.renderer)
    if (modelOfObject == "smartphone") {
      await this.phone1.addOriginal(this.idOfObject)
    } else {
      await this.phone1.add()
    }


    rotateX = this.randomIntFromInterval(0, Math.PI * 2)
    rotateY = this.randomIntFromInterval(0, Math.PI * 2)
    rotateZ = this.randomIntFromInterval(0, Math.PI * 2)

    this.phone1.rotateY(rotateY)
    this.phone1.rotateX(rotateX)
    this.phone1.rotateZ(rotateZ)

    boundingBox = await this.phone1.getBoundingBox()
    boundingBox.position.x = this.phone1Position.x
    boundingBox.position.y = this.phone1Position.y
    boundingBox.position.z = this.phone1Position.z
    this.boundingBoxGroup.add(boundingBox)

    this.phone2Position = {
      x: 900,
      y: 500,
      z: -600,
    }
    this.phone2 = new Object(this.parameters, 8, this.camera, this.phone2Position, this.scene, this.renderer)
    await this.phone2.add()

    rotateX = this.randomIntFromInterval(0, Math.PI * 2)
    rotateY = this.randomIntFromInterval(0, Math.PI * 2)
    rotateZ = this.randomIntFromInterval(0, Math.PI * 2)

    this.phone2.rotateY(rotateY)
    this.phone2.rotateX(rotateX)
    this.phone2.rotateZ(rotateZ)

    boundingBox = await this.phone2.getBoundingBox()
    boundingBox.position.x = this.phone2Position.x
    boundingBox.position.y = this.phone2Position.y
    boundingBox.position.z = this.phone2Position.z
    this.boundingBoxGroup.add(boundingBox)


    this.phone3Position = {
      x: 2000,
      y: 100,
      z: 0,
    }
    this.phone3 = new Object(this.parameters, 8, this.camera, this.phone3Position, this.scene, this.renderer)
    await this.phone3.add()

    rotateX = this.randomIntFromInterval(0, Math.PI * 2)
    rotateY = this.randomIntFromInterval(0, Math.PI * 2)
    rotateZ = this.randomIntFromInterval(0, Math.PI * 2)

    this.phone3.rotateY(rotateY)
    this.phone3.rotateX(rotateX)
    this.phone3.rotateZ(rotateZ)

    boundingBox = await this.phone3.getBoundingBox()
    boundingBox.position.x = this.phone3Position.x
    boundingBox.position.y = this.phone3Position.y
    boundingBox.position.z = this.phone3Position.z
    this.boundingBoxGroup.add(boundingBox)



    //////////////////////////////////////////////////////////////////////////////// BED
    this.bed1Position = {
      x: 600,
      y: 400,
      z: 600,
    }
    this.bed1 = new Object(this.parameters, 9, this.camera, this.bed1Position, this.scene, this.renderer)
    if (modelOfObject == "bed") {
      await this.bed1.addOriginal(this.idOfObject)
    } else {
      await this.bed1.add()
    }


    rotateX = this.randomIntFromInterval(0, Math.PI * 2)
    rotateY = this.randomIntFromInterval(0, Math.PI * 2)
    rotateZ = this.randomIntFromInterval(0, Math.PI * 2)

    this.bed1.rotateY(rotateY)
    this.bed1.rotateX(rotateX)
    this.bed1.rotateZ(rotateZ)

    boundingBox = await this.bed1.getBoundingBox()
    boundingBox.position.x = this.bed1Position.x
    boundingBox.position.y = this.bed1Position.y
    boundingBox.position.z = this.bed1Position.z
    this.boundingBoxGroup.add(boundingBox)

    this.bed2Position = {
      x: -100,
      y: 700,
      z: 300,
    }
    this.bed2 = new Object(this.parameters, 9, this.camera, this.bed2Position, this.scene, this.renderer)
    await this.bed2.add()

    rotateX = this.randomIntFromInterval(0, Math.PI * 2)
    rotateY = this.randomIntFromInterval(0, Math.PI * 2)
    rotateZ = this.randomIntFromInterval(0, Math.PI * 2)

    this.bed2.rotateY(rotateY)
    this.bed2.rotateX(rotateX)
    this.bed2.rotateZ(rotateZ)

    boundingBox = await this.bed2.getBoundingBox()
    boundingBox.position.x = this.bed2Position.x
    boundingBox.position.y = this.bed2Position.y
    boundingBox.position.z = this.bed2Position.z
    this.boundingBoxGroup.add(boundingBox)

    this.bed3Position = {
      x: 1000,
      y: 100,
      z: 2000,
    }
    this.bed3 = new Object(this.parameters, 9, this.camera, this.bed3Position, this.scene, this.renderer)
    await this.bed3.add()

    rotateX = this.randomIntFromInterval(0, Math.PI * 2)
    rotateY = this.randomIntFromInterval(0, Math.PI * 2)
    rotateZ = this.randomIntFromInterval(0, Math.PI * 2)

    this.bed3.rotateY(rotateY)
    this.bed3.rotateX(rotateX)
    this.bed3.rotateZ(rotateZ)

    boundingBox = await this.bed3.getBoundingBox()
    boundingBox.position.x = this.bed3Position.x
    boundingBox.position.y = this.bed3Position.y
    boundingBox.position.z = this.bed3Position.z
    this.boundingBoxGroup.add(boundingBox)



    ////////////////////////////////////////////////////////////////////////////////////////   FLOOR

    this.floorGeometry = new THREE.CircleGeometry(2000, 32);
    this.floorMaterial = new THREE.MeshBasicMaterial({
      // color: 0x056CF2,
      color: 0xfffffff,
      side: THREE.DoubleSide
    });
    this.floor = new THREE.Mesh(this.floorGeometry, this.floorMaterial);
    this.floor.rotateX(Math.PI / 2)
    this.floor.position.y = -200
    this.floor.name = "floor"
    await this.addViewImageTexture(this.floor)

    this.scene.add(this.floor);

    this.boundingBoxGroup.visible = false;
    await this.scene.add(this.boundingBoxGroup)

  }
  async addViewImageTexture(object) {
    let ID = await this.firebaseManager.getIDfromIndex("VIEW")
    let imageTexture = await this.firebaseManager.getViewImageURL(ID)

    let tempCamera = new THREE.OrthographicCamera(window.innerWidth / -2, window.innerWidth / 2, window.innerHeight / 2, window.innerHeight / -2, 1, 1000)
    tempCamera.position.set(0, 100, 0)
    tempCamera.rotateX(90)

    let textureKey = new THREE.TextureLoader().load(imageTexture, function (t) {
      let material = new FixedMaterial({
        camera: tempCamera,
        texture: t,
        // color: '#080808',
        side: THREE.DoubleSide,
      })
      object.material = material
      object.material.transparent = true
      object.material.opacity = 0.5
    }.bind(this));

  }
  addCamera() {
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 10000)
    this.scene.add(this.camera)

    this.camera0 = new THREE.PerspectiveCamera(55, window.innerWidth / window.innerHeight, 0.1, 10000)
    this.chooseRoomScene.add(this.camera0)
    this.camera0.updateProjectionMatrix();

    this.testimonyOverlayCamera = new THREE.PerspectiveCamera(25, 1, 1, 10000)
    this.testimonyOverlayCamera.position.set(0, 0, 500)
    this.testimonyOverlayScene.add(this.testimonyOverlayCamera)
  }
  addOrbit() {
    this.orbit = new OrbitControls(this.camera, this.renderer.domElement)
    this.orbit.enableZoom = false
    this.orbit0 = new OrbitControls(this.camera0, this.renderer.domElement)
    this.orbit0.enableZoom = false
  }
  rotateOrbit() {
    this.orbit.autoRotate = true;
    this.orbit.update()
    this.orbit0.autoRotate = true;
    this.orbit0.update()
  }
  addDeviceOrientation() {
    this.controls = new DeviceOrientationControls(this.camera);
    this.controls.update()
    this.controls0 = new DeviceOrientationControls(this.camera0);
    this.controls0.update()
  }

  onTouchStart(e) {
    this.pointStartX = event.changedTouches[0].clientX
    this.pointStartY = event.changedTouches[0].clientY
  }
  onTouchMove(e) {}
  onTouchEnd(e) {
    this.pointEndX = event.changedTouches[0].clientX
    this.pointEndY = event.changedTouches[0].clientY
    if (!this.roomIsChosen && this.roomIsActive) {
      if (Math.abs(this.pointStartY - this.pointEndY) < 5 && Math.abs(this.pointStartX - this.pointEndX) < 5) {
        this.domManager.setLoader()

        this.chooseRoomScene.remove(this.outlinedImage)
        this.chooseRoomScene.remove(this.textOfOutlinedImage)
        this.isOutlined = false

        for (let i = 0; i < this.images.length; i++) {
          this.chooseRoomScene.remove(this.images[i])
        }


        this.createRoom()
        this.roomIsChosen = true
      }

    } else {
      if (!this.objectIsSelected) {
        if (Math.abs(this.pointStartY - this.pointEndY) < 5 && Math.abs(this.pointStartX - this.pointEndX) < 5) {
          this.addRaycast(e)
          if (this.pointer.visible == false && this.isOutlined) {
            this.objectIsSelected = true
            this.displaySelectedObject()
          }
        }
      }
    }
  }
  onDocumentMouseDown(event) {
    this.pointStartX = event.clientX
    this.pointStartY = event.clientY
  }
  onDeviceOrientationChangeEvent() {
    if (!this.roomIsChosen) {
      if (event) {
        this.roomIsActive = false;
        if (this.isOutlined) {
          this.chooseRoomScene.remove(this.outlinedImage)
          this.chooseRoomScene.remove(this.textOfOutlinedImage)
          this.isOutlined = false
        }

        let canvas = document.getElementById("memorialCanvas")
        var rect = canvas.getBoundingClientRect()

        this.center.x = (((window.innerWidth / 2) - rect.left) / window.innerWidth) * 2 - 1;
        this.center.y = -(((window.innerHeight / 2) - rect.top) / window.innerHeight) * 2 + 1;

        // this.center.x = ((this.pointStartX - rect.left) / window.innerWidth) * 2 - 1;
        // this.center.y = -((this.pointStartY - rect.top) / window.innerHeight) * 2 + 1;

        if (this.raycaster) {

          this.raycaster.setFromCamera(this.center, this.camera0);
          this.intersects = this.raycaster.intersectObjects(this.images, true);

          if (this.intersects[0]) {
            if (this.intersects[0].object && this.intersects[0].object.name != "outlined" && this.intersects[0].object.type == "Mesh") {
              var intersect = this.intersects[0];

              this.outlinedImage = this.windowFrame.clone()

              this.outlinedImage.name = "outlined"

              intersect.object.getWorldPosition(this.outlinedImage.children[0].position)
              intersect.object.getWorldQuaternion(this.outlinedImage.children[0].quaternion)
              this.roomIsActive = false;
              switch (intersect.object.name) {
                case "image1":
                  this.indexOfSelectedRoom = 0
                  this.textOfOutlinedImage = this.displayTextOfOutlinedImage("The Living Room", "4 video games", "4 sofas", "4 speakers", "4 televisions")
                  this.roomIsActive = true;
                  break
                case "image2":
                  this.indexOfSelectedRoom = 1
                  this.textOfOutlinedImage = this.displayTextOfOutlinedImage("The Playfield", "6 board games", "6 plants", "6 windows", "2 books")
                  this.roomIsActive = true;
                  break
                case "image3":
                  this.indexOfSelectedRoom = 2
                  this.textOfOutlinedImage = this.displayTextOfOutlinedImage("The Home Office", "1 headphone", "1 computer", "3 desks", "1 chair")
                  this.roomIsActive = true;
                  break
                case "image4":
                  this.indexOfSelectedRoom = 3
                  this.textOfOutlinedImage = this.displayTextOfOutlinedImage("The Dream", "3 beds", "3 smartphones", "3 cooker", "3 tables")
                  this.roomIsActive = true;
                  break
                case "image5":
                  this.roomIsActive = false;
                  break
                case "image6":
                  this.roomIsActive = false;
                  break
              }
              intersect.object.getWorldPosition(this.textOfOutlinedImage.position)
              intersect.object.getWorldQuaternion(this.textOfOutlinedImage.quaternion)

              this.chooseRoomScene.add(this.outlinedImage)
              if (this.roomIsActive) {
                this.chooseRoomScene.add(this.textOfOutlinedImage)
              }
              this.isOutlined = true;
            }
          }

        }
      }

    } else {
      if (!this.objectIsSelected) {
        if (this.isOutlined) {
          this.isOutlined = false
          this.scene.remove(this.scene.children[this.scene.children.length - 1])
          this.mesh.children[0].visible = true
        }
        let canvas = document.getElementById("memorialCanvas")
        var rect = canvas.getBoundingClientRect()

        // this.center.x = (((window.innerWidth / 2) - rect.left) / window.innerWidth) * 2 - 1;
        // this.center.y = -(((window.innerHeight / 2) - rect.top) / window.innerHeight) * 2 + 1;

        this.center.x = ((this.pointStartX - rect.left) / window.innerWidth) * 2 - 1;
        this.center.y = -((this.pointStartY - rect.top) / window.innerHeight) * 2 + 1;

        if (this.raycaster) {
          this.raycaster.setFromCamera(this.center, this.camera);
          this.intersects = this.raycaster.intersectObjects([this.floor, ...this.boundingBoxGroup.children], true);
          if (this.intersects[0]) {
            if (this.intersects[0].object && this.intersects[0].object.name == "floor") {
              var intersect = this.intersects[0];
              this.pointer.position.copy(intersect.point).add(intersect.face.normal)
              this.pointer.position.y += 10
              this.pointer.visible = true
              this.pointer.rotation.y = Math.atan2((this.camera.position.x - this.pointer.position.x), (this.camera.position.z - this.pointer.position.z));
            }

            if (this.intersects[0].object && this.intersects[0].object.name != "floor" && this.intersects[0].object.name != "outlined") {
              var intersect = this.intersects[0];

              this.mesh = this.scene.children.find((it) => it.uuid === intersect.object.refUuid)
              this.firebaseID = intersect.object.firebaseID
              if (this.mesh) {
                this.isOutlined = true;
                this.outlinedObject = new Object(this.parameters, this.mesh.name, this.camera, this.mesh.position, this.scene, this.renderer)
                this.outlinedObject.setOutlineTexture(this.mesh)
                this.outlinedObject.outlineMesh.name = "outlined"
                this.pointer.visible = false
                this.mesh.children[0].visible = false
              }
            }
          }
        }
      }
    }
  }

  onDocumentMouseMove(event) {
    if (!this.roomIsChosen) {
      if (event) {
        this.roomIsActive = false;

        if (this.isOutlined) {
          this.chooseRoomScene.remove(this.outlinedImage)
          this.chooseRoomScene.remove(this.textOfOutlinedImage)
          this.isOutlined = false
        }

        let canvas = document.getElementById("memorialCanvas")
        var rect = canvas.getBoundingClientRect()

        this.mouse.x = ((event.clientX - rect.left) / window.innerWidth) * 2 - 1;
        this.mouse.y = -((event.clientY - rect.top) / window.innerHeight) * 2 + 1;

        if (this.raycaster) {

          this.raycaster.setFromCamera(this.mouse, this.camera0);
          this.intersects = this.raycaster.intersectObjects(this.images, true);

          if (this.intersects[0] && this.intersects[0].object.name != "outlined" && this.intersects[0].object.type == "Mesh") {
            var intersect = this.intersects[0];

            this.outlinedImage = this.windowFrame.clone()

            this.outlinedImage.name = "outlined"

            intersect.object.getWorldPosition(this.outlinedImage.children[0].position)
            intersect.object.getWorldQuaternion(this.outlinedImage.children[0].quaternion)
            this.roomIsActive = false;
            switch (intersect.object.name) {
              case "image1":
                this.indexOfSelectedRoom = 0
                this.textOfOutlinedImage = this.displayTextOfOutlinedImage("The Living Room", "4 video games", "4 sofas", "4 speakers", "4 televisions")
                this.roomIsActive = true;
                break
              case "image2":
                this.indexOfSelectedRoom = 1
                this.textOfOutlinedImage = this.displayTextOfOutlinedImage("The Playfield", "6 board games", "6 plants", "6 windows", "2 books")
                this.roomIsActive = true;
                break
              case "image3":
                this.indexOfSelectedRoom = 2
                this.textOfOutlinedImage = this.displayTextOfOutlinedImage("The Home Office", "1 headphone", "1 computer", "3 desks", "1 chair")
                this.roomIsActive = true;
                break
              case "image4":
                this.indexOfSelectedRoom = 3
                this.textOfOutlinedImage = this.displayTextOfOutlinedImage("The Dream", "3 beds", "3 smartphones", "3 cooker", "3 tables")
                this.roomIsActive = true;
                break
              case "image5":
                this.roomIsActive = false;
                break
              case "image6":
                this.roomIsActive = false;
                break

            }
            intersect.object.getWorldPosition(this.textOfOutlinedImage.position)
            intersect.object.getWorldQuaternion(this.textOfOutlinedImage.quaternion)

            this.chooseRoomScene.add(this.outlinedImage)
            if (this.roomIsActive) {
              this.chooseRoomScene.add(this.textOfOutlinedImage)
            }
            this.isOutlined = true;
          }
        }
      }

    } else {
      if (!this.objectIsSelected) {
        if (event) {
          if (this.isOutlined) {
            this.isOutlined = false
            this.scene.remove(this.scene.children[this.scene.children.length - 1])
            this.mesh.children[0].visible = true
          }

          let canvas = document.getElementById("memorialCanvas")
          var rect = canvas.getBoundingClientRect()

          this.mouse.x = ((event.clientX - rect.left) / window.innerWidth) * 2 - 1;
          this.mouse.y = -((event.clientY - rect.top) / window.innerHeight) * 2 + 1;

          if (this.raycaster) {

            this.raycaster.setFromCamera(this.mouse, this.camera);
            this.intersects = this.raycaster.intersectObjects([this.floor, ...this.boundingBoxGroup.children], true);


            if (this.intersects[0] && this.intersects[0].object.name == "floor") {
              var intersect = this.intersects[0];
              this.pointer.position.copy(intersect.point).add(intersect.face.normal)
              this.pointer.position.y += 10
              this.pointer.visible = true
              this.pointer.rotation.y = Math.atan2((this.camera.position.x - this.pointer.position.x), (this.camera.position.z - this.pointer.position.z));
            }

            if (this.intersects[0] && this.intersects[0].object.name != "floor" && this.intersects[0].object.name != "outlined") {
              var intersect = this.intersects[0];

              this.mesh = this.scene.children.find((it) => it.uuid === intersect.object.refUuid)
              this.firebaseID = intersect.object.firebaseID

              if (this.mesh) {
                this.isOutlined = true;
                this.outlinedObject = new Object(this.parameters, this.mesh.name, this.camera, this.mesh.position, this.scene, this.renderer)
                this.outlinedObject.setOutlineTexture(this.mesh)
                this.outlinedObject.outlineMesh.name = "outlined"
                this.pointer.visible = false
                this.mesh.children[0].visible = false
              }

            }
          }
        }
      }

    }

  }
  displayTextOfOutlinedImage(msg1, msg2, msg3, msg4, msg5) {
    const ctx = document.createElement('canvas').getContext('2d');
    ctx.canvas.style.backgroundColor = "transparent"
    ctx.canvas.width = 1000;
    ctx.canvas.height = 700;
    ctx.fillStyle = '#056bf0'
    // ctx.fillStyle = 'rgba(0,0,0,0)'
    // ctx.fillStyle = 'green'
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.fillStyle = 'white'
    ctx.font = "100px Roboto";
    ctx.fillText(msg1, 10, 100);

    ctx.font = "60px Roboto";
    ctx.fillText(msg2, 10, 220);
    ctx.font = "60px Roboto";
    ctx.fillText(msg3, 10, 300);
    ctx.font = "60px Roboto";
    ctx.fillText(msg4, 10, 380);
    ctx.font = "60px Roboto";
    ctx.fillText(msg5, 10, 460);

    const texture = new THREE.CanvasTexture(ctx.canvas);

    var textGeometry = new THREE.PlaneGeometry(50, 35, 32);
    var textMaterial = new THREE.MeshBasicMaterial({
      map: texture,
    });
    var textModel = new THREE.Mesh(textGeometry, textMaterial);

    var textContainer = new THREE.Group();
    textContainer.add(textModel)

    textModel.position.x = -5;
    // textModel.position.z = 10;
    textModel.position.y = -90;

    return textContainer
  }

  onDocumentMouseUp() {
    this.pointEndX = event.clientX
    this.pointEndY = event.clientY
    if (!this.roomIsChosen && this.roomIsActive) {
      if (Math.abs(this.pointStartY - this.pointEndY) < 5 && Math.abs(this.pointStartX - this.pointEndX) < 5) {
        this.domManager.setLoader()
        this.chooseRoomScene.remove(this.outlinedImage)
        this.chooseRoomScene.remove(this.textOfOutlinedImage)

        for (let i = 0; i < this.images.length; i++) {
          this.chooseRoomScene.remove(this.images[i])
        }

        this.createRoom()
        this.roomIsChosen = true
      }

    } else {
      if (!this.objectIsSelected && this.renderMode != "choiceOfRoom") {
        if (Math.abs(this.pointStartY - this.pointEndY) < 5 && Math.abs(this.pointStartX - this.pointEndX) < 5) {
          this.addRaycast(event)
          if (this.pointer.visible == false && this.isOutlined) {
            this.objectIsSelected = true
            this.scene.remove(this.scene.children[this.scene.children.length - 1])
            this.isOutlined = false
            this.mesh.children[0].visible = true
            this.pointer.visible = false
            this.displaySelectedObject()
          }
        }
      }
    }
  }
  async displaySelectedObject() {

    this.testimonyOverlayOrbit = new OrbitControls(this.testimonyOverlayCamera, this.testimonyOverlayRenderer.domElement)
    this.testimonyOverlayOrbit.enableZoom = false
    console.log(this.testimonyOverlayOrbit)

    this.renderMode = "displayObject"

    this.positionOfSelectedObject = new Vector3(0, 0, 0)
    this.selectedObject = new Object(this.parameters, this.mesh.name, this.testimonyOverlayCamera, this.positionOfSelectedObject, this.testimonyOverlayScene, this.testimonyOverlayRenderer)

    await this.selectedObject.addOriginal(this.firebaseID)
    this.selectedObject.addLabelModelToObject()

    let testimonyValues = await this.firebaseManager.getTestimony(this.mesh.children[0].name, this.firebaseID)
    this.domManager.toggleTestimonyOverlayContainer()
    // this.domManager.toggleMainButtonOverlay()
    this.domManager.displayOverlayTestimony(testimonyValues)
    // this.domManager.changeMainButtonOverlay("See in memorial")
    this.domManager.changeBackButtonOverlay("Link")

  }
  displayUrl() {
    this.renderMode = "displayUrl"
    while (this.testimonyOverlayScene.children.length > 0) {
      this.testimonyOverlayScene.remove(this.testimonyOverlayScene.children[0]);
    }
    this.selectedObject.addLabelModelOfObject(this.testimonyOverlayScene)
    let urlOfObject = "https://lockdown.memorial/?model=" + this.mesh.children[0].name + "&id=" + this.firebaseID + ""

    this.domManager.toggleBackButtonOverlay()
    this.domManager.setCopyUrlButton(urlOfObject)
    this.domManager.addCopyUrlText()
    this.domManager.removeDateOfTestimony()

  }
  async displayNewMemorial() {
    while (this.testimonyOverlayScene.children.length > 0) {
      this.testimonyOverlayScene.remove(this.testimonyOverlayScene.children[0]);
    }
    this.domManager.changeBackHeaderButton("Back to memorial's choice")
    if (document.getElementById("urlButton")) {
      this.domManager.removeCopyUrlButton()
      this.domManager.toggleBackButtonOverlay()
    }
    this.domManager.toggleTestimonyOverlayContainer()
    this.objectIsSelected = false
    this.renderMode = "displayMemorial"
  }
  resetCameraPosition() {
    switch (this.index) {
      case 0:
        this.camera.position.set(0, 0, 0)
        if (!this.isMobile) {
          this.orbit.target.x = 0
          this.orbit.target.z = 100
        }
        break
      case 1:
        this.camera.position.set(0, 0, 0)
        if (!this.isMobile) {
          this.orbit.target.x = 0
          this.orbit.target.z = 100
        }
        break
      case 2:
        this.camera.position.set(1000, 0, 200)
        if (!this.isMobile) {
          this.orbit.target.x = 820
          this.orbit.target.z = 151
        }
        break
      case 3:
        this.camera.position.set(0, 0, 0)
        if (!this.isMobile) {
          this.orbit.target.x = 0
          this.orbit.target.z = 100
        }
        break
    }
  }
  addRaycast(event) {

    let canvas = document.getElementById("memorialCanvas")
    var rect = canvas.getBoundingClientRect()

    if (this.isMobile) {
      this.center.x = (((window.innerWidth / 2) - rect.left) / window.innerWidth) * 2 - 1;
      this.center.y = -(((window.innerHeight / 2) - rect.top) / window.innerHeight) * 2 + 1;
      this.raycaster.setFromCamera(this.center, this.camera);
    } else {
      this.mouse.x = ((event.clientX - rect.left) / window.innerWidth) * 2 - 1;
      this.mouse.y = -((event.clientY - rect.top) / window.innerHeight) * 2 + 1;
      this.raycaster.setFromCamera(this.mouse, this.camera);
    }

    this.intersects = this.raycaster.intersectObjects(this.scene.children, true);
    if (this.intersects[0] && this.intersects[0].object.name == "floor") {
      var intersect = this.intersects[0];
      this.setNewPosition(intersect)
    }
  }
  async setNewPosition(intersect) {
    if (this.isMobile) {
      let increaseCameraPosition = {
        x: 0,
        y: 0,
        z: 0,
      }
      increaseCameraPosition.x = (intersect.point.x - this.camera.position.x) / 100
      increaseCameraPosition.y = (intersect.point.y - this.camera.position.y + 200) / 100
      increaseCameraPosition.z = (intersect.point.z - this.camera.position.z) / 100

      for (let i = 0; i <= 80; i++) {
        this.camera.position.x += increaseCameraPosition.x
        this.camera.position.y += increaseCameraPosition.y
        this.camera.position.z += increaseCameraPosition.z
        await this.timer(10)
      }

    } else {
      let increaseCameraPosition = {
        x: 0,
        y: 0,
        z: 0,
      }
      let increaseOrbitPosition = {
        x: 0,
        y: 0,
        z: 0,
      }
      increaseCameraPosition.x = (intersect.point.x - this.camera.position.x) / 100
      increaseCameraPosition.y = (intersect.point.y - this.camera.position.y + 200) / 100
      increaseCameraPosition.z = (intersect.point.z - this.camera.position.z) / 100

      increaseOrbitPosition.x = (intersect.point.x - this.orbit.target.x) / 100
      increaseOrbitPosition.y = (intersect.point.y - this.orbit.target.y + 200) / 100
      increaseOrbitPosition.z = (intersect.point.z - this.orbit.target.z) / 100
      for (let i = 0; i <= 100; i++) {
        this.orbit.target.x += increaseOrbitPosition.x
        this.orbit.target.y += increaseOrbitPosition.y
        this.orbit.target.z += increaseOrbitPosition.z
        await this.timer(2)
      }
      for (let i = 0; i <= 80; i++) {
        this.camera.position.x += increaseCameraPosition.x
        this.camera.position.y += increaseCameraPosition.y
        this.camera.position.z += increaseCameraPosition.z
        await this.timer(10)
      }
    }
  }
  timer(ms) {
    return new Promise(res => setTimeout(res, ms));
  }
  render() {
    if (this.renderIsOn) {
      if (this.renderMode == "choiceOfRoom") {
        if (this.isMobile) {
          this.controls0.update()
        } else {
          this.orbit0.update()
        }
        this.renderer.render(this.chooseRoomScene, this.camera0)
      } else if (this.renderMode == "displayMemorial") {
        if (this.isMobile) {
          this.controls.update()
        } else {
          this.orbit.update()
        }
        this.renderer.render(this.scene, this.camera)

      } else if (this.renderMode == "displayObject") {
        this.testimonyOverlayOrbit.update()
        this.testimonyOverlayRenderer.render(this.testimonyOverlayScene, this.testimonyOverlayCamera)
      } else if (this.renderMode == "displayUrl") {
        this.renderer.render(this.scene, this.camera)
        this.testimonyOverlayOrbit.update()
        this.testimonyOverlayRenderer.render(this.testimonyOverlayScene, this.testimonyOverlayCamera)
      }
    }
  }
  removeMemorial() {
    while (this.scene.children.length > 0) {
      this.scene.remove(this.scene.children[0]);
    }
    this.renderer.render(this.scene, this.camera)
  }
  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}