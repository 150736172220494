import 'babel-polyfill'
export class DomManager {
  constructor() {}
  ///////////////////////////////////////////////////////////////GENERAL
  setLoader() {
    let loaderOverlay = document.createElement("div")
    loaderOverlay.setAttribute("id", "loaderOverlay")
    document.body.appendChild(loaderOverlay)

    let loaderContainer = document.createElement("div")
    loaderContainer.setAttribute("id", "loaderContainer")
    document.body.appendChild(loaderContainer)

    let loader = document.createElement("img")
    loader.setAttribute("id", "gifLoader")
    loaderContainer.appendChild(loader)
    loader.setAttribute("src", require("../img/loading2.gif"))

    let loaderText = document.createElement("p")
    loaderText.setAttribute("class", "loaderText")
    loaderText.innerHTML = "Loading..."
    loaderContainer.appendChild(loaderText)
  }
  removeLoader() {
    let loaderContainer = document.getElementById("loaderContainer")
    loaderContainer.remove()

    let loaderOverlay = document.getElementById("loaderOverlay")
    loaderOverlay.remove()
  }

  setHeader(isMobile) {
    if (isMobile) {
      let header = document.createElement("header")
      header.setAttribute("id", "header")
      document.body.appendChild(header)

      let headerTitle = document.createElement('div')
      headerTitle.setAttribute("id", "headerTitle");
      header.appendChild(headerTitle)

      let navElement = document.createElement("nav")
      navElement.setAttribute("id", "mobileMenuContainer")
      navElement.setAttribute("role", "navigation")
      header.appendChild(navElement)

      let menuToggle = document.createElement("div")
      menuToggle.setAttribute("id", "menuToggle")
      navElement.appendChild(menuToggle)

      let inputCheckbox = document.createElement("input")
      inputCheckbox.setAttribute("type", "checkbox")
      menuToggle.appendChild(inputCheckbox)

      let firstSpan = document.createElement("span")
      menuToggle.appendChild(firstSpan)
      let secondSpan = document.createElement("span")
      menuToggle.appendChild(secondSpan)
      let thirdSpan = document.createElement("span")
      menuToggle.appendChild(thirdSpan)

      let menu = document.createElement("ul")
      menu.setAttribute("id", "menu")
      menuToggle.appendChild(menu)

      let homeLink = document.createElement("a")
      homeLink.setAttribute("id", "homeLink")
      homeLink.setAttribute("href", "#")
      menu.appendChild(homeLink)
      let homeText = document.createElement("li")
      homeText.innerHTML = "Home"
      homeLink.appendChild(homeText)

      let archiveLink = document.createElement("a")
      archiveLink.setAttribute("id", "archiveLink")
      archiveLink.setAttribute("href", "#")
      menu.appendChild(archiveLink)
      let archiveText = document.createElement("li")
      archiveText.innerHTML = "Archive"
      archiveLink.appendChild(archiveText)

      let memorialLink = document.createElement("a")
      memorialLink.setAttribute("href", "#")
      memorialLink.setAttribute("id", "memorialLink")
      menu.appendChild(memorialLink)
      let memorialText = document.createElement("li")
      memorialText.innerHTML = "Memorials"
      memorialLink.appendChild(memorialText)

      let testimonyLink = document.createElement("a")
      testimonyLink.setAttribute("href", "#")
      testimonyLink.setAttribute("id", "testimonyLink")
      menu.appendChild(testimonyLink)
      let testimonyText = document.createElement("li")
      testimonyText.innerHTML = "Testimony"
      testimonyLink.appendChild(testimonyText)

      let aboutLink = document.createElement("a")
      aboutLink.setAttribute("href", "#")
      aboutLink.setAttribute("id", "aboutLink")
      menu.appendChild(aboutLink)
      let aboutText = document.createElement("li")
      aboutText.innerHTML = "About"
      aboutLink.appendChild(aboutText)

    } else {
      let header = document.createElement("header")
      header.setAttribute("id", "header")
      document.body.appendChild(header)

      let headerTitle = document.createElement('h1')
      headerTitle.setAttribute("id", "headerTitle");
      header.appendChild(headerTitle)

      let headerMenuContainer = document.createElement("div")
      headerMenuContainer.setAttribute("id", "headerMenuContainer")
      header.appendChild(headerMenuContainer)

      let archiveContainer = document.createElement("li")
      archiveContainer.setAttribute("class", "headerMenuElement")
      headerMenuContainer.appendChild(archiveContainer)

      let archiveLink = document.createElement("a")
      archiveLink.setAttribute("href", "#")
      archiveLink.setAttribute("id", "archiveLink")
      archiveLink.innerHTML = "Archive"
      archiveContainer.appendChild(archiveLink)

      let memorialContainer = document.createElement("li")
      memorialContainer.setAttribute("class", "headerMenuElement")
      headerMenuContainer.appendChild(memorialContainer)

      let memorialLink = document.createElement("a")
      memorialLink.setAttribute("href", "#")
      memorialLink.setAttribute("id", "memorialLink")
      memorialLink.innerHTML = "Memorials"
      memorialContainer.appendChild(memorialLink)

      let aboutContainer = document.createElement("li")
      aboutContainer.setAttribute("class", "headerMenuElement")
      headerMenuContainer.appendChild(aboutContainer)

      let aboutLink = document.createElement("a")
      aboutLink.setAttribute("href", "#")
      aboutLink.setAttribute("id", "aboutLink")
      aboutLink.innerHTML = "About"
      aboutContainer.appendChild(aboutLink)

    }
  }
  changeHeaderText(text) {
    let headerTitle = document.getElementById("headerTitle")
    headerTitle.innerHTML = text
  }
  removeHeaderText() {
    let headerText = document.getElementById("headerText")
    headerText.remove()
  }
  toggleHeader() {
    let headerTitle = document.getElementById("headerTitle")
    headerTitle.classList.toggle("nonVisible")
  }
  removeHeaderTitle() {
    let headerTitle = document.getElementById("headerTitle")
    headerTitle.style.display = "none"
  }
  displayHeaderTitle() {
    let headerTitle = document.getElementById("headerTitle")
    headerTitle.style.display = "inline-block"
  }
  setBackHeaderButton(message) {
    this.header = document.getElementById("header")

    this.backHeaderButton = document.createElement("div")
    this.backHeaderButton.setAttribute("id", "backHeaderButton")
    this.header.appendChild(this.backHeaderButton)

    let arrowBackButton = document.createElement("i")
    arrowBackButton.setAttribute("id", "arrow")
    this.backHeaderButton.appendChild(arrowBackButton)

    let backHeaderButtonText = document.createElement("div")
    backHeaderButtonText.setAttribute("id", "backHeaderButtonText")
    backHeaderButtonText.innerHTML = message
    this.backHeaderButton.appendChild(backHeaderButtonText)
    // this.headerTitle = document.getElementById("headerTitle")
    // this.headerTitle.parentNode.insertBefore(this.backHeaderButton, this.headerTitle)
  }
  changeBackHeaderButton(message) {
    let backHeaderButtonText = document.getElementById("backHeaderButtonText")
    backHeaderButtonText.innerHTML = message
  }
  removeBackHeaderButton() {
    this.backHeaderButton = document.getElementById("backHeaderButton")
    this.backHeaderButton.remove()
  }
  setMenu(isMobile) {
    if (!isMobile) {
      this.backgroundVideo = document.createElement("video")
      this.backgroundVideo.setAttribute("id", "backgroundVideo")
      this.backgroundVideo.setAttribute("autoplay", "")
      this.backgroundVideo.setAttribute("muted", "")
      this.backgroundVideo.setAttribute("loop", "")
      this.backgroundVideo.setAttribute("playsinline", "")
      this.backgroundVideo.setAttribute("poster", require("../img/home.png"))

      this.videoSource2 = document.createElement("source")
      this.videoSource2.setAttribute("src", require("../img/home.mp4"))
      this.videoSource2.setAttribute("type", "video/mp4")
      this.backgroundVideo.appendChild(this.videoSource2)

      document.body.appendChild(this.backgroundVideo)
    } else {
      this.backgroundVideo = document.createElement("img")
      this.backgroundVideo.setAttribute("id", "backgroundVideo")

      this.backgroundVideo.setAttribute("src", require("../img/homeMobile.jpg"))
      document.body.appendChild(this.backgroundVideo)
    }

    this.backgroundOverlay = document.createElement("div")
    this.backgroundOverlay.setAttribute("id", "backgroundOverlay")
    document.body.appendChild(this.backgroundOverlay)

    this.titleMenu = document.createElement("h1")
    this.titleMenu.setAttribute("id", "titleMenu")
    this.titleMenu.innerHTML = "LOCKDOWN MEMORIAL ARCHIVE"
    document.body.appendChild(this.titleMenu)

    this.menuContainer = document.createElement("div")
    this.menuContainer.setAttribute("id", "menuContainer")

    this.archiveMenuContainer = document.createElement("div")
    this.archiveMenuContainer.setAttribute("class", "sectionMenuContainer")
    this.archiveMenuContainer.setAttribute("id", "archiveLink")
    this.menuContainer.appendChild(this.archiveMenuContainer)

    this.archiveMenuText = document.createElement('p')
    this.archiveMenuText.setAttribute("class", "menuParagraph")
    this.archiveMenuLink = document.createElement('a')
    // this.archiveMenuLink.setAttribute("href", "#")
    this.archiveMenuLink.setAttribute("class", "menuLink")
    this.archiveMenuContainer.appendChild(this.archiveMenuLink)
    this.archiveMenuContainer.appendChild(this.archiveMenuText)

    this.memorialMenuContainer = document.createElement("div")
    this.memorialMenuContainer.setAttribute("class", "sectionMenuContainer")
    this.memorialMenuContainer.setAttribute("id", "memorialLink")
    this.menuContainer.appendChild(this.memorialMenuContainer)

    this.memorialMenuText = document.createElement('p')
    this.memorialMenuText.setAttribute("class", "menuParagraph")
    this.memorialMenuLink = document.createElement('a')
    this.memorialMenuLink.setAttribute("class", "menuLink")

    this.memorialMenuContainer.appendChild(this.memorialMenuLink)
    this.memorialMenuContainer.appendChild(this.memorialMenuText)


    this.testimonyMenuContainer = document.createElement("div")
    this.testimonyMenuContainer.setAttribute("class", "sectionMenuContainer")
    this.testimonyMenuContainer.setAttribute("id", "testimonyLink")
    this.menuContainer.appendChild(this.testimonyMenuContainer)

    this.testimonyMenuText = document.createElement('p')
    this.testimonyMenuText.setAttribute("class", "menuParagraph")
    this.testimonyMenuLink = document.createElement('a')
    this.testimonyMenuLink.setAttribute("class", "menuLink")

    this.testimonyMenuContainer.appendChild(this.testimonyMenuLink)
    this.testimonyMenuContainer.appendChild(this.testimonyMenuText)


    this.archiveMenuText.innerHTML = "Browse through all the testimonies"
    this.archiveMenuLink.innerHTML = "Explore the archive"

    this.memorialMenuText.innerHTML = "Immerse yourself into a lockdown atmosphere"
    this.memorialMenuLink.innerHTML = "Visit the memorials"

    this.testimonyMenuText.innerHTML = "Share your own lockdown testimony"
    this.testimonyMenuLink.innerHTML = "Create your testimony"

    document.body.appendChild(this.menuContainer)
  }
  removeMenu() {
    this.menuContainer.remove()
    this.titleMenu.remove()
    this.backgroundVideo.remove()
    this.backgroundOverlay.remove()
  }
  toggleArchiveLink() {
    this.archiveLink = document.getElementById("archiveLink")
    this.archiveLink.classList.toggle("bigLink")
  }
  toggleMemorialLink() {
    this.memorialLink = document.getElementById("memorialLink")
    this.memorialLink.classList.toggle("bigLink")
  }

  setInstructionText() {
    this.instruction = document.createElement("div")
    this.instruction.setAttribute("class", "instruction");
    document.body.appendChild(this.instruction)

    this.instructionText = document.createElement("div")
    this.instructionText.setAttribute("class", "textInstruction");
    this.instruction.appendChild(this.instructionText)

    this.titleText = document.createElement('div')
    this.titleText.setAttribute("id", "titleText");
    this.instructionText.appendChild(this.titleText)

    this.centralText = document.createElement('div')
    this.centralText.setAttribute("id", "centralText");
    this.centralText.setAttribute("class", "smallCentralText");
    this.instructionText.appendChild(this.centralText)
  }
  toggleInstructionText() {
    this.centralText = document.getElementById("centralText")
    this.centralText.classList.toggle("bigCentralText")
    this.centralText.classList.toggle("smallCentralText")
  }
  removeInstructionText() {
    this.instruction = document.getElementsByClassName("instruction")[0]
    this.instruction.remove()
  }
  removeMain() {
    this.main = document.getElementById("main")
    this.main.remove()
  }

  ///////////////////////////////////////////////////////////////TESTIMONY

  changeIndexOfInstruction(text) {
    let indexOfInstruction = document.getElementById("indexOfInstruction")
    indexOfInstruction.innerHTML = text
  }
  removeIndexOfInstruction() {
    let indexOfInstruction = document.getElementById("indexOfInstruction")
    indexOfInstruction.classList.toggle("nonVisible")
  }
  changeTitleText(text) {
    let titleOfInstruction = document.getElementById('titleOfInstruction')
    titleOfInstruction.innerHTML = text
  }
  changeCentralText(text) {
    let paragraphOfInstruction = document.getElementById('paragraphOfInstruction')
    paragraphOfInstruction.innerHTML = text
  }
  removeCentralText() {
    this.centralText = document.getElementById('centralText')
    this.centralText.remove()
  }
  displayWarning() {
    this.warningContainer = document.createElement("div")
    this.warningContainer.setAttribute("id", "warningContainer")
    document.body.appendChild(this.warningContainer)

    this.warningTitle = document.createElement("div")
    this.warningTitle.setAttribute("id", "warningTitle")
    this.warningTitle.innerHTML = "BE AWARE!"
    this.warningContainer.appendChild(this.warningTitle)

    this.warningText = document.createElement("div")
    this.warningText.setAttribute("id", "warningText")
    this.warningText.innerHTML = "All testimonials will be published and freely accessible to anyone visiting a newer version of this website."
    this.warningContainer.appendChild(this.warningText)

    this.backgroundWarning = document.createElement("div")
    this.backgroundWarning.setAttribute("id", "backgroundWarning")
    document.body.appendChild(this.backgroundWarning)
  }
  removeWarning() {
    this.warningContainer = document.getElementById("warningContainer")
    this.warningContainer.remove()
    this.backgroundWarning = document.getElementById("backgroundWarning")
    this.backgroundWarning.remove()
  }
  displayOverlayMessage(title, message) {
    let overlayMessageContainer = document.createElement("div")
    overlayMessageContainer.setAttribute("id", "overlayMessageContainer")
    overlayMessageContainer.setAttribute("class", "overlayMessageContainer")
    document.body.appendChild(overlayMessageContainer)

    let overlayMessageCloseButton = document.createElement("div")
    overlayMessageCloseButton.setAttribute("id", "overlayMessageCloseButton")
    overlayMessageContainer.appendChild(overlayMessageCloseButton)

    let span1 = document.createElement("span")
    overlayMessageCloseButton.appendChild(span1)

    let span2 = document.createElement("span")
    overlayMessageCloseButton.appendChild(span2)

    overlayMessageCloseButton.addEventListener("click", function () {
      overlayMessageContainer.remove()
    }.bind(this))

    let overlayTitle = document.createElement("h3")
    overlayTitle.setAttribute("id", "overlayTitle")
    overlayTitle.setAttribute("class", "overlayTitle")
    overlayTitle.innerHTML = title
    overlayMessageContainer.appendChild(overlayTitle)

    let overlayMessage = document.createElement("p")
    overlayMessage.setAttribute("id", "overlayMessage")
    overlayMessage.setAttribute("class", "overlayMessage")
    overlayMessage.innerHTML = message
    overlayMessageContainer.appendChild(overlayMessage)
  }

  removeOverlayMessage() {
    let overlayMessageContainer = document.getElementById("overlayMessageContainer")
    overlayMessageContainer.remove()
  }
  displayObjectName() {
    let objectNameContainer = document.getElementById("objectNameContainer")
    objectNameContainer.classList.toggle("nonVisible")
  }
  changeObjectName(name) {
    let objectName = document.getElementById("objectName")
    objectName.innerHTML = name
  }
  removeObjectName() {
    let objectNameContainer = document.getElementById("objectNameContainer")
    objectNameContainer.classList.toggle("nonVisible")
  }
  setImportanceInterface() {
    let sliderContainer = document.getElementById("sliderContainer")
    sliderContainer.classList.toggle("nonVisible")
  }
  removeImportanceInterface() {
    this.sliderContainer = document.getElementById("sliderContainer")
    this.sliderContainer.classList.toggle("nonVisible")
  }
  setYourStoryInput() {
    this.yourStoryIsToggled = false
    let controlContainer = document.getElementById("controlContainer")
    this.yourStoryInput = document.createElement("textarea")
    this.yourStoryInput.setAttribute("id", "yourStoryInput")
    this.yourStoryInput.setAttribute("type", "text");
    this.yourStoryInput.setAttribute("spellcheck", "false");
    this.yourStoryInput.addEventListener("click", this.yourStoryInputOnClick.bind(this))
    this.yourStoryInput.setAttribute("onfocus", "value=''");
    this.yourStoryInput.innerHTML = "type your testimony here..."
    controlContainer.appendChild(this.yourStoryInput)
  }
  yourStoryInputOnClick() {
    if (!this.yourStoryIsToggled) {
      this.toggleCreateTestimonyCanvas()
      this.toggleYourStoryInput()
      this.yourStoryIsToggled = true;
    }
  }
  toggleYourStoryInput() {
    this.yourStoryInput.classList.toggle("bigStoryInput");
  }
  removeYourStoryInput() {
    this.yourStoryInput = document.getElementById("yourStoryInput")
    this.yourStoryInput.remove()
  }
  setYourInfos() {
    let yourInfosContainer = document.getElementById("yourInfosContainer")
    yourInfosContainer.classList.toggle("nonVisible")
  }

  removeYourInfos() {
    let yourInfosContainer = document.getElementById("yourInfosContainer")
    yourInfosContainer.classList.toggle("nonVisible")
  }
  removeCanvas() {
    let canvasContainer = document.getElementById("canvasContainer")
    canvasContainer.remove()
  }

  toggleTestimonyOverlayContainer() {
    let testimonyOverlayContainer = document.getElementById("testimonyOverlayContainer")
    testimonyOverlayContainer.classList.toggle("nonVisible")

    let backgroundTestimonyOverlay = document.getElementById("backgroundTestimonyOverlay")
    backgroundTestimonyOverlay.classList.toggle("nonVisible")
  }

  toggleMemorialCanvas() {
    let memorialCanvas = document.getElementById("memorialCanvas")
    memorialCanvas.classList.toggle("nonVisible")
  }

  toggleCreateTestimonyCanvas() {
    let createTestimonyCanvas = document.getElementById("createTestimonyCanvas")
    createTestimonyCanvas.classList.toggle("nonVisible")
  }

  toggleArchiveCanvas() {
    let archiveCanvas = document.getElementById("archiveCanvas")
    archiveCanvas.classList.toggle("nonVisible")
  }

  setButtonContainer() {
    let buttonContainer = document.getElementById("buttonContainer")
    buttonContainer.classList.toggle("nonVisible")
  }
  changeContinueButton(text) {
    let continueButton = document.getElementById('continueButton')
    continueButton.innerHTML = text
  }
  toggleContinueButton() {
    let continueButton = document.getElementById("continueButton")
    continueButton.classList.toggle("nonVisible");
  }
  removeDownButton() {
    this.downButton = document.getElementById('downButton')
    this.downButton.remove()
  }
  setBackButton() {
    this.backButton = document.createElement("button");
    this.backButton.setAttribute("id", "backButton");
    document.body.appendChild(this.backButton);
  }
  changeBackButton(text) {
    this.backButton = document.getElementById('backButton')
    this.backButton.innerHTML = text
  }
  toggleBackButton() {
    let backButton = document.getElementById("backButton")
    backButton.classList.toggle("nonVisible")
  }
  removeBackButton() {
    this.backButton = document.getElementById('backButton')
    this.backButton.remove()
  }
  setCaptureElements() {

    this.mainCam = document.createElement("main")
    this.mainCam.setAttribute("id", "camera");
    this.mainCam.classList.toggle("cameraOff");

    this.camSensor = document.createElement("canvas");
    this.camSensor.setAttribute("id", "camera--sensor");

    this.camView = document.createElement("video")
    this.camView.setAttribute("id", "camera--view");
    this.camView.setAttribute("autoplay", "");
    this.camView.setAttribute("playsinline", "");

    this.camOutput = document.createElement("img");
    this.camOutput.setAttribute("id", "camera--output");

    this.camOutput2 = document.createElement("img");
    this.camOutput2.setAttribute("id", "camera--output2");

    this.mainCam.appendChild(this.camSensor);
    this.mainCam.appendChild(this.camView);
    this.mainCam.appendChild(this.camOutput);
    this.mainCam.appendChild(this.camOutput2);

    document.body.appendChild(this.mainCam);
  }
  setVideo() {
    const constraints = {
      video: {
        facingMode: "environment",
      },
      audio: false
    };
    this.camView.width = 512;
    this.camView.height = 512;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia(constraints).catch(function (err) {
        console.log(err.name + ": " + err.message);
      }).then((function (stream) {
        this.camView.srcObject = stream;
      }).bind(this))
    }
  }
  toogleCameraView() {
    this.mainCam = document.getElementById("camera")
    this.mainCam.classList.toggle("cameraOff");
  }
  displayVideoWarning() {
    this.videoWarning = document.createElement("div")
    this.videoWarning.setAttribute("id", "videoWarning")
    this.videoWarning.innerHTML = "Please allow video in order to continue"
  }
  removeAllCameraElements() {
    let mainCam = document.getElementById("camera")
    mainCam.remove()
  }
  capturePhotoAsTexture() {
    this.camView = document.getElementById('camera--view')
    this.camSensor = document.getElementById('camera--sensor')
    this.camOutput = document.getElementById('camera--output')
    this.camSensor.width = this.camView.videoWidth;
    this.camSensor.height = this.camView.videoHeight;
    this.camOutput.width = this.camView.videoWidth;
    this.camOutput.height = this.camView.videoHeight;
    this.ctx = this.camSensor.getContext("2d");
    this.ctx.drawImage(this.camView, 0, 0);
    this.camOutput.src = this.camSensor.toDataURL("image/png");
    this.camOutput.setAttribute("style", "opacity:0;")
    return this.camOutput.src
  }
  capturePhotoAsView() {
    this.camView = document.getElementById('camera--view')
    this.camSensor = document.getElementById('camera--sensor')
    this.camOutput2 = document.getElementById('camera--output2')
    this.camSensor.width = this.camView.videoWidth;
    this.camSensor.height = this.camView.videoHeight;
    this.camOutput2.width = this.camView.videoWidth;
    this.camOutput2.height = this.camView.videoHeight;
    this.ctx = this.camSensor.getContext("2d");
    this.ctx.drawImage(this.camView, 0, 0);
    this.camOutput2.src = this.camSensor.toDataURL("image/png");
    this.camOutput2.setAttribute("style", "opacity:0;")
    return this.camOutput2.src
  }
  capturePhotoAsArchive(context) {
    this.camOutput3 = document.createElement("img");
    this.camOutput3.setAttribute("id", "camera--output3");

    let createTestimonyContainer = document.getElementById("createTestimonyContainer")
    createTestimonyContainer.appendChild(this.camOutput3);

    let createTestimonyCanvas = document.getElementById("createTestimonyCanvas")

    this.camOutput3.width = createTestimonyCanvas.width
    this.camOutput3.height = createTestimonyCanvas.height

    this.camOutput3.src = context.toDataURL("image/png");
    this.camOutput3.setAttribute("style", "opacity:0;")
    return this.camOutput3.src
  }

  setEmailInput() {
    let controlContainer = document.getElementById("controlContainer")
    this.emailContainer = document.createElement("div")
    this.emailContainer.setAttribute("id", "emailContainer")
    controlContainer.appendChild(this.emailContainer)

    this.emailTitle = document.createElement("div")
    this.emailTitle.setAttribute("id", "emailTitle")
    this.emailTitle.innerHTML = "email"
    this.emailContainer.appendChild(this.emailTitle)

    this.emailInput = document.createElement("input")
    this.emailInput.setAttribute("id", "emailInput")
    this.emailInput.setAttribute("type", "email");
    this.emailInput.setAttribute("placeholder", "Enter your email");
    this.emailInput.setAttribute("pattern", "/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/");
    this.emailInput.setAttribute("validate", "required:true");
    this.emailInput.setAttribute("value", "youremail@example.com");
    this.emailInput.setAttribute("onfocus", "this.value=''");
    this.emailContainer.appendChild(this.emailInput)
  }
  removeEmail() {
    this.emailContainer.remove()
  }
  displayEmailConfirmation() {
    let emailConfirmationOverlay = document.createElement("div")
    emailConfirmationOverlay.setAttribute("id", "emailConfirmationOverlay")
    document.body.appendChild(emailConfirmationOverlay)

    let emailConfirmationContainer = document.createElement("div")
    emailConfirmationContainer.setAttribute("id", "emailConfirmationContainer")
    document.body.appendChild(emailConfirmationContainer)

    let emailCloseButton = document.createElement("div")
    emailCloseButton.setAttribute("id", "emailCloseButton")
    emailConfirmationContainer.appendChild(emailCloseButton)

    let span1 = document.createElement("span")
    emailCloseButton.appendChild(span1)

    let span2 = document.createElement("span")
    emailCloseButton.appendChild(span2)

    emailCloseButton.addEventListener("click", function () {
      emailConfirmationOverlay.remove()
      emailConfirmationContainer.remove()
    }.bind(this))

    let emailConfirmationText = document.createElement("p")
    emailConfirmationText.setAttribute("id", "emailConfirmationText")
    emailConfirmationContainer.appendChild(emailConfirmationText)
    emailConfirmationText.innerHTML = "Your email adress has been sent successfully!"

  }
  removeEmailConfirmation() {
    this.emailConfirmationContainer = document.getElementById("emailConfirmationContainer")
    this.emailConfirmationContainer.remove()
  }
  displayOverlayTestimony(testimony, state) {
    this.testimonyValue = testimony
    this.testimonyIsSmall = true

    let unix_timestamp = this.testimonyValue.date
    var date = new Date(unix_timestamp * 1000);
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()
    var formattedTime = day + "/8/2020"

    let dateOfTestimony = document.getElementById("dateOfTestimony")

    dateOfTestimony.innerHTML = formattedTime

    let testimonyText = document.getElementById("paragraphOfTestimonyOverlay")

    if (this.testimonyValue.roomMates === "0") {
      testimonyText.innerHTML = "I am " + this.testimonyValue.name + " from " + this.testimonyValue.place + ". I lived my lockdown alone." + this.testimonyValue.text
    } else {
      testimonyText.innerHTML = "I am " + this.testimonyValue.name + " from " + this.testimonyValue.place + ". I lived my lockdown with " + this.testimonyValue.roomMates + " people. " + this.testimonyValue.text
    }
  }
  toggleTestimony() {}

  removeTestimony() {
    this.testimonyContainer = document.getElementById("testimonyContainer")
    this.testimonyContainer.remove()
  }

  setCopyUrlButton(url) {
    let buttonOverlayContainer = document.getElementById("buttonOverlayContainer")

    let urlButton = document.createElement("a")
    urlButton.setAttribute("id", "urlButton")
    urlButton.setAttribute("class", "fa fa-link")
    urlButton.setAttribute("target", "_blank")
    urlButton.setAttribute("rel", "noopener")
    urlButton.setAttribute("aria-label", "")

    buttonOverlayContainer.appendChild(urlButton)

    urlButton.addEventListener("click", function () {
      this.copyToClipboard(url)
    }.bind(this))
  }
  setCreateTestimonyCopyUrlButton(url) {
    let buttonContainer = document.getElementById("buttonContainer")

    let urlButton = document.createElement("button")
    urlButton.setAttribute("id", "urlButton")
    urlButton.setAttribute("class", "fa fa-link")
    urlButton.setAttribute("target", "_blank")
    urlButton.setAttribute("rel", "noopener")
    urlButton.setAttribute("aria-label", "")

    urlButton.addEventListener("click", function () {
      console.log("yoloo")
      this.createTestimonyCopyToClipboard(url)
    }.bind(this))

    buttonContainer.appendChild(urlButton)

  }

  removeCopyUrlButton() {
    let urlButton = document.getElementById("urlButton")
    urlButton.remove()
  }
  addCopyUrlText() {
    let paragraphOfTestimonyOverlay = document.getElementById("paragraphOfTestimonyOverlay")
    paragraphOfTestimonyOverlay.innerHTML = "Keep a track of this testimony by copying the url link or share it with your friends."
  }
  removeUrlInstruction() {
    this.urlInstruction.remove()
  }
  copyToClipboard(str) {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    let dateOfTestimony = document.getElementById("dateOfTestimony")
    let paragraphOfTestimonyOverlay = document.getElementById("paragraphOfTestimonyOverlay")
    dateOfTestimony.innerHTML = "Url Successfully copied!"
    paragraphOfTestimonyOverlay.innerHTML = "The url of this testimony has been copied in the clipboard. Feel free to share it with your friend or to save it to consut it later."
  }
  createTestimonyCopyToClipboard(str) {
    console.log("yooo")
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    let popupOverlay = document.createElement("div")
    popupOverlay.setAttribute("id", "popupOverlay")
    document.body.appendChild(popupOverlay)

    let popupContainer = document.createElement("div")
    popupContainer.setAttribute("id", "popupContainer")
    document.body.appendChild(popupContainer)

    let popupTitle = document.createElement("h3")
    popupTitle.setAttribute("id", "popupTitle")
    popupContainer.appendChild(popupTitle)
    popupTitle.innerHTML = "Url Successfully copied."

    let popupCloseButton = document.createElement("div")
    popupCloseButton.setAttribute("id", "popupCloseButton")
    popupContainer.appendChild(popupCloseButton)

    let span1 = document.createElement("span")
    popupCloseButton.appendChild(span1)

    let span2 = document.createElement("span")
    popupCloseButton.appendChild(span2)

    popupCloseButton.addEventListener("click", function () {
      popupOverlay.remove()
      popupContainer.remove()
    }.bind(this))

    let popupText = document.createElement("p")
    popupText.setAttribute("id", "popupText")
    popupContainer.appendChild(popupText)
    popupText.innerHTML = "The url of this testimony has been copied in the clipboard. Feel free to share it with your friend or to save it to consut it later."
  }
  removeDateOfTestimony() {
    let dateOfTestimony = document.getElementById("dateOfTestimony")
    dateOfTestimony.innerHTML = "<br>"
  }
  ///////////////////////////////////////////////////////////////MEMORIAL
  setMemorialMenu() {
    let memorialImageContainer = document.createElement("div")
    memorialImageContainer.setAttribute("id", "memorialImageContainer")
    document.body.appendChild(memorialImageContainer)

    let memorialTitle = document.createElement("p")
    memorialTitle.setAttribute("id", "memorialTitle")
    memorialTitle.innerHTML = "Choose memorial"

    memorialImageContainer.appendChild(memorialTitle)

    let memorialImageSrc = require('../img/memorial1.png')
    let memorialImage = document.createElement('img')
    memorialImage.setAttribute("class", "memorialImage")
    memorialImage.setAttribute("id", "memorialImage1")
    memorialImage.src = memorialImageSrc

    memorialImageContainer.appendChild(memorialImage)

    memorialImageSrc = require('../img/memorial2.png')
    memorialImage = document.createElement('img')
    memorialImage.setAttribute("class", "memorialImage")
    memorialImage.setAttribute("id", "memorialImage2")
    memorialImage.src = memorialImageSrc

    memorialImageContainer.appendChild(memorialImage)

    memorialImageSrc = require('../img/memorial3.png')
    memorialImage = document.createElement('img')
    memorialImage.setAttribute("class", "memorialImage")
    memorialImage.setAttribute("id", "memorialImage3")
    memorialImage.src = memorialImageSrc

    memorialImageContainer.appendChild(memorialImage)

    memorialImageSrc = require('../img/memorial4.png')
    memorialImage = document.createElement('img')
    memorialImage.setAttribute("class", "memorialImage")
    memorialImage.setAttribute("id", "memorialImage4")
    memorialImage.src = memorialImageSrc

    memorialImageContainer.appendChild(memorialImage)

    return memorialImageContainer
  }
  removeMemorialMenu() {
    let memorialImageContainer = document.getElementById("memorialImageContainer")
    memorialImageContainer.remove()
  }
  setQuitButton() {
    this.leftQuitButton = document.createElement("span")
    this.rightQuitButton = document.createElement("span")

    this.quitButton = document.createElement("div")
    this.quitButton.setAttribute("id", "quitButton")

    this.quitButton.appendChild(this.leftQuitButton)
    this.quitButton.appendChild(this.rightQuitButton)

    document.body.appendChild(this.quitButton)

    return this.quitButton
  }
  removeQuitButton() {
    this.quitButton.remove()
  }
  displayPreventDesktop() {
    let preventOverlay = document.createElement("div")
    preventOverlay.setAttribute("id", "preventOverlay")
    document.body.appendChild(preventOverlay)

    let preventContainer = document.createElement("div")
    preventContainer.setAttribute("id", "preventContainer")
    document.body.appendChild(preventContainer)

    let preventQRCode = document.createElement("object")
    preventQRCode.setAttribute("id", "preventQRCode")
    preventQRCode.setAttribute("type", "image/svg+xml")
    preventQRCode.setAttribute("data", require("../img/qr2.svg"))
    preventContainer.appendChild(preventQRCode)

    let preventCloseButton = document.createElement("div")
    preventCloseButton.setAttribute("id", "preventCloseButton")
    preventContainer.appendChild(preventCloseButton)

    let span1 = document.createElement("span")
    preventCloseButton.appendChild(span1)

    let span2 = document.createElement("span")
    preventCloseButton.appendChild(span2)

    preventCloseButton.addEventListener("click", function () {
      preventOverlay.remove()
      preventContainer.remove()
    }.bind(this))

    let preventText = document.createElement("p")
    preventText.setAttribute("id", "preventText")
    preventText.innerHTML = "In order to testify about your lockdown situation, please visit lockdown.memorial from your smartphone."
    preventContainer.appendChild(preventText)

  }

  removePreventDesktop() {
    let preventContainer = document.getElementById("preventContainer")
    preventContainer.remove()
    let preventOverlay = document.getElementById("preventOverlay")
    preventOverlay.remove()
  }
  setAboutPage() {
    let aboutTitle = document.createElement("h2")
    aboutTitle.setAttribute("id", "aboutTitle")
    aboutTitle.innerHTML = "ABOUT"
    document.body.appendChild(aboutTitle)

    let aboutParagraphContainer = document.createElement("div")
    aboutParagraphContainer.setAttribute("id", "aboutParagraphContainer")
    document.body.appendChild(aboutParagraphContainer)

    let firstParagraph = document.createElement("p")
    firstParagraph.setAttribute("id", "firstParagraph")
    firstParagraph.setAttribute("class", "aboutParagraph")
    firstParagraph.innerHTML = "In 2020, with the emergence of Covid-19, everyone isolates themselves. For many it is an opportunity to reflect on their situation. <br> <br> Lockdown Memorial Archive allows everyone to share their testimony about their confinement and to become aware of the feelings of others. Participants are invited to personalize an emblematic object and then to associate a testimony about their experience to it. The whole will then constitute an archive made up of several immersive memorials where one can relive this situation of isolation in a different way. <br> <br> This web plateforme was made in 2020 by Pablo Bellon for his diploma project at ECAL (Ecole cantonale de Lausanne), in the Media and Interaction Design bachelor section. <br> <br> Project by ECAL/Pablo Bellon <br> Project Tutors : Alain Bellet, Gael Hugo, Christophe Guignard, Laura Perrenoud & Pauline Saglio <br> Project Assistants : Callum Ross, Pierry Jaquillard,  Sebastien Matos & Pietro Alberti <br> ecal.ch + ECAL - Bachelor Media&Interaction Design <br> <br> Special thanks to Roman Karrer"
    aboutParagraphContainer.appendChild(firstParagraph)

    let secondParagraph = document.createElement("p")
    secondParagraph.setAttribute("id", "secondParagraph")
    secondParagraph.setAttribute("class", "aboutParagraph")
    secondParagraph.innerHTML = ""
    aboutParagraphContainer.appendChild(secondParagraph)
  }
  removeAboutPage() {
    let aboutTitle = document.getElementById("aboutTitle")
    let aboutParagraphContainer = document.getElementById("aboutParagraphContainer")

    aboutTitle.remove()
    aboutParagraphContainer.remove()
  }
  displayMemorialInstructions(message) {
    let memorialInstructions = document.createElement("p")
    memorialInstructions.setAttribute("id", "memorialInstructions")
    memorialInstructions.setAttribute("class", "memorialInstructions")
    memorialInstructions.innerHTML = message
    document.body.appendChild(memorialInstructions)
    setTimeout(function () {
      memorialInstructions.setAttribute("class", "memorialInstructionsIn")
      memorialInstructions.classList.toggle("memorialInstructions")
    }.bind(this), 100)

  }
  removeMemorialInstructions() {
    let memorialInstructions = document.getElementById("memorialInstructions")
    memorialInstructions.setAttribute("class", "memorialInstructionsOut")
    setTimeout(function () {
      memorialInstructions.remove()
    }.bind(this), 2000)
  }
  changeCanvasColor(color) {
    let canvas = document.getElementById("memorialCanvas")
    if (color == "gradient") {
      canvas.setAttribute("style", "background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(5,108,242,1) 100%)")
    } else {
      canvas.setAttribute("style", "background-color: #056bf0)")
    }
  }
  changeBackgroundColor(color) {
    document.body.setAttribute("style", "background-color:" + color)
  }

  setUrlButton() {
    this.urlButton = document.createElement("button")
    this.urlButton.setAttribute("id", "urlButton")
    document.body.appendChild(this.urlButton)
    this.urlButton.innerHTML = "Link"

  }

  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  mobileCheck() {
    let check = false;
    (function (a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    console.log("isMobile : " + check)
    return check;
  }

  setupTestimonyOverlayContainer() {
    let backgroundTestimonyOverlay = document.createElement("div")
    backgroundTestimonyOverlay.setAttribute("class", "nonVisible backgroundTestimonyOverlay")
    backgroundTestimonyOverlay.setAttribute("id", "backgroundTestimonyOverlay")
    document.body.appendChild(backgroundTestimonyOverlay)


    let testimonyOverlayContainer = document.createElement("div")
    testimonyOverlayContainer.setAttribute("class", "nonVisible testimonyOverlayContainer")
    testimonyOverlayContainer.setAttribute("id", "testimonyOverlayContainer")
    document.body.appendChild(testimonyOverlayContainer)

    let testimonyOverlayCanvas = document.createElement("canvas")
    testimonyOverlayCanvas.setAttribute("id", "testimonyOverlayCanvas")
    testimonyOverlayContainer.appendChild(testimonyOverlayCanvas)

    let testimonyOverlayInterface = document.createElement("div")
    testimonyOverlayInterface.setAttribute("id", "testimonyOverlayInterface")
    testimonyOverlayContainer.appendChild(testimonyOverlayInterface)

    let testimonyOverlayCloseButton = document.createElement("div")
    testimonyOverlayCloseButton.setAttribute("id", "testimonyOverlayCloseButton")
    testimonyOverlayInterface.appendChild(testimonyOverlayCloseButton)

    let span1 = document.createElement("span")
    testimonyOverlayCloseButton.appendChild(span1)

    let span2 = document.createElement("span")
    testimonyOverlayCloseButton.appendChild(span2)

    let textTestimonyOverlayContainer = document.createElement("div")
    textTestimonyOverlayContainer.setAttribute("id", "textTestimonyOverlayContainer")
    testimonyOverlayInterface.appendChild(textTestimonyOverlayContainer)

    let dateOfTestimony = document.createElement("p")
    dateOfTestimony.setAttribute("id", "dateOfTestimony")
    textTestimonyOverlayContainer.appendChild(dateOfTestimony)

    let paragraphOfTestimonyOverlay = document.createElement("p")
    paragraphOfTestimonyOverlay.setAttribute("id", "paragraphOfTestimonyOverlay")
    textTestimonyOverlayContainer.appendChild(paragraphOfTestimonyOverlay)

    // let backgroundOfTestimonyOverlayParagraph = document.createElement("div")
    // backgroundOfTestimonyOverlayParagraph.setAttribute("id", "backgroundOfTestimonyOverlayParagraph")
    // textTestimonyOverlayContainer.appendChild(backgroundOfTestimonyOverlayParagraph)

    let buttonOverlayContainer = document.createElement("div")
    buttonOverlayContainer.setAttribute("id", "buttonOverlayContainer")
    testimonyOverlayInterface.appendChild(buttonOverlayContainer)

    let backButtonOverlay = document.createElement("button")
    backButtonOverlay.setAttribute("id", "backButtonOverlay")
    buttonOverlayContainer.appendChild(backButtonOverlay)

    let mainButtonOverlay = document.createElement("button")
    mainButtonOverlay.setAttribute("id", "mainButtonOverlay")
    buttonOverlayContainer.appendChild(mainButtonOverlay)

  }
  setupMemorialCanvas() {
    let memorialCanvas = document.createElement("canvas")
    memorialCanvas.setAttribute("id", "memorialCanvas")
    memorialCanvas.setAttribute("class", "nonVisible memorialCanvas")
    document.body.appendChild(memorialCanvas)
  }
  removeMemorialCanvas() {
    let memorialCanvas = document.getElementById("memorialCanvas")
    memorialCanvas.remove()
  }
  setupCreateTestimony() {
    let createTestimonyContainer = document.createElement("div")
    createTestimonyContainer.setAttribute("id", "createTestimonyContainer")
    createTestimonyContainer.setAttribute("class", "nonVisible createTestimonyContainer")
    document.body.appendChild(createTestimonyContainer)

    let instructionContainer = document.createElement("div")
    instructionContainer.setAttribute("id", "instructionContainer")
    instructionContainer.setAttribute("class", "instructionContainer")
    createTestimonyContainer.appendChild(instructionContainer)

    let indexOfInstruction = document.createElement("p")
    indexOfInstruction.setAttribute("id", "indexOfInstruction")
    indexOfInstruction.setAttribute("class", "indexOfInstruction")
    instructionContainer.appendChild(indexOfInstruction)

    let textInstruction = document.createElement("p")
    textInstruction.setAttribute("id", "textInstruction")
    textInstruction.setAttribute("class", "textInstruction")
    instructionContainer.appendChild(textInstruction)

    let titleOfInstruction = document.createElement("p")
    titleOfInstruction.setAttribute("id", "titleOfInstruction")
    titleOfInstruction.setAttribute("class", "titleOfInstruction")
    textInstruction.appendChild(titleOfInstruction)

    let paragraphOfInstruction = document.createElement("p")
    paragraphOfInstruction.setAttribute("id", "paragraphOfInstruction")
    paragraphOfInstruction.setAttribute("class", "paragraphOfInstruction")
    textInstruction.appendChild(paragraphOfInstruction)

    let createTestimonyCanvas = document.createElement("canvas")
    createTestimonyCanvas.setAttribute("id", "createTestimonyCanvas")
    createTestimonyCanvas.setAttribute("class", "nonVisible createTestimonyCanvas")
    createTestimonyContainer.appendChild(createTestimonyCanvas)

    let yourInfosContainer = document.createElement("div")
    yourInfosContainer.setAttribute("id", "yourInfosContainer")
    yourInfosContainer.setAttribute("class", "nonVisible yourInfosContainer")
    createTestimonyContainer.appendChild(yourInfosContainer)

    let yourNamesInfosInstruction = document.createElement("div")
    yourNamesInfosInstruction.setAttribute("class", "yourInfosInstruction")
    yourNamesInfosInstruction.innerHTML = "Name"
    yourInfosContainer.appendChild(yourNamesInfosInstruction)

    let yourNamesInfosInput = document.createElement("input")
    yourNamesInfosInput.setAttribute("class", "yourInfosInput")
    yourNamesInfosInput.setAttribute("type", "text")
    yourNamesInfosInput.setAttribute("value", "someone");
    yourNamesInfosInput.setAttribute("onfocus", "value=''");
    yourInfosContainer.appendChild(yourNamesInfosInput)

    let yourPlaceInfosInstruction = document.createElement("div")
    yourPlaceInfosInstruction.setAttribute("class", "yourInfosInstruction")
    yourPlaceInfosInstruction.innerHTML = "Place"
    yourInfosContainer.appendChild(yourPlaceInfosInstruction)

    let yourPlaceInfosInput = document.createElement("input")
    yourPlaceInfosInput.setAttribute("class", "yourInfosInput")
    yourPlaceInfosInput.setAttribute("type", "text")
    yourPlaceInfosInput.setAttribute("value", "somewhere");
    yourPlaceInfosInput.setAttribute("onfocus", "value=''");
    yourInfosContainer.appendChild(yourPlaceInfosInput)

    let yourRoomMateInfosContainer = document.createElement("div")
    yourRoomMateInfosContainer.setAttribute("class", "yourRoomMateInfosContainer")
    yourInfosContainer.appendChild(yourRoomMateInfosContainer)

    let yourRoomMateInfosInstruction = document.createElement("div")
    yourRoomMateInfosInstruction.setAttribute("id", "yourRoomMateInfosInstruction")
    yourRoomMateInfosInstruction.innerHTML = "Number of people you stayed with"
    yourRoomMateInfosContainer.appendChild(yourRoomMateInfosInstruction)

    let yourRoomMateInfosInput = document.createElement("input")
    yourRoomMateInfosInput.setAttribute("id", "yourRoomMateInfosInput")
    yourRoomMateInfosInput.setAttribute("type", "number")
    yourRoomMateInfosInput.setAttribute("value", "none");
    yourRoomMateInfosInput.setAttribute("onfocus", "value=''");
    yourRoomMateInfosContainer.appendChild(yourRoomMateInfosInput)

    let controlContainer = document.createElement("div")
    controlContainer.setAttribute("id", "controlContainer")
    controlContainer.setAttribute("class", "nonVisible controlContainer")
    createTestimonyContainer.appendChild(controlContainer)

    let sliderContainer = document.createElement("div")
    sliderContainer.setAttribute("id", "sliderContainer")
    sliderContainer.setAttribute("class", "nonVisible sliderContainer")
    controlContainer.appendChild(sliderContainer)

    let sliderInfos = document.createElement("div")
    sliderInfos.setAttribute("id", "sliderInfos")
    sliderContainer.appendChild(sliderInfos)

    let lowSliderInfo = document.createElement("span")
    lowSliderInfo.innerHTML = "barely"
    sliderInfos.appendChild(lowSliderInfo)

    let highSliderInfo = document.createElement("span")
    highSliderInfo.innerHTML = "very"
    sliderInfos.appendChild(highSliderInfo)

    let slider = document.createElement("input")
    slider.setAttribute("id", "slider")
    slider.setAttribute("type", "range")
    slider.setAttribute("min", "1")
    slider.setAttribute("max", "100")
    slider.setAttribute("value", "50")
    sliderContainer.appendChild(slider)

    let objectNameContainer = document.createElement("div")
    objectNameContainer.setAttribute("id", "objectNameContainer")
    objectNameContainer.setAttribute("class", "nonVisible objectNameContainer")
    controlContainer.appendChild(objectNameContainer)

    let objectName = document.createElement("p")
    objectName.setAttribute("id", "objectName")
    objectName.setAttribute("class", "objectName")
    objectNameContainer.appendChild(objectName)

    let buttonContainer = document.createElement("div")
    buttonContainer.setAttribute("id", "buttonContainer")
    buttonContainer.setAttribute("class", "nonVisible buttonContainer")
    createTestimonyContainer.appendChild(buttonContainer)

    let backButton = document.createElement("button")
    backButton.setAttribute("id", "backButton")
    backButton.setAttribute("class", "nonVisible backButton")
    buttonContainer.appendChild(backButton)

    let continueButton = document.createElement("button")
    continueButton.setAttribute("id", "continueButton")
    continueButton.setAttribute("class", "nonVisible continueButton")
    buttonContainer.appendChild(continueButton)

  }
  toggleCreateTestimonyContainer() {
    let createTestimonyContainer = document.getElementById("createTestimonyContainer")
    createTestimonyContainer.classList.toggle("nonVisible")
  }
  toggleButtonContainer() {
    let buttonContainer = document.getElementById("buttonContainer")
    buttonContainer.classList.toggle("nonVisible")
  }
  toggleControlContainer() {
    let controlContainer = document.getElementById("controlContainer")
    controlContainer.classList.toggle("nonVisible")
  }
  setupArchiveCanvas() {
    let archiveCanvas = document.createElement("canvas")
    archiveCanvas.setAttribute("id", "archiveCanvas")
    archiveCanvas.setAttribute("class", "nonVisible archiveCanvas")
    document.body.appendChild(archiveCanvas)
  }
  changeMainButtonOverlay(text) {
    let mainButtonOverlay = document.getElementById("mainButtonOverlay")
    mainButtonOverlay.innerHTML = text
  }
  changeBackButtonOverlay(text) {
    let backButtonOverlay = document.getElementById("backButtonOverlay")
    backButtonOverlay.innerHTML = text
  }
  toggleTestimonyOverlayButton() {
    let mainButtonOverlay = document.getElementById("mainButtonOverlay")
    let backButtonOverlay = document.getElementById("backButtonOverlay")
    mainButtonOverlay.classList.toggle("nonVisible")
    backButtonOverlay.classList.toggle("nonVisible")
  }
  toggleMainButtonOverlay() {
    let mainButtonOverlay = document.getElementById("mainButtonOverlay")
    mainButtonOverlay.classList.toggle("nonVisible")
  }
  toggleBackButtonOverlay() {
    let backButtonOverlay = document.getElementById("backButtonOverlay")
    backButtonOverlay.classList.toggle("nonVisible")
  }
  displayTestimony(testimony, state) {
    this.testimonyValue = testimony
    this.testimonyIsSmall = true

    this.testimonyContainer = document.createElement("div")
    this.testimonyContainer.setAttribute("id", "testimonyContainer")


    let overlayTestimonyText = document.createElement("div")
    overlayTestimonyText.setAttribute("id", "overlayTestimonyText")
    this.testimonyContainer.appendChild(overlayTestimonyText)


    let controlContainer = document.getElementById("controlContainer")
    controlContainer.appendChild(this.testimonyContainer)

    this.testimonyInfosContainer = document.createElement("div")
    this.testimonyInfosContainer.setAttribute("id", "testimonyInfosContainer")
    this.testimonyContainer.appendChild(this.testimonyInfosContainer)

    this.testimonyText = document.createElement("p")
    this.testimonyText.setAttribute("id", "testimonyText")
    this.testimonyContainer.appendChild(this.testimonyText)

    if (this.testimonyValue.roomMates === "0") {
      this.testimonyText.innerHTML = "I am " + this.testimonyValue.name + " from " + this.testimonyValue.place + ". I lived my lockdown alone." + this.testimonyValue.text
    } else {
      this.testimonyText.innerHTML = "I am " + this.testimonyValue.name + " from " + this.testimonyValue.place + ". I lived my lockdown with " + this.testimonyValue.roomMates + " people. " + this.testimonyValue.text
    }
  }
  removeCameraOutput() {
    let cameraOutput = document.getElementById("camera--output3")
    cameraOutput.remove()
  }

}