import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';
import '../config.js'
export class FirebaseManager {
  constructor() {
    this.storage = firebase.storage();
    this.database = firebase.database();
  }
  increaseCounter() {
    let pathCounter = 'COUNTER'

    this.postCounterRef = this.database.ref(pathCounter)
    this.postCounterRef.once('value', function (snapshot) {
      this.counterValue = parseInt(snapshot.val()) + 1
      if (snapshot.val() == null) {
        this.database.ref(pathCounter).set("1");
      } else {
        this.database.ref(pathCounter).set(this.counterValue);
      }
    }.bind(this));
  }
  async getCounter() {
    let pathCounter = 'COUNTER'

    this.postCounterRef = this.database.ref(pathCounter)
    await this.postCounterRef.once('value', function (snapshot) {
      this.counterValue = snapshot.val()
    }.bind(this))
    return this.counterValue
  }

  send(path, _data) {
    this.database.ref(path).set(_data);
  }
  //Similar to send, but generate Id for each data and Add TIMESTAMP to date
  sendWithUniqueID(path) {
    this.newPostRef = this.database.ref(path).push({
      date: firebase.database.ServerValue.TIMESTAMP
    })
    this.postID = this.newPostRef.key;
    return this.postID
  }
  sendImageTextureToFirebase(path, _data) {
    this.ref = this.storage.ref();
    this.file = _data;
    // var name = 'TEXTURES/1/Image/texture.png';
    this.metadata = {
      contentType: this.file.type
    }
    this.task = this.ref.child(path).putString(this.file, 'data_url');
    this.task.then(snapshot => snapshot.ref.getDownloadURL())
      .then(url => {})
  }
  async getIDfromIndex(path) {
    let textureKeys = await this.browseID(path)
    let randomIndex = this.randomIntFromInterval(0, textureKeys.length - 1)
    return textureKeys[randomIndex]
  }

  async getAllIDfromPath(path) {
    let textureKeys = await this.browseIDfromPathIfAny(path)
    if (textureKeys) {
      return textureKeys
    }
  }
  browseIDfromPathIfAny(path) {
    return new Promise(resolve => {
      this.database.ref(path).orderByKey().on("value", function (snapshot) {
        if (snapshot.val()) {
          let keys = Object.keys(snapshot.val())
          resolve(keys)
        } else {
          resolve()
        }
      });
    })
  }

  browseID(path) {
    return new Promise(resolve => {
      this.database.ref(path).orderByKey().on("value", function (snapshot) {
        let keys = Object.keys(snapshot.val())
        resolve(keys)
      });
    })
  }

  async getImageURL(path, ID) {
    var textureRef = this.storage.ref(path + '/' + ID + '/TEXTURE/img.png');

    return new Promise(resolve => {
      textureRef.getDownloadURL().then(function (url) {
        let imageTexture = url;
        resolve(imageTexture)
      }).catch(function (error) {
        console.log(error)
      });
    })
  }
  async getViewImageURL(ID) {
    var textureRef = this.storage.ref('VIEW/' + ID + '/VIEW/img.png');
    return new Promise(resolve => {
      textureRef.getDownloadURL().then(function (url) {
        let imageTexture = url;
        resolve(imageTexture)
      }).catch(function (error) {
        console.log(error)
      });
    })
  }
  async getArchiveImageURL(model, ID) {
    var textureRef = this.storage.ref(model + '/' + ID + '/ARCHIVE/img.png');
    return new Promise(resolve => {
      textureRef.getDownloadURL().then(function (url) {
        let imageTexture = url;
        resolve(imageTexture)
      }).catch(function (error) {
        console.log(error)
      });
    })
  }
  getScaleValue(path, ID) {
    return new Promise(resolve => {
      this.database.ref(path + '/' + ID + "/IMPORTANCE").on('value', (snapshot) => {
        resolve(snapshot.val())
      })
    })
  }
  async getCameraObjectValue(path, ID) {
    // let path = this.getPathFromIndex(index)
    this.cameraValue = {}
    this.cameraValue.matrixWorldInverse = await Promise.resolve(this.getCameraValue(path, ID, "MatrixWorldInverse"));
    this.cameraValue.matrixWorld = await Promise.resolve(this.getCameraValue(path, ID, "MatrixWorld"));
    this.cameraValue.projectionMatrix = await Promise.resolve(this.getCameraValue(path, ID, "ProjectionMatrix"));
    this.cameraValue.position = await Promise.resolve(this.getCameraValue(path, ID, "Position"));
    return this.cameraValue
  }
  getCameraValue(path, ID, value) {
    return new Promise(resolve => {
      this.database.ref(path + '/' + ID + "/CAMERA/" + value).on('value', (snapshot) => {
        resolve(snapshot.val())
      })
    })
  }
  async getTestimony(path, id) {
    this.testimony = {}
    this.testimony.name = await Promise.resolve(this.getTestimonyValue(path, id, "NAME"))
    this.testimony.place = await Promise.resolve(this.getTestimonyValue(path, id, "PLACE"))
    this.testimony.roomMates = await Promise.resolve(this.getTestimonyValue(path, id, "ROOMMATES"))
    this.testimony.text = await Promise.resolve(this.getTestimonyValue(path, id, "TEXT"))
    this.testimony.date = await Promise.resolve(this.getTestimonyValue(path, id, "date"))
    return this.testimony
  }
  getTestimonyValue(path, id, value) {
    return new Promise(resolve => {
      this.database.ref(path + '/' + id + "/" + value).on('value', (snapshot) => {
        resolve(snapshot.val())
      })
    })
  }
  getPathFromIndex(index) {
    let path
    switch (index) {
      case 0:
        path = "ObjectType"
        break
      case 1:
        path = "ObjectType"
        break
      case 2:
        path = "ObjectType"
        break
      case 3:
        path = "ObjectType"
        break
      case 4:
        path = "ObjectType"
        break
      case 5:
        path = "ObjectType"
        break
      case 6:
        path = "ObjectType"
        break
    }
    return path
  }
  ////////////////////////////////////////////////////////////////// TOOLS
  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

}