import 'babel-polyfill'
import * as THREE from 'three'
import {
  FixedMaterial
} from '../shaders/FixedMaterial'
import {
  Object
} from '../Objects/object'
import {
  DomManager
} from '../domElements/domManager';
import {
  Preload
} from '../appModes/preload';
import {
  FirebaseManager
} from '../firebaseManager/firebaseManager'
import {
  OrbitControls
} from 'three/examples/jsm/controls/OrbitControls'
import {
  Memorial
} from './memorial'

export class Archive {
  constructor(parameters, isMobile) {
    this.parameters = parameters ? parameters : false
    this.scene = parameters.testimonyOverlayScene
    this.renderer = parameters.testimonyOverlayRenderer
    this.modelArray = parameters.modelArray ? parameters.modelArray : false
    this.domManager = new DomManager()
    this.firebaseManager = new FirebaseManager()
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.arrayOfObjectName = parameters.arrayOfModelName
    this.refModelArray = parameters.refModelArray
    this.counter = 0
    this.isMobile = isMobile
    this.numberOfTestimony = parameters.numberOfTestimony
    this.objectIsSelected = false;
  }
  /////////////////////////////////////////STEP 1
  async init() {
    this.domManager.setLoader()
    this.drawCanvas()
    this.imagesPath = await this.getAllImagesPath()
    await this.loadImages()
    this.drawDisplayedZone()
    await this.pushImageRefIntoDisplayedImages(0, 0)
    this.currentPosX = 0
    this.currentPosY = 0
    this.updateDisplayedImages(this.currentPosX, this.currentPosY)
    if (document.getElementById("loaderContainer")) {
      this.domManager.removeLoader()
    }
    this.renderMode = "archiveArray"

    this.currentPosX = 5
    this.currentPosY = 5
    for (let i = 0; i < 100; i++) {
      this.currentPosX *= 0.98
      this.currentPosY *= 0.98
      await this.timer(10)
    }
    return "chooseObjectInArchive"
  }
  async initWithID(url) {
    this.domManager.setLoader()
    this.imagesPath = await this.getAllImagesPath()
    await this.loadImages()
    this.drawCanvas()
    this.drawDisplayedZone()
    await this.pushImageRefIntoDisplayedImages(0, 0)
    this.currentPosX = 0
    this.currentPosY = 0
    this.updateDisplayedImages(this.currentPosX, this.currentPosY)

    this.currentPosX = 5
    this.currentPosY = 5
    for (let i = 0; i < 100; i++) {
      this.currentPosX *= 0.98
      this.currentPosY *= 0.98
      await this.timer(10)
    }

    this.model = url.model
    this.id = url.id

    this.indexOfObject = this.arrayOfObjectName.indexOf(url.model)

    this.modelArray[this.indexOfObject] = await Preload.loadModel(this.refModelArray[this.indexOfObject])

    await this.setupSelectedObject(this.model, this.id, this.indexOfObject)
    this.domManager.removeLoader()

    this.renderMode = "displayObject"

    return "chooseObjectInArchive"

  }
  async getAllImagesPath() {
    this.arrayOfImageRef = []
    this.arrayOfPath = []
    for (let i = 0; i < this.arrayOfObjectName.length; i++) {
      let model = this.arrayOfObjectName[i]
      let ID = await this.firebaseManager.getAllIDfromPath(model)
      if (ID) {
        for (let j = 0; j < ID.length; j++) {
          let imagePath = {
            model: "",
            id: "",
            position: {
              x: 0,
              y: 0,
            },
          }
          imagePath.model = model
          imagePath.id = ID[j]
          this.arrayOfPath.push(imagePath)
        }
      }
    }
    return this.arrayOfPath
  }
  async loadImages() {
    for (let i = 0; i < this.arrayOfPath.length; i++) {
      let image = new Image();
      image.src = await this.firebaseManager.getArchiveImageURL(this.arrayOfPath[i].model, this.arrayOfPath[i].id)
      let imageParameters = {
        image: image,
        id: this.arrayOfPath[i].id,
        model: this.arrayOfPath[i].model,
      }
      this.arrayOfImageRef[i] = imageParameters
    }
    this.arrayOfDisplayedImages = []
  }
  drawCanvas() {
    this.canvas = document.getElementById("archiveCanvas")
    this.canvas.width = window.innerWidth
    this.canvas.height = window.innerHeight
    this.ctx = this.canvas.getContext("2d");
  }
  drawDisplayedZone() {
    this.xZone = -320
    this.yZone = -240
    this.zoneWidth = window.innerWidth + 640
    this.zoneHeight = window.innerHeight + 480
    this.ctx.clearRect(0, 0, window.innerWidth, window.innerHeight)
  }
  async pushImageRefIntoDisplayedImages(x, y) {
    let size = await this.getSizeOfImages()
    const width = size.width
    const height = size.height

    let posX = x + this.xZone
    let posY = y + this.yZone
    this.xNumberOfDisplayedImages = Math.floor(this.zoneWidth / width)
    this.yNumberOfDisplayedImages = Math.floor(this.zoneHeight / height)
    let numberOfDisplayedImages = this.xNumberOfDisplayedImages * this.yNumberOfDisplayedImages

    for (let i = 0; i < numberOfDisplayedImages; i++) {
      if (i != 0 && i % this.xNumberOfDisplayedImages == 0) {
        posY += height
        posX = x + this.xZone
      } else if (i != 0 && i % this.xNumberOfDisplayedImages != 0) {
        posX += width
      }
      let randomIndex = this.randomIntFromInterval(0, this.arrayOfImageRef.length - 1)
      let imageParameters = {
        x: posX,
        y: posY,
        width: width,
        height: height,
        image: this.arrayOfImageRef[randomIndex].image,
        id: this.arrayOfImageRef[randomIndex].id,
        model: this.arrayOfImageRef[randomIndex].model,
      }
      this.arrayOfDisplayedImages.push(imageParameters)
    }
  }
  getSizeOfImages() {
    return new Promise((resolve) => {
      if (this.isMobile) {
        resolve({
          width: 200,
          height: 160,
        })
      } else {
        resolve({
          width: 240,
          height: 180,
        })
      }
    });
  }
  updateDisplayedImages(x, y) {
    for (let i = 0; i < this.arrayOfDisplayedImages.length; i++) {
      this.arrayOfDisplayedImages[i].x += x
      this.arrayOfDisplayedImages[i].y += y
      let image = this.arrayOfDisplayedImages[i].image
      let posX = this.arrayOfDisplayedImages[i].x
      let posY = this.arrayOfDisplayedImages[i].y
      let width = this.arrayOfDisplayedImages[i].width
      let height = this.arrayOfDisplayedImages[i].height
      if (posX >= this.xZone && posX <= this.zoneWidth - width + this.xZone && posY >= this.yZone && posY <= this.zoneHeight - height + this.yZone) {
        this.ctx.drawImage(image, posX, posY, width, height)
      } else {
        if (posX < this.xZone) {
          this.changeArrayOfDisplayedImages("remove left", i, posX, posY, width, height)
        }
        if (posX > this.zoneWidth - width + this.xZone) {
          this.changeArrayOfDisplayedImages("remove right", i, posX, posY, width, height)
        }
        if (posY < this.yZone) {
          this.changeArrayOfDisplayedImages("remove top", i, posX, posY, width, height)
        }
        if (posY > this.zoneHeight - height + this.yZone) {
          this.changeArrayOfDisplayedImages("remove bottom", i, posX, posY, width, height)
        }
      }
    }
  }
  changeArrayOfDisplayedImages(direction, index, x, y, width, height) {
    let randomIndex = this.randomIntFromInterval(0, this.arrayOfImageRef.length - 1)
    let randomImage = this.arrayOfImageRef[randomIndex]
    switch (direction) {
      case "remove left":
        let imageParameters = {
          x: this.xNumberOfDisplayedImages * width + x,
          y: y,
          width: width,
          height: height,
          image: randomImage.image,
          id: randomImage.id,
          model: randomImage.model,
        }
        this.arrayOfDisplayedImages[index] = imageParameters
        break;
      case "remove right":
        imageParameters = {
          x: x - this.xNumberOfDisplayedImages * width,
          y: y,
          width: width,
          height: height,
          image: randomImage.image,
          id: randomImage.id,
          model: randomImage.model,
        }
        this.arrayOfDisplayedImages[index] = imageParameters
        break;
      case "remove top":
        imageParameters = {
          x: x,
          y: this.yNumberOfDisplayedImages * height + y,
          width: width,
          height: height,
          image: randomImage.image,
          id: randomImage.id,
          model: randomImage.model,
        }
        this.arrayOfDisplayedImages[index] = imageParameters
        break;
      case "remove bottom":
        imageParameters = {
          x: x,
          y: y - this.yNumberOfDisplayedImages * height,
          width: width,
          height: height,
          image: randomImage.image,
          id: randomImage.id,
          model: randomImage.model,
        }
        this.arrayOfDisplayedImages[index] = imageParameters
        break;
    }
  }
  onMouseDown(e) {
    this.isDragging = true
    this.startX = e.clientX
    this.startY = e.clientY
    this.previousX = this.startX
    this.previousY = this.startY
  }
  onMouseMove(e) {
    if (this.isDragging) {
      this.currentPosX = (e.clientX - this.previousX)
      this.currentPosY = (e.clientY - this.previousY)
      this.previousX = e.clientX
      this.previousY = e.clientY
    }
  }
  async onMouseUp(e) {
    this.isDragging = false
    if (e.clientX <= this.startX + 5 && e.clientX >= this.startX - 5 && e.clientY <= this.startY + 5 && e.clientY >= this.startY - 5 && !this.objectIsSelected) {
      this.selectObject(e)
      this.objectIsSelected = true;
    } else {
      for (let i = 0; i < 200; i++) {
        this.currentPosX *= 0.98
        this.currentPosY *= 0.98
        await this.timer(10)
      }
    }
  }
  onTouchDown(e) {
    this.isDragging = true
    this.startX = e.changedTouches[0].clientX
    this.startY = e.changedTouches[0].clientY
    this.previousX = this.startX
    this.previousY = this.startY
  }
  onTouchMove(e) {
    if (this.isDragging) {
      this.currentPosX = (e.changedTouches[0].clientX - this.previousX)
      this.currentPosY = (e.changedTouches[0].clientY - this.previousY)
      this.previousX = e.changedTouches[0].clientX
      this.previousY = e.changedTouches[0].clientY
    }
  }
  async onTouchUp(e) {
    this.isDragging = false
    if (e.changedTouches[0].clientX <= this.startX + 5 && e.changedTouches[0].clientX >= this.startX - 5 && e.changedTouches[0].clientY <= this.startY + 5 && e.changedTouches[0].clientY >= this.startY - 5 && !this.objectIsSelected) {
      this.selectObject(e)
      this.objectIsSelected = true;
    } else {
      for (let i = 0; i < 200; i++) {
        this.currentPosX *= 0.98
        this.currentPosY *= 0.98
        await this.timer(10)
      }
    }
  }
  async selectObject(e) {
    let model = 0;
    let id = 0;
    for (let i = 0; i < this.arrayOfDisplayedImages.length; i++) {
      let posX = this.arrayOfDisplayedImages[i].x
      let posY = this.arrayOfDisplayedImages[i].y
      let width = this.arrayOfDisplayedImages[i].width
      let height = this.arrayOfDisplayedImages[i].height

      if (this.isMobile) {
        let touch = this.getTouchPosition(e)
        if (touch.x > posX && touch.x < posX + width && touch.y > posY && touch.y < posY + height) {
          id = this.arrayOfDisplayedImages[i].id
          model = this.arrayOfDisplayedImages[i].model
          this.indexOfObject = this.arrayOfObjectName.indexOf(model)
        }
      } else {
        let mouse = this.getMousePosition(e)

        if (mouse.x > posX && mouse.x < posX + width && mouse.y > posY && mouse.y < posY + height) {
          id = this.arrayOfDisplayedImages[i].id
          model = this.arrayOfDisplayedImages[i].model
          this.indexOfObject = this.arrayOfObjectName.indexOf(model)
        }
      }
    }
    if (id != 0) {
      if (!this.modelArray[this.indexOfObject]) {
        this.modelArray[this.indexOfObject] = await Preload.loadModel(this.refModelArray[this.indexOfObject])
      }
      await this.setupSelectedObject(model, id, this.indexOfObject)
    }
  }
  /////////////////////////////////////////////////////////////////
  render() {
    switch (this.renderMode) {
      case "archiveArray":
        this.drawDisplayedZone()
        this.updateDisplayedImages(this.currentPosX, this.currentPosY)
        break;
      case "displayObject":
        this.orbit.update()
        this.renderer.render(this.scene, this.camera)
        // this.drawDisplayedZone()
        // this.updateDisplayedImages(this.currentPosX, this.currentPosY)
        break;
      case "displayUrl":
        this.orbit.update()
        this.renderer.render(this.scene, this.camera)
        // this.drawDisplayedZone()
        // this.updateDisplayedImages(this.currentPosX, this.currentPosY)
        break;
      default:
        break;
    }
  }
  setCamera(posX, posY, posZ) {
    this.camera = new THREE.PerspectiveCamera(25, 1, 1, 10000)
    this.camera.position.set(posX, posY, posZ)
    this.scene.add(this.camera)
  }

  async setupSelectedObject(model, id, index) {
    this.id = id
    this.model = model
    this.setCamera(0, 0, 500)
    this.position = {
      x: 0,
      y: 0,
      z: 0,
    }
    this.object = new Object(this.parameters, index, this.camera, this.position, this.scene, this.renderer)
    await this.object.addOriginal(id)
    await this.object.addLabelModelToObject()
    this.addOrbitControls()

    this.renderMode = "displayObject"

    let testimonyValues = await this.firebaseManager.getTestimony(model, id)

    this.domManager.displayOverlayTestimony(testimonyValues)
    this.domManager.changeMainButtonOverlay("See in memorial")
    this.domManager.changeBackButtonOverlay("Link")
    this.domManager.toggleTestimonyOverlayContainer()

  }
  async goBackToArchive() {
    while (this.scene.children.length) {
      this.scene.remove(this.scene.children[0]);
    }
    if (document.getElementById("urlButton")) {
      this.domManager.removeCopyUrlButton()
      this.domManager.toggleTestimonyOverlayButton()
    }

    this.domManager.toggleTestimonyOverlayContainer()
    this.renderMode = "archiveArray"
    this.objectIsSelected = false;
  }
  displayUrl() {
    this.renderMode = "displayUrl"
    while (this.scene.children.length > 1) {
      this.scene.remove(this.scene.children[1]);
    }
    this.object.addLabelModelOfObject()
    let urlOfObject = "https://lockdown.memorial/?model=" + this.model + "&id=" + this.id + ""

    this.domManager.toggleTestimonyOverlayButton()
    this.domManager.setCopyUrlButton(urlOfObject)
    this.domManager.addCopyUrlText()
    this.domManager.removeDateOfTestimony()
  }

  addOrbitControls() {
    this.orbit = new OrbitControls(this.camera, this.renderer.domElement)
    this.orbit.enableZoom = false
    this.orbit.autoRotate = true;
    let canvasContainer = document.getElementById("testimonyOverlayCanvas")
    canvasContainer.addEventListener("mousemove", function () {
      // this.orbit.autoRotate = false;
    }.bind(this))
    canvasContainer.addEventListener("touchstart", function () {
      // this.orbit.autoRotate = false;
    }.bind(this))
  }

  /////////////////////////////////////////STEP 3
  removeArchive() {
    while (this.scene.children.length > 0) {
      this.scene.remove(this.scene.children[0]);
    }
    this.domManager.toggleArchiveCanvas()
    this.domManager.toggleTestimonyOverlayContainer()
    let url = {
      model: this.model,
      id: this.id,
      index: this.object.indexOfModel,
    }

    return url
  }
  ///////////////////////////////////////////////////////////////////////////TOOLS
  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  timer(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  getMousePosition(e) {
    let rect = this.canvas.getBoundingClientRect()
    return {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    }
  }
  getTouchPosition(e) {
    let rect = this.canvas.getBoundingClientRect()
    return {
      x: e.changedTouches[0].clientX - rect.left,
      y: e.changedTouches[0].clientY - rect.top,
    }
  }
}