import 'babel-polyfill'
import * as THREE from 'three'
import {
  Preload
} from './appModes/preload'

import {
  CreateTestimony
} from './appModes/createTestimony'
import {
  Memorial
} from './appModes/memorial'
import {
  Archive
} from './appModes/archive'
import {
  DomManager
} from './domElements/domManager'
import {
  FirebaseManager
} from './firebaseManager/firebaseManager'

let parameters = {}
let camera

let createTestimony, archive, memorial
let domManager = new DomManager()

let appState = null
let renderMode = null
let appMode = null

let initialX = null;
let initialY = null;

let backgroundColor = 0x000000

const MAX_LENGTH_OF_ARRAY_MODEL = 16

let isMobile = mobileCheck()

////////////////////////////////////////////////////////DISPLAYED FIRST

async function init() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlModel = urlParams.get('model')
  const urlID = urlParams.get('id')

  let firebaseManager = new FirebaseManager()

  parameters.lengthOfModelArray = 0
  parameters.indexOfModelToLoad = []
  parameters.indexOfModelLoaded = []
  parameters.modelArray = []

  domManager.setLoader()
  parameters.refModelArray = await Preload.loadModelsReferences();
  parameters.arrayOfModelName = await Preload.getArrayOfObjectName()
  for (let i = 0; i < parameters.refModelArray.length; i++) {
    parameters.indexOfModelToLoad[i] = i
  }
  loadRandomModel()
  parameters.labelArray = await Preload.loadLabelModels()

  domManager.setupTestimonyOverlayContainer()
  setTestimonyOverlayInteractions()
  domManager.setupCreateTestimony()
  domManager.setupArchiveCanvas()
  setArchiveCanvasInteraction()

  let mainOverlayButton = document.getElementById("mainButtonOverlay")
  mainOverlayButton.addEventListener("click", onMainOverlayButtonClick)

  let continueButton = document.getElementById("continueButton")
  continueButton.addEventListener("click", onContinueButtonClick)

  let backButton = document.getElementById("backButton")
  backButton.addEventListener("click", onBackButtonClick)

  threeSetup()

  if (urlModel != null && urlID != null) {
    appState = "archive"
    appMode = "archive"
    renderMode = "archive"
    domManager.setHeader(isMobile)
    domManager.changeHeaderText("LOCKDOWN MEMORIAL ARCHIVE")
    domManager.toggleArchiveCanvas()

    archive = new Archive(parameters, isMobile)

    let url = {
      model: urlModel,
      id: urlID,
      index: "",
    }
    archive.initWithID(url)
    setHeaderButtons()

  } else {
    appState = displayMenu()
  }
  render()
  domManager.removeLoader()
}

function displayMenu() {
  renderMode = "menu"
  appState = "menu"
  appMode = "menu"


  domManager.setMenu(isMobile)

  if (!isMobile) {
    let video = document.getElementById("backgroundVideo")
    video.oncanplaythrough = function () {
      console.log("video can play through")
      // alert("Can play through video without stopping");
      video.muted = true
      video.play()
    };
  }

  let archiveLink = document.getElementById("archiveLink")
  archiveLink.addEventListener("click", function () {
    // domManager.toggleArchiveLink()
    // setTimeout(onArchiveButtonMenuClick, 1000)
    onArchiveButtonMenuClick()
  })

  let memorialLink = document.getElementById("memorialLink")
  memorialLink.addEventListener("click", function () {
    onMemorialButtonMenuClick()
  })

  let testimonyLink = document.getElementById("testimonyLink")
  testimonyLink.addEventListener("click", onCreateTestimonyButtonMenuClick)

}

function onArchiveButtonMenuClick() {
  appState = "archive"
  appMode = "archive"
  renderMode = "archive"
  domManager.setHeader(isMobile)
  domManager.changeHeaderText("LOCKDOWN MEMORIAL ARCHIVE")

  domManager.toggleArchiveCanvas()
  domManager.removeMenu()

  archive = new Archive(parameters, isMobile)
  archive.init()

  setHeaderButtons()
}

function onMemorialButtonMenuClick() {

  appState = "choose Memorial"
  domManager.removeMenu()
  domManager.setHeader(isMobile)
  domManager.changeHeaderText("LOCKDOWN MEMORIAL ARCHIVE")

  if (isMobile) {
    domManager.displayOverlayMessage("MEMORIAL", "You are going to visit the memorial. Its goals is to submerge you into a lockdown atmosphere. <br> <br> <br><span class = \"secondInstructionTitle\"> INSTRUCTIONS </span> <br> Look around by moving your phone. You will be able to select an object or a room by clicking on the projected point. Once you choosed the memorial you will be able to move around. To move around the memorial, point a position on the floor, you will see an indicator. Once you want to move just click on this indicator.")
  } else {
    domManager.displayOverlayMessage("MEMORIAL", "You are going to visit the memorial. Its goals is to submerge you into a lockdown atmosphere. <br> <br> <br><span class = \"secondInstructionTitle\"> INSTRUCTIONS </span> <br> Look around by dragging your mouse. You will be able to select a room by clicking on it. Once you entered the memorial, you will be able to move around by clicking on the projected point on the floor. You will also be able to click on objects to look at their content.")
  }

  appMode = "memorial"

  domManager.setLoader()
  memorialThreeSetup()
  domManager.toggleMemorialCanvas()

  memorial = new Memorial(parameters, isMobile)
  appState = memorial.initMemorial()
  // memorial.setupMemorialInteractions()

  renderMode = "memorial"
  setHeaderButtons()
}

function onCreateTestimonyButtonMenuClick() {

  if (isMobile) {
    domManager.removeMenu()
    domManager.setHeader(isMobile)
    domManager.changeHeaderText("LOCKDOWN MEMORIAL ARCHIVE")
    appMode = "testimony"
    domManager.changeTitleText("")
    domManager.changeCentralText("")
    domManager.changeContinueButton('Continue')

    createTestimony = new CreateTestimony(parameters)
    createTestimony.shareYourTestimony()

    domManager.toggleCreateTestimonyCanvas()
    domManager.toggleButtonContainer()
    domManager.toggleContinueButton()
    domManager.toggleBackButton()
    domManager.toggleControlContainer()
    domManager.toggleCreateTestimonyContainer()

    createTestimony.setCamera()
    createTestimony.setOrbitControls()
    createTestimony.setRandomObject()

    domManager.displayObjectName()

    appState = createTestimony.presentSelectedObject()

    loadRandomModel()
    renderMode = "createTestimony"
    setHeaderButtons()
  } else {
    domManager.displayPreventDesktop()
    appState = "preventDesktop"
  }
}

function threeSetup() {

  ////////////////////////////////////////////////////////////////////////////////////////////TESTIMONY OVERLAY CANVAS SCENE
  let canvas = document.getElementById("testimonyOverlayCanvas")
  let testimonyOverlayScene = new THREE.Scene()
  let testimonyOverlayRenderer = new THREE.WebGLRenderer({
    canvas,
    antialias: true,
    preserveDrawingBuffer: true,
    alpha: true,
  })
  testimonyOverlayRenderer.setClearColor(backgroundColor, 0);

  let maxWidth = 512
  if (isMobile) {
    maxWidth = 340
  } else {
    maxWidth = 340
  }
  if (window.innerWidth < maxWidth) {
    testimonyOverlayRenderer.setSize(window.innerWidth, window.innerWidth);
  } else {
    testimonyOverlayRenderer.setSize(maxWidth, maxWidth);
  }
  parameters.testimonyOverlayScene = testimonyOverlayScene
  parameters.testimonyOverlayRenderer = testimonyOverlayRenderer
  parameters.testimonyOverlayRenderer.setPixelRatio(window.devicePixelRatio)

  // ////////////////////////////////////////////////////////////////////////////////////////////MEMORIAL CANVAS SCENE

  ////////////////////////////////////////////////////////////////////////////////////////////CREATE TESTIMONY CANVAS SCENE
  canvas = document.getElementById("createTestimonyCanvas")

  let createTestimonyScene = new THREE.Scene()
  let createTestimonyRenderer = new THREE.WebGLRenderer({
    canvas,
    antialias: true,
    preserveDrawingBuffer: true,
    alpha: true
  })
  createTestimonyRenderer.setClearColor(backgroundColor, 0);

  maxWidth = 260;
  if (window.innerWidth < maxWidth) {
    createTestimonyRenderer.setSize(window.innerWidth, window.innerWidth);
  } else {
    createTestimonyRenderer.setSize(maxWidth, maxWidth);
  }

  parameters.createTestimonyScene = createTestimonyScene
  parameters.createTestimonyRenderer = createTestimonyRenderer
  parameters.createTestimonyRenderer.setPixelRatio(window.devicePixelRatio)

  window.addEventListener('resize', onWindowResize, false);
}

function memorialThreeSetup() {

  domManager.setupMemorialCanvas()

  let canvas = document.getElementById("memorialCanvas")

  let memorialScene = new THREE.Scene()
  let memorialRenderer = new THREE.WebGLRenderer({
    canvas,
    antialias: true,
    preserveDrawingBuffer: true,
    alpha: true
  })

  memorialRenderer.setPixelRatio(window.devicePixelRatio);
  memorialRenderer.setClearColor(backgroundColor, 0);
  memorialRenderer.setSize(window.innerWidth, window.innerHeight);

  parameters.memorialScene = memorialScene
  parameters.memorialRenderer = memorialRenderer
}

//////////////////////////////////////////////////////// BUTTONS INTERACTIONS

function setTestimonyOverlayInteractions() {
  let closeTestimonyOverlayButton = document.getElementById("testimonyOverlayCloseButton")
  closeTestimonyOverlayButton.addEventListener("click", function () {
    onCloseTestimonyOverlayButtonClick()
  }.bind(this))

  let linkButton = document.getElementById("backButtonOverlay")
  linkButton.addEventListener("click", function () {
    // archive.displayUrl()
    onLinkButtonClick()
  }.bind(this))
}

function onCloseTestimonyOverlayButtonClick() {
  switch (appMode) {
    case "archive":
      archive.renderMode = ""
      archive.goBackToArchive()
      break
    case "memorial":
      memorial.displayNewMemorial()
      break
  }
}

function onLinkButtonClick() {
  switch (appMode) {
    case "archive":
      archive.displayUrl()
      break
    case "memorial":
      memorial.displayUrl()
      break
  }

}


async function onContinueButtonClick() {
  console.log("clkch")
  switch (appState) {
    case "instruction1":
      domManager.removeObjectName()
      appState = createTestimony.setImportance()
      break;

    case "instruction2":
      createTestimony.saveImportanceValue()
      domManager.removeImportanceInterface()

      domManager.setCaptureElements()
      domManager.setVideo()

      createTestimony.setOriginalScaleToObject()
      appState = createTestimony.setYourObject()
      break;

    case "instruction3":
      createTestimony.getCameraValue()
      appState = createTestimony.setYourStory()

      break;

    case "instruction4":
      let createTestimonyCanvas = document.getElementById("createTestimonyCanvas")
      if (!createTestimonyCanvas.classList.contains("nonVisible")) {
        domManager.toggleCreateTestimonyCanvas()
      }

      createTestimony.saveTextTestimony()
      domManager.removeYourStoryInput()
      domManager.toogleCameraView()
      appState = createTestimony.setYourView()
      break;
    case "instruction5":
      createTestimony.saveViewValue()
      appState = createTestimony.setYourInfos()

      break;
    case "instruction6":
      createTestimony.saveInfosValue()
      domManager.removeYourInfos()
      domManager.toggleCreateTestimonyCanvas()
      createTestimony.setTestimonyButton()
      appState = createTestimony.setPreview()

      break;
    case "instruction7":
      createTestimonyCanvas = document.getElementById("createTestimonyCanvas")
      if (!createTestimonyCanvas.classList.contains("nonVisible")) {
        domManager.toggleCreateTestimonyCanvas()
      }
      createTestimony.centerCamera()
      setTimeout(function () {
        appState = createTestimony.setHelp()
      }, 100)

      break;
    case "instruction8":
      appState = createTestimony.setShare()
      break;
    case "instruction9":
      appState = createTestimony.setThanks()
      break;
    case "endTestimony":
      let url = createTestimony.removeCreateTestimony()

      removeAllElementsFromDom()
      removeObjectsFromAllScenes()

      appMode = "memorial"
      domManager.setLoader()
      memorialThreeSetup()
      memorial = new Memorial(parameters, isMobile)

      appState = memorial.initMemorialWithID(url)

      renderMode = "memorial"
      break;
    case "choose Memorial":
      domManager.removeInstructionText()
      domManager.removeContinueButton()

      appMode = "memorial"

      domManager.setLoader()
      domManager.toggleCanvas()
      memorial = new Memorial(parameters, isMobile)
      appState = memorial.initMemorial()

      renderMode = "memorial"
      break;

    case "archive":
      url = archive.removeArchive()

      appMode = "memorial"
      domManager.setLoader()
      // threeSetup()

      memorial = new Memorial(parameters, isMobile)

      appState = memorial.initMemorialWithID(url)

      renderMode = "memorial"

      break;
  }

}

function onMainOverlayButtonClick() {
  switch (appState) {
    case "archive":
      let url = archive.removeArchive()

      appMode = "memorial"
      domManager.setLoader()
      memorialThreeSetup()
      memorial = new Memorial(parameters, isMobile)
      appState = memorial.initMemorialWithID(url)
      renderMode = "memorial"
      break
  }
}

async function onBackButtonClick() {
  console.log(appState)
  switch (appState) {
    case "instruction1":
      await loadRandomModel()
      createTestimony.removeObject()
      createTestimony.setRandomObject()
      createTestimony.presentSelectedObject()
      break;
    case "instruction2":
      domManager.displayObjectName()
      appState = createTestimony.presentSelectedObject()
      domManager.removeImportanceInterface()
      break;
    case "instruction3":
      appState = createTestimony.setImportance()
      let camera = document.getElementById("camera")
      camera.remove()
      break;
    case "instruction4":
      let yourStoryInput = document.getElementById("yourStoryInput")
      if (yourStoryInput.classList.contains("bigStoryInput")) {
        domManager.toggleCreateTestimonyCanvas()
      }
      domManager.removeYourStoryInput()
      appState = createTestimony.setYourObject()

      break;
    case "instruction5":
      domManager.toogleCameraView()
      domManager.toggleCreateTestimonyCanvas()

      appState = createTestimony.setYourStory()
      break;
    case "instruction6":
      domManager.toogleCameraView()
      domManager.removeYourInfos()
      appState = createTestimony.setYourView()
      break;
    case "instruction7":
      let canvas = document.getElementById("createTestimonyCanvas")
      if (!canvas.classList.contains("nonVisible")) {
        domManager.toggleCreateTestimonyCanvas()
      }
      domManager.removeTestimony()
      appState = createTestimony.setYourInfos()
      createTestimony.removeCurrentLabel()
      break;
    case "instruction8":
      createTestimony.sendEmail()
      break;
    case "instruction9":
      createTestimony.displayLinkToWebsite()
      break;
    case "endTestimony":
      removeObjectsFromAllScenes()
      removeHeaderFromDom()
      removeAllElementsFromDom()

      createTestimony.removeCreateTestimony()
      appState = displayMenu()
      break
  }
}

function setHeaderButtons() {
  if (isMobile) {
    let homeButton = document.getElementById("homeLink")
    homeLink.addEventListener("click", onHomeButtonClick)
    let testimonyButton = document.getElementById("testimonyLink")
    testimonyButton.addEventListener("click", onTestimonyButtonClick)
  }
  let headerTitle = document.getElementById("headerTitle")
  let archiveButton = document.getElementById("archiveLink")
  let memorialButton = document.getElementById("memorialLink")
  let aboutButton = document.getElementById("aboutLink")

  headerTitle.addEventListener("click", onHomeButtonClick)
  archiveButton.addEventListener("click", onArchiveButtonClick)
  memorialButton.addEventListener("click", onMemorialButtonClick)
  aboutButton.addEventListener("click", onAboutButtonClick)
}

async function onMemorialImageClick(event) {
  appMode = "memorial"

  let index
  switch (event.target.id) {
    case "memorialImage1":
      index = 0
      domManager.removeMemorialMenu()
      domManager.setLoader()
      domManager.toggleCanvas()
      memorial = new Memorial(parameters, isMobile)
      appState = memorial.initMemorial()

      renderMode = "memorial"
      break;
    case "memorialImage2":
      index = 1
      domManager.removeMemorialMenu()
      domManager.setLoader()
      domManager.toggleCanvas()
      memorial = new Memorial(parameters, isMobile)
      appState = memorial.initMemorial()
      renderMode = "memorial"
      break;
    case "memorialImage3":
      index = 2
      domManager.removeMemorialMenu()
      domManager.setLoader()
      domManager.toggleCanvas()
      memorial = new Memorial(parameters, isMobile)
      appState = memorial.initMemorial()
      renderMode = "memorial"
      break;
    case "memorialImage4":
      index = 3
      domManager.removeMemorialMenu()
      domManager.setLoader()
      domManager.toggleCanvas()
      memorial = new Memorial(parameters, isMobile)
      appState = memorial.initMemorial()
      renderMode = "memorial"
      break;
    default:
      index = 0
      break;
  }

}

function onAboutButtonClick() {
  removeAllElementsFromDom()
  removeObjectsFromAllScenes()

  domManager.setAboutPage()
  appState = "aboutPage"

}

function onHomeButtonClick() {
  removeObjectsFromAllScenes()
  removeHeaderFromDom()
  removeAllElementsFromDom()
  displayMenu()
}

function onArchiveButtonClick() {
  removeObjectsFromAllScenes()
  removeAllElementsFromDom()
  removeHeaderFromDom()
  displayMenu()
  onArchiveButtonMenuClick()
}

function onMemorialButtonClick() {
  removeObjectsFromAllScenes()
  removeAllElementsFromDom()
  removeHeaderFromDom()
  displayMenu()
  onMemorialButtonMenuClick()
}

function onTestimonyButtonClick() {
  removeObjectsFromAllScenes()
  removeAllElementsFromDom()
  removeHeaderFromDom()
  displayMenu()
  onCreateTestimonyButtonMenuClick()
}

function removeHeaderFromDom() {
  let header = document.getElementById("header")
  if (header) {
    header.remove()
  }
}

function removeAllElementsFromDom() {
  let archiveCanvas = document.getElementById("archiveCanvas")
  if (!archiveCanvas.classList.contains("nonVisible")) {
    domManager.toggleArchiveCanvas()
  }
  let testimonyOverlayContainer = document.getElementById("testimonyOverlayContainer")
  if (!testimonyOverlayContainer.classList.contains("nonVisible")) {
    domManager.toggleTestimonyOverlayContainer()
  }
  let createTestimonyContainer = document.getElementById("createTestimonyContainer")
  if (!createTestimonyContainer.classList.contains("nonVisible")) {
    domManager.toggleCreateTestimonyContainer()
  }
  let createTestimonyCanvas = document.getElementById("createTestimonyCanvas")
  if (!createTestimonyCanvas.classList.contains("nonVisible")) {
    domManager.toggleCreateTestimonyCanvas()
  }
  let buttonContainer = document.getElementById("buttonContainer")
  if (!buttonContainer.classList.contains("nonVisible")) {
    domManager.toggleButtonContainer()
  }
  let continueButton = document.getElementById("continueButton")
  if (!continueButton.classList.contains("nonVisible")) {
    domManager.toggleContinueButton()
  }
  let backButton = document.getElementById("backButton")
  if (!backButton.classList.contains("nonVisible")) {
    domManager.toggleBackButton()
  }
  let controlContainer = document.getElementById("controlContainer")
  if (!controlContainer.classList.contains("nonVisible")) {
    domManager.toggleControlContainer()
  }
  let sliderContainer = document.getElementById("sliderContainer")
  if (!sliderContainer.classList.contains("nonVisible")) {
    domManager.removeImportanceInterface()
  }
  let objectNameContainer = document.getElementById("objectNameContainer")
  if (!objectNameContainer.classList.contains("nonVisible")) {
    domManager.removeObjectName()
  }
  if (document.getElementById("camera")) {
    let mainCamera = document.getElementById("camera")
    mainCamera.remove()
  }
  if (document.getElementById("yourStoryInput")) {
    let yourStoryInput = document.getElementById("yourStoryInput")
    yourStoryInput.remove()
  }
  let yourInfosContainer = document.getElementById("yourInfosContainer")
  if (!yourInfosContainer.classList.contains("nonVisible")) {
    domManager.removeYourInfos()
  }
  if (document.getElementById("testimonyContainer")) {
    document.getElementById("testimonyContainer").remove()
  }
  if (document.getElementById("emailContainer")) {
    document.getElementById("emailContainer").remove()
  }
  if (document.getElementById("camera--output3")) {
    document.getElementById("camera--output3").remove()
  }
  if (document.getElementById("urlButton")) {
    document.getElementById("urlButton").remove()
  }

  let loader = document.getElementById("loaderContainer")
  let loaderOverlay = document.getElementById("loaderOverlay")
  if (loader) {
    loader.remove()
    loaderOverlay.remove()
  }
  let aboutPage = document.getElementById("aboutTitle")
  if (aboutPage) {
    domManager.removeAboutPage()
  }
  let preventContainer = document.getElementById("preventContainer")
  if (preventContainer) {
    preventContainer.remove()
  }
  let overlayMessageContainer = document.getElementById("overlayMessageContainer")
  if (overlayMessageContainer) {
    overlayMessageContainer.remove()
  }
  let backHeaderButton = document.getElementById("backHeaderButton")
  if (backHeaderButton) {
    backHeaderButton.remove()
    domManager.displayHeaderTitle()
  }
  let mainButtonOverlay = document.getElementById("mainButtonOverlay")
  if (mainButtonOverlay.classList.contains("nonVisible")) {
    domManager.toggleMainButtonOverlay()
  }
  if (document.getElementById("memorialCanvas")) {
    domManager.removeMemorialCanvas()
  }
}
async function removeObjectsFromAllScenes() {
  if (parameters.testimonyOverlayScene) {
    while (parameters.testimonyOverlayScene.children.length) {
      await parameters.testimonyOverlayScene.remove(parameters.testimonyOverlayScene.children[0]);
    }
  }
  if (parameters.memorialScene) {
    while (parameters.memorialScene.children.length) {
      await parameters.memorialScene.remove(parameters.memorialScene.children[0]);
    }
  }
  if (parameters.createTestimonyScene) {
    while (parameters.createTestimonyScene.children.length) {
      await parameters.createTestimonyScene.remove(parameters.createTestimonyScene.children[0]);
    }
  }
}
//////////////////////////////////////////////////////////CANVAS INTERACTONS
function setArchiveCanvasInteraction() {
  let archiveCanvas = document.getElementById("archiveCanvas")
  if (isMobile) {
    archiveCanvas.addEventListener("touchstart", function (e) {
      archive.onTouchDown(e)
    }.bind(this))
    archiveCanvas.addEventListener("touchmove", function (e) {
      archive.onTouchMove(e)
    }.bind(this))
    archiveCanvas.addEventListener("touchend", function (e) {
      archive.onTouchUp(e)
    }.bind(this))
  } else {
    archiveCanvas.addEventListener("mousedown", function (e) {
      archive.onMouseDown(e)
    }.bind(this))
    archiveCanvas.addEventListener("mousemove", function (e) {
      archive.onMouseMove(e)
    }.bind(this))
    archiveCanvas.addEventListener("mouseup", function (e) {
      archive.onMouseUp(e)
    }.bind(this))
  }
}
//////////////////////////////////////////////////////////RENDER
function render() {
  requestAnimationFrame(render)
  switch (renderMode) {
    case "menu":
      break;
    case "createTestimony":
      createTestimony.render()
      break;
    case "archive":
      archive.render()
      break;
    case "memorial":
      memorial.render()
      break;
    default:
      break;
  }
}

function onWindowResize() {
  // if (appMode == "testimony") {
  //   let maxWidth = 512
  //   if (window.innerWidth < maxWidth) {
  //     parameters.renderer.setSize(window.innerWidth * 0.9, ((window.innerWidth * 0.9) * 9) / 16);
  //   } else {
  //     parameters.renderer.setSize(maxWidth * 0.9, ((maxWidth * 0.9) * 9) / 16);
  //   }
  // } else if (appMode == "memorial") {
  //   parameters.renderer.setSize(window.innerWidth, window.innerHeight);
  // }
}

function mobileCheck() {
  let check = false;
  (function (a) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  console.log("isMobile : " + check)
  return check;
}

window.addEventListener("load", function () {
  init().catch(error => {
    console.error(error);
  });
});

/////////////////////////////////////////////////////////////TOOLS

function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min);
}

async function loadRandomModel() {
  domManager.setLoader()
  if (parameters.indexOfModelToLoad.length > 0) {
    let randomIndexFromZeroToAmountOfNeededObjects = randomIntFromInterval(0, parameters.indexOfModelToLoad.length - 1)
    let randomIndexOfModelNeeded = parameters.indexOfModelToLoad[randomIndexFromZeroToAmountOfNeededObjects]
    parameters.modelArray[randomIndexOfModelNeeded] = await Preload.loadModels(parameters.refModelArray, randomIndexOfModelNeeded);

    parameters.indexOfModelToLoad.splice(randomIndexFromZeroToAmountOfNeededObjects, 1)
    parameters.indexOfModelLoaded.push(randomIndexOfModelNeeded)
  }
  domManager.removeLoader()
}