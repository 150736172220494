import 'babel-polyfill'
import * as THREE from 'three'
import {
  ProjectedMaterial
} from '../shaders/ProjectedMaterial'
import {
  FixedMaterial
} from '../shaders/FixedMaterial'

import {
  DomManager
} from '../domElements/domManager';
import {
  FirebaseManager
} from '../firebaseManager/firebaseManager'


export class CreateObject {
  constructor(parameters, index, camera) {
    this.scene = parameters.createTestimonyScene
    this.renderer = parameters.createTestimonyRenderer
    this.modelArray = parameters.modelArray ? parameters.modelArray : false
    this.labelArray = parameters.labelArray
    this.indexOfModel = index
    this.camera = camera
    this.firebaseManager = new FirebaseManager()
  }
  addObject() {
    this.currentModel = this.modelArray[this.indexOfModel].clone()
    this.scene.add(this.currentModel)
    this.originalTexture = this.currentModel.children[0].material
    this.newObjectScale = 1
  }

  initTexture() {

    if (this.currentModel) {
      this.camView = document.getElementById('camera--view')
      this.textureKey = new THREE.VideoTexture(this.camView);
      this.material = new ProjectedMaterial({
        camera: this.camera,
        texture: this.textureKey,
        color: '#3149D5',
      })
      this.currentModel.traverse(function (child) {
        if (child instanceof THREE.Mesh) {
          child.material = this.material;
        }
      }.bind(this));
    }

  }

  updateTexture() {
    if (this.currentModel) {

      this.material = new ProjectedMaterial({
        camera: this.camera,
        texture: this.textureKey,
        color: '#3149D5',
      })
      this.currentModel.traverse(function (child) {
        if (child instanceof THREE.Mesh) {
          child.material = this.material;
        }
      }.bind(this));
    }
  }
  setOriginalTexture() {
    this.currentModel.traverse(function (child) {
      if (child instanceof THREE.Mesh) {
        child.material = this.originalTexture;
      }
    }.bind(this));
  }
  async bakeTexture(cameraValue, testimonyValues) {
    this.cameraValue = cameraValue
    this.testimonyTexture = testimonyValues.texture
    this.textureKey = new THREE.TextureLoader().load(this.testimonyTexture);
    this.material = new FixedMaterial({
      camera: this.cameraValue,
      texture: this.textureKey,
      color: '#080808',
    })
    this.currentModel.traverse(await
      function (child) {
        if (child instanceof THREE.Mesh) {
          child.material = this.material;
        }
      }.bind(this));
  }
  addLabelModelToObject() {
    this.labelParameters = {}
    this.labelParameters = this.getParametersOfLabelFromIndexModel()
    this.labelModel = this.labelArray[this.labelParameters.index]
    this.scene.add(this.labelModel)

    this.labelModel.position.x = this.labelParameters.position.x
    this.labelModel.position.y = this.labelParameters.position.y
    this.labelModel.position.z = this.labelParameters.position.z

    this.labelModel.scale.x = this.labelParameters.scale
    this.labelModel.scale.y = this.labelParameters.scale
    this.labelModel.scale.z = this.labelParameters.scale

    // this.addTextureUrlToLabel()
  }
  removeLabelModel() {
    this.scene.remove(this.labelModel)
  }
  addTextureUrlToLabel() {
    ///MODIFIER CAR çA MARCHE PAS ....
    let labelCanvas = document.createElement("canvas")

    let mainCanvas = document.getElementById("canvas")
    labelCanvas.width = mainCanvas.width;
    labelCanvas.height = mainCanvas.height;
    let ctx = labelCanvas.getContext("2d")
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, labelCanvas.width, labelCanvas.width)
    ctx.fillStyle = "blue";
    ctx.font = '40pt Arial';
    ctx.fillText("Hello World!", 0, 0);

    // document.body.appendChild(labelCanvas)

    var labelTexture = new THREE.CanvasTexture(labelCanvas);
    var labelMaterial = new THREE.MeshLambertMaterial({
      emissive: new THREE.Color(0xffffff),
      emissiveMap: labelTexture,
    });
    // labelTexture.needsUpdate = true;

    this.labelModel.traverse(
      function (child) {
        if (child instanceof THREE.Mesh) {
          child.material = labelMaterial;
        }
      }.bind(this));



  }
  removeObject() {
    this.scene.remove(this.currentModel)
    this.scene.remove(this.labelModel)
  }
  rotate(rotateCounter) {
    this.currentModel.rotateOnAxis(new THREE.Vector3(0, 1, 0), rotateCounter)
  }
  scaleObject(value) {

    this.newObjectScale = this.remap(value, 0, 100, 0.5, 1.5)
    this.currentModel.scale.x = this.newObjectScale
    this.currentModel.scale.y = this.newObjectScale
    this.currentModel.scale.z = this.newObjectScale
  }
  setOriginalScale() {
    this.currentModel.scale.x *= (1 / this.newObjectScale)
    this.currentModel.scale.y *= (1 / this.newObjectScale)
    this.currentModel.scale.z *= (1 / this.newObjectScale)
  }
  remap(num, in_min, in_max, out_min, out_max) {
    return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
  }

  addLabelModelOfObject() {
    this.labelParameters = {}
    this.labelParameters = this.getParametersOfLabelFromIndexModel()
    this.labelModel = this.labelArray[this.labelParameters.index]
    this.labelModel.position.x = 0
    this.labelModel.position.y = 0
    this.labelModel.position.z = 0

    this.labelModel.scale.x = 2
    this.labelModel.scale.y = 2
    this.labelModel.scale.z = 2

    if (this.labelModel.children[0].name == "label2") {
      const ctx = document.createElement('canvas').getContext('2d');
      ctx.canvas.width = 300;
      ctx.canvas.height = 80;
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.fillStyle = '#056bf0'
      ctx.font = "15px Roboto";

      ctx.fillText("https://lockdown.memorial/?model=", 10, 15);
      ctx.fillText("" + this.currentModel.children[0].name + "%id=" + this.ID + "", 10, 35);

      const texture = new THREE.CanvasTexture(ctx.canvas);

      var labelIdgeometry = new THREE.PlaneGeometry(30, 8, 32);
      var labelIDmaterial = new THREE.MeshBasicMaterial({
        map: texture,
      });
      var labelId = new THREE.Mesh(labelIdgeometry, labelIDmaterial);

      labelId.rotateX(-Math.PI / 2)
      labelId.position.x = 10;
      labelId.position.y = 0.3;
      labelId.position.z = 8;

      texture.needsUpdate = true;

      this.labelModel.add(labelId)

      this.scene.add(this.labelModel)
    } else {
      const ctx = document.createElement('canvas').getContext('2d');
      ctx.canvas.width = 300;
      ctx.canvas.height = 80;
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.fillStyle = '#056bf0'
      ctx.font = "16px Roboto";

      ctx.fillText("https://lockdown.memorial/?model=", 20, 15);
      ctx.fillText("" + this.currentModel.children[0].name + "&id=" + this.ID + "", 20, 35);

      const texture = new THREE.CanvasTexture(ctx.canvas);

      var labelIdgeometry = new THREE.PlaneGeometry(30, 8, 32);
      var labelIDmaterial = new THREE.MeshBasicMaterial({
        map: texture,
      });
      var labelId = new THREE.Mesh(labelIdgeometry, labelIDmaterial);

      labelId.rotateX(-Math.PI / 2)
      labelId.position.x = 24;
      labelId.position.y = 0.3;
      labelId.position.z = 16;

      texture.needsUpdate = true;

      this.labelModel.add(labelId)

      this.scene.add(this.labelModel)
    }
    this.labelModel.rotateX(Math.PI / 2)

  }
  getParametersOfLabelFromIndexModel() {
    let currentLabelParameters = {
      index: 0,
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
      scale: 1,
    }
    switch (this.indexOfModel) {
      case 0:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = -10;
        currentLabelParameters.position.y = -30;
        currentLabelParameters.position.z = 50;
        currentLabelParameters.scale = 1.25;
        break;
      case 1:
        currentLabelParameters.index = 0
        currentLabelParameters.position.x = -30;
        currentLabelParameters.position.y = -35;
        currentLabelParameters.position.z = 50;
        currentLabelParameters.scale = 1.25;
        break;
      case 2:
        currentLabelParameters.index = 0
        currentLabelParameters.position.x = -20;
        currentLabelParameters.position.y = -22;
        currentLabelParameters.position.z = 55;
        currentLabelParameters.scale = 1.25;
        break;
      case 3:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = -10;
        currentLabelParameters.position.y = -70;
        currentLabelParameters.position.z = 50;
        currentLabelParameters.scale = 1.25;
        break;
      case 4:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = -10;
        currentLabelParameters.position.y = -70;
        currentLabelParameters.position.z = 50;
        currentLabelParameters.scale = 1.25;
        break;
      case 5:
        currentLabelParameters.index = 0
        currentLabelParameters.position.x = -50;
        currentLabelParameters.position.y = -40;
        currentLabelParameters.position.z = 68;
        currentLabelParameters.scale = 1.25;
        break;
      case 6:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = -10;
        currentLabelParameters.position.y = -35;
        currentLabelParameters.position.z = 80;
        currentLabelParameters.scale = 1.25;
        break;
      case 7:
        currentLabelParameters.index = 0
        currentLabelParameters.position.x = -27;
        currentLabelParameters.position.y = -42;
        currentLabelParameters.position.z = 45;
        currentLabelParameters.scale = 1.25;
        break;
      case 8:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = -10;
        currentLabelParameters.position.y = -72;
        currentLabelParameters.position.z = 25;
        currentLabelParameters.scale = 1.25;
        break;
      case 9:
        currentLabelParameters.index = 0
        currentLabelParameters.position.x = -25;
        currentLabelParameters.position.y = -20;
        currentLabelParameters.position.z = 85;
        currentLabelParameters.scale = 1.25;
        break;
      case 10:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = -10;
        currentLabelParameters.position.y = -70;
        currentLabelParameters.position.z = 63;
        currentLabelParameters.scale = 1.25;
        break;
      case 11:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = 30;
        currentLabelParameters.position.y = -55;
        currentLabelParameters.position.z = 55;
        currentLabelParameters.scale = 1.25;
        break;
      case 12:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = -10;
        currentLabelParameters.position.y = -52;
        currentLabelParameters.position.z = 65;
        currentLabelParameters.scale = 1.25;
        break;
      case 13:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = 0;
        currentLabelParameters.position.y = -60;
        currentLabelParameters.position.z = 45;
        currentLabelParameters.scale = 1.25;
        break;
      case 14:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = 0;
        currentLabelParameters.position.y = -80;
        currentLabelParameters.position.z = 25;
        currentLabelParameters.scale = 1.25;
        break;
        // case 15:
        //   currentLabelParameters.index = 1
        //   currentLabelParameters.position.x = -10;
        //   currentLabelParameters.position.y = -30;
        //   currentLabelParameters.position.z = 50;
        //   currentLabelParameters.scale = 1.25;
        //   break;

    }
    return currentLabelParameters
  }

}