import 'babel-polyfill'
import * as THREE from 'three'
import {
  FixedMaterial
} from '../shaders/FixedMaterial'
import {
  FirebaseManager
} from '../firebaseManager/firebaseManager'

export class Object {
  constructor(parameters, index, camera, position, scene, renderer) {
    this.scene = scene
    this.renderer = renderer
    this.modelArray = parameters.modelArray ? parameters.modelArray : false
    this.indexOfModel = index
    this.camera = camera
    this.position = position ? position : false
    this.firebaseManager = new FirebaseManager()
    this.labelArray = parameters.labelArray
  }
  async add() {
    this.newObject = this.modelArray[this.indexOfModel].clone()
    this.newObject.renderOrder = 1;

    await this.getTextureValue()
    this.scaleObject()
    this.addTexture()

    // this.newObject.children[0].firebaseID = this.ID
    this.newObject.position.set(this.position.x, this.position.y, this.position.z)
    this.scene.add(this.newObject)
    this.renderer.render(this.scene, this.camera)
  }
  async addOriginal(id, currentScene) {
    this.newObject = this.modelArray[this.indexOfModel].clone()

    await this.getTextureFromID(id)
    this.addTexture()
    this.newObject.position.set(this.position.x, this.position.y, this.position.z)
    this.scene.add(this.newObject)
    this.renderer.render(this.scene, this.camera)
  }
  getBoundingBox() {
    let box = new THREE.Box3();
    this.newObject.children[0].geometry.computeBoundingBox();
    box.copy(this.newObject.children[0].geometry.boundingBox).applyMatrix4(this.newObject.children[0].matrixWorld);

    var geometry = new THREE.BoxBufferGeometry(box.max.x - box.min.x, box.max.y - box.min.y, box.max.z - box.min.z);
    var material = new THREE.MeshBasicMaterial({
      color: 0x00ff00
    });
    var cube = new THREE.Mesh(geometry, material);
    cube.refUuid = this.newObject.uuid
    cube.firebaseID = this.ID
    return cube
  }
  async getTextureValue() {
    this.ID = await this.firebaseManager.getIDfromIndex(this.newObject.children[0].name)
    this.imageTexture = await this.firebaseManager.getImageURL(this.newObject.children[0].name, this.ID)
    this.cameraValue = await this.firebaseManager.getCameraObjectValue(this.newObject.children[0].name, this.ID)
    this.scaleValue = await this.firebaseManager.getScaleValue(this.newObject.children[0].name, this.ID)
  }
  async getTextureFromID(id) {
    this.ID = id
    this.imageTexture = await this.firebaseManager.getImageURL(this.newObject.children[0].name, this.ID)
    this.cameraValue = await this.firebaseManager.getCameraObjectValue(this.newObject.children[0].name, this.ID)
    this.scaleValue = await this.firebaseManager.getScaleValue(this.newObject.children[0].name, this.ID)
  }
  getID() {
    return this.ID
  }
  scaleObject() {
    this.newObject.scale.x = this.scaleValue / 50
    this.newObject.scale.y = this.scaleValue / 50
    this.newObject.scale.z = this.scaleValue / 50
  }

  addTexture() {
    // let isDesk = this.newObject.children[0].name === "desk"

    this.setCameraValueToTempCamera()
    this.textureKey = new THREE.TextureLoader().load(this.imageTexture, function (t) {

      this.material = new FixedMaterial({
        camera: this.tempCamera,
        texture: t,
        color: '#080808',
      })
      this.newObject.traverse(function (child) {
        // if (isDesk) {
        if (child instanceof THREE.Mesh) {
          child.material = this.material;
        }
        // }
      }.bind(this));

    }.bind(this));
  }

  setCameraValueToTempCamera() {
    this.tempCamera = new THREE.OrthographicCamera(window.innerWidth / -2, window.innerWidth / 2, window.innerHeight / 2, window.innerHeight / -2, 1, 1000)
    this.tempCamera.position.set(0, 0, 200)
    this.tempCamera.matrixWorldInverse.elements = this.cameraValue.matrixWorldInverse.elements
    this.tempCamera.matrixWorld.elements = this.cameraValue.matrixWorld.elements
    this.tempCamera.projectionMatrix.elements = this.cameraValue.projectionMatrix.elements
    this.tempCamera.position.x = this.cameraValue.position.x
    this.tempCamera.position.y = this.cameraValue.position.y
    this.tempCamera.position.z = this.cameraValue.position.z
  }
  async getTestimony(id) {
    this.testimony = await this.firebaseManager.getTestimony(this.indexOfModel, id)
    return this.testimony
  }
  setOutlineTexture(object) {
    this.outlineMesh = this.modelArray[this.indexOfModel].clone()

    object.getWorldPosition(this.outlineMesh.position)
    object.getWorldQuaternion(this.outlineMesh.quaternion)
    object.getWorldScale(this.outlineMesh.scale)

    this.scene.add(this.outlineMesh);
  }
  rotateX(rotateCounter) {
    this.newObject.rotateOnAxis(new THREE.Vector3(1, 0, 0), rotateCounter)
  }
  rotateY(rotateCounter) {
    this.newObject.rotateOnAxis(new THREE.Vector3(0, 1, 0), rotateCounter)
  }
  rotateZ(rotateCounter) {
    this.newObject.rotateOnAxis(new THREE.Vector3(0, 0, 1), rotateCounter)
  }

  addLabelModelToObject(currentScene) {
    this.labelParameters = {}
    this.labelParameters = this.getParametersOfLabelFromIndexModel()
    this.labelModel = this.labelArray[this.labelParameters.index].clone()
    if (currentScene) {
      currentScene.add(this.labelModel)
    } else {
      this.scene.add(this.labelModel)
    }

    this.labelModel.position.x = this.labelParameters.position.x
    this.labelModel.position.y = this.labelParameters.position.y
    this.labelModel.position.z = this.labelParameters.position.z

    this.labelModel.scale.x = this.labelParameters.scale
    this.labelModel.scale.y = this.labelParameters.scale
    this.labelModel.scale.z = this.labelParameters.scale
  }
  removeLabelModel() {
    this.scene.remove(this.labelModel)
  }
  addLabelModelOfObject(currentScene) {
    this.labelParameters = {}
    this.labelParameters = this.getParametersOfLabelFromIndexModel()
    this.labelModel = this.labelArray[this.labelParameters.index].clone()
    this.labelModel.position.x = 0
    this.labelModel.position.y = 0
    this.labelModel.position.z = 0

    this.labelModel.scale.x = 2
    this.labelModel.scale.y = 2
    this.labelModel.scale.z = 2

    if (this.labelModel.children[0].name == "label2") {
      const ctx = document.createElement('canvas').getContext('2d');
      ctx.canvas.width = 300;
      ctx.canvas.height = 80;
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.fillStyle = '#056bf0'
      ctx.font = "15px Roboto";

      ctx.fillText("https://lockdown.memorial/?model=", 10, 15);
      ctx.fillText("" + this.newObject.children[0].name + "%id=" + this.ID + "", 10, 35);

      const texture = new THREE.CanvasTexture(ctx.canvas);

      var labelIdgeometry = new THREE.PlaneGeometry(30, 8, 32);
      var labelIDmaterial = new THREE.MeshBasicMaterial({
        map: texture,
      });
      var labelId = new THREE.Mesh(labelIdgeometry, labelIDmaterial);

      labelId.rotateX(-Math.PI / 2)
      labelId.position.x = 10;
      labelId.position.y = 0.3;
      labelId.position.z = 8;

      texture.needsUpdate = true;

      this.labelModel.add(labelId)
      if (currentScene) {
        currentScene.add(this.labelModel)
      } else {
        this.scene.add(this.labelModel)
      }


    } else {
      const ctx = document.createElement('canvas').getContext('2d');
      ctx.canvas.width = 300;
      ctx.canvas.height = 80;
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.fillStyle = '#056bf0'
      ctx.font = "16px Roboto";

      ctx.fillText("https://lockdown.memorial/?model=", 20, 15);
      ctx.fillText("" + this.newObject.children[0].name + "&id=" + this.ID + "", 20, 35);

      const texture = new THREE.CanvasTexture(ctx.canvas);

      var labelIdgeometry = new THREE.PlaneGeometry(30, 8, 32);
      var labelIDmaterial = new THREE.MeshBasicMaterial({
        map: texture,
      });
      var labelId = new THREE.Mesh(labelIdgeometry, labelIDmaterial);

      labelId.rotateX(-Math.PI / 2)
      labelId.position.x = 24;
      labelId.position.y = 0.3;
      labelId.position.z = 16;

      texture.needsUpdate = true;

      this.labelModel.add(labelId)
      if (currentScene) {
        currentScene.add(this.labelModel)
      } else {
        this.scene.add(this.labelModel)
      }
    }
    this.labelModel.rotateX(Math.PI / 2)

  }
  getParametersOfLabelFromIndexModel() {
    let currentLabelParameters = {
      index: 0,
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
      scale: 1,
    }
    switch (this.indexOfModel) {
      case 0:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = -10;
        currentLabelParameters.position.y = -30;
        currentLabelParameters.position.z = 50;
        currentLabelParameters.scale = 1.25;
        break;
      case 1:
        currentLabelParameters.index = 0
        currentLabelParameters.position.x = -30;
        currentLabelParameters.position.y = -35;
        currentLabelParameters.position.z = 50;
        currentLabelParameters.scale = 1.25;
        break;
      case 2:
        currentLabelParameters.index = 0
        currentLabelParameters.position.x = -20;
        currentLabelParameters.position.y = -22;
        currentLabelParameters.position.z = 55;
        currentLabelParameters.scale = 1.25;
        break;
      case 3:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = -10;
        currentLabelParameters.position.y = -70;
        currentLabelParameters.position.z = 50;
        currentLabelParameters.scale = 1.25;
        break;
      case 4:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = -10;
        currentLabelParameters.position.y = -70;
        currentLabelParameters.position.z = 50;
        currentLabelParameters.scale = 1.25;
        break;
      case 5:
        currentLabelParameters.index = 0
        currentLabelParameters.position.x = -50;
        currentLabelParameters.position.y = -40;
        currentLabelParameters.position.z = 68;
        currentLabelParameters.scale = 1.25;
        break;
      case 6:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = -10;
        currentLabelParameters.position.y = -35;
        currentLabelParameters.position.z = 80;
        currentLabelParameters.scale = 1.25;
        break;
      case 7:
        currentLabelParameters.index = 0
        currentLabelParameters.position.x = -27;
        currentLabelParameters.position.y = -42;
        currentLabelParameters.position.z = 45;
        currentLabelParameters.scale = 1.25;
        break;
      case 8:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = -10;
        currentLabelParameters.position.y = -72;
        currentLabelParameters.position.z = 25;
        currentLabelParameters.scale = 1.25;
        break;
      case 9:
        currentLabelParameters.index = 0
        currentLabelParameters.position.x = -25;
        currentLabelParameters.position.y = -20;
        currentLabelParameters.position.z = 85;
        currentLabelParameters.scale = 1.25;
        break;
      case 10:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = -10;
        currentLabelParameters.position.y = -70;
        currentLabelParameters.position.z = 63;
        currentLabelParameters.scale = 1.25;
        break;
      case 11:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = 30;
        currentLabelParameters.position.y = -55;
        currentLabelParameters.position.z = 55;
        currentLabelParameters.scale = 1.25;
        break;
      case 12:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = -10;
        currentLabelParameters.position.y = -52;
        currentLabelParameters.position.z = 65;
        currentLabelParameters.scale = 1.25;
        break;
      case 13:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = 0;
        currentLabelParameters.position.y = -60;
        currentLabelParameters.position.z = 45;
        currentLabelParameters.scale = 1.25;
        break;
      case 14:
        currentLabelParameters.index = 1
        currentLabelParameters.position.x = 0;
        currentLabelParameters.position.y = -80;
        currentLabelParameters.position.z = 25;
        currentLabelParameters.scale = 1.25;
        break;
        // case 15:
        //   currentLabelParameters.index = 1
        //   currentLabelParameters.position.x = -10;
        //   currentLabelParameters.position.y = -30;
        //   currentLabelParameters.position.z = 50;
        //   currentLabelParameters.scale = 1.25;
        //   break;

    }
    return currentLabelParameters
  }

}