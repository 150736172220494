import 'babel-polyfill'
import * as THREE from 'three'
import {
  GLTFLoader
} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {
  DomManager
} from '../domElements/domManager';

export const Preload = {

  loadModelsReferences() {

    this.refModelArray = []
    this.refModelArray.push(require('../models/videoGame.gltf'))
    this.refModelArray.push(require('../models/desk.gltf'))
    this.refModelArray.push(require('../models/table.gltf'))
    this.refModelArray.push(require('../models/headphone.gltf'))
    this.refModelArray.push(require('../models/cooker.gltf'))
    this.refModelArray.push(require('../models/sofa.gltf'))
    this.refModelArray.push(require('../models/computer.gltf'))
    this.refModelArray.push(require('../models/tv.gltf'))
    this.refModelArray.push(require('../models/phone.gltf'))
    this.refModelArray.push(require('../models/bed.gltf'))
    this.refModelArray.push(require('../models/chair.gltf'))
    this.refModelArray.push(require('../models/book.gltf'))
    this.refModelArray.push(require('../models/speaker.gltf'))
    this.refModelArray.push(require('../models/boardGame.gltf'))
    this.refModelArray.push(require('../models/plant.gltf'))

    this.modelArray = [];
    this.promiseArray = [];

    return this.refModelArray
  },
  async loadLabelModels() {

    this.refLabelArray = []
    this.promiseLabelArray = []
    this.labelArray = []

    this.refLabelArray.push(require('../models/label1.gltf'))
    this.refLabelArray.push(require('../models/label2.gltf'))

    this.promiseLabelArray[0] = await this.loadModel(this.refLabelArray[0])
    this.promiseLabelArray[1] = await this.loadModel(this.refLabelArray[1])

    await Promise.resolve(this.promiseLabelArray)

    this.labelArray = await this.promiseLabelArray

    return this.labelArray
  },

  async loadModels(refModelArray, i) {
    this.promiseArray[i] = this.loadModel(refModelArray[i]);

    await Promise.all(this.promiseArray);

    this.modelArray[i] = await this.promiseArray[i];
    this.modelArray[i].name = i


    return this.modelArray[i];
  },

  loadModel(refModel) {
    this.loader = new GLTFLoader();
    return new Promise(resolve => {
      this.loader.load(
        refModel,
        function (gltf) {
          resolve(gltf.scene);
        },
        (xhr) => {
          console.log((xhr.loaded / xhr.total * 100) + '% loaded');
        },
        (error) => {
          console.log('An error happened');
        }
      );
    });
  },
  displayHeader(isMobile) {
    this.domManager = new DomManager()

    this.domManager.setHeader(isMobile)
    this.domManager.changeHeaderText("LOCKDOWN <br> MEMORIAL <br> ARCHIVE")
  },
  async getArrayOfObjectName() {
    this.arrayOfObjectName = []
    for (let i = 0; i < this.refModelArray.length; i++) {
      switch (i) {
        case 0:
          this.arrayOfObjectName.push("video_game")
          break;
        case 1:
          this.arrayOfObjectName.push("desk")
          break;
        case 2:
          this.arrayOfObjectName.push("table")
          break;
        case 3:
          this.arrayOfObjectName.push("headphones")
          break;
        case 4:
          this.arrayOfObjectName.push("cooker")
          break;
        case 5:
          this.arrayOfObjectName.push("sofa")
          break;
        case 6:
          this.arrayOfObjectName.push("computer")
          break;
        case 7:
          this.arrayOfObjectName.push("television")
          break;
        case 8:
          this.arrayOfObjectName.push("smartphone")
          break;
        case 9:
          this.arrayOfObjectName.push("bed")
          break;
        case 10:
          this.arrayOfObjectName.push("chair")
          break;
        case 11:
          this.arrayOfObjectName.push("book")
          break;
        case 12:
          this.arrayOfObjectName.push("speaker")
          break;
        case 13:
          this.arrayOfObjectName.push("board_game")
          break;
        case 14:
          this.arrayOfObjectName.push("plant")
          break;
        case 15:
          this.arrayOfObjectName.push("bike")
          break;
      }
    }
    return this.arrayOfObjectName
  }

}