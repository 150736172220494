import * as firebase from 'firebase/app';

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyApdc3hWLicU3q5_dIijieYS6WX9rwElCs",
  authDomain: "socialgamification.firebaseapp.com",
  databaseURL: "https://socialgamification.firebaseio.com",
  projectId: "socialgamification",
  storageBucket: "socialgamification.appspot.com",
  messagingSenderId: "802086904133",
  appId: "1:802086904133:web:35f568bbf5b48f3f391e2c"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);